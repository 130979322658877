import fetch from 'interceptors/FetchInterceptor';
const MO_API_ENDPOINT = '/collections/mo-logs';

class MoService {
    async read(params){
        if(params){
            params['_sort'] = 'received_time:DESC';
        }
        const response = await fetch({
            url: `${MO_API_ENDPOINT}`,
            method: 'GET',
            params
        })
        return response;
    }
    async count(params){
        const response = await fetch({
            url: `${MO_API_ENDPOINT}/count`,
            method: 'GET',
            params

        })
        return response;
    }

    async export(params){
        const response = await fetch({
            url: `${MO_API_ENDPOINT}/export`,
            method: 'GET',
            params

        })
        return response;
    }
}
export default new MoService();