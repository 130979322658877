import React, { Component } from 'react';
import { Modal } from "antd";
import Websocket from 'components/shared-components/Websocket';
import SubscriptionServiceService from 'services/SubscriptionServiceService';
import { APP_PREFIX_PATH } from 'configs/AppConfig';
import { withRouter } from 'react-router-dom';
import CustomerService from "../../services/CustomerService";

class CallingService extends Component {

  constructor(props) {
    super(props)

    this.state = {
      isModalVisible: false,
      message: ''
    };

    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  
  handleData = async (data) => {
    try {
      this._isMounted && this.setState({
        message: data,
        isModalVisible: true
      })
    } catch (error) {
      console.log(error)
    }

  }

  handleCancel = () => {
    this.setState({
      isModalVisible: false,
      message: null
    })
  }

  handleOk = () => {
    const { message } = this.state;
    this.props.history.push(`${APP_PREFIX_PATH}/subscription/phone/${message.msisdn}`);
    this.props.history.go(0)
    this.setState({
      isModalVisible: false,
      message: null
    });
  }

  render() {
    const { isModalVisible, message } = this.state;
    return (
      <>
        <Modal title="Incoming Call" visible={isModalVisible} onOk={this.handleOk} onCancel={this.handleCancel}>
          <p>{message ? this.state.message.msisdn : ''}</p>
        </Modal>

        <Websocket onMessage={this.handleData} />
      </>
    )
  }

}

export default withRouter(CallingService);