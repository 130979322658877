import { useEffect } from 'react';

const useScript = ({ url, beforeLoad = null, afterLoad = null, async = true }) => {
  useEffect(() => {
    typeof beforeLoad === 'function' && beforeLoad();

    const script = document.createElement('script');

    script.src = url;
    script.async = async;
    script.onload = () => {
      typeof afterLoad === 'function' && afterLoad();
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, [url, beforeLoad, afterLoad, async]);
};

export default useScript;