import React from 'react';
import Statistic from "./statistic";
import { Card, Col, Row, Input, Form, DatePicker, Button } from "antd";
import History from "./history";
import CSKHComment from "./comment";
import CSKHTimeline from "./timeline";
import AddComment from "./add-comment";
import TableServicesOfCustomer from "./services-of-customer-table";
import TableSActiveLogs from "./active-logs";
import SubscriptionDetailService from "services/SubscriptionDetailService";
import SubscriptionService from "services/SubscriptionServiceService";
import PackageService from "services/PackageService";
import { PhoneOutlined } from "@ant-design/icons";
import moment from "moment";
import Utils from "utils";
import { withRouter } from 'react-router';
import CallLogService from "../../../../services/CallLogService";

const { RangePicker } = DatePicker;

class CSKHProfile extends React.Component {
  constructor(props) {
    super(props);

    const { phone } = this.props.match.params;

    this.state = {
      statistic: [],
      statisticLoading: false,

      timeline: [],
      timelineLoading: false,

      comments: [],
      commentLoading: false,

      servicesOfCustomer: [],
      servicesOfCustomerLoading: false,

      activeLogs: [],
      activeLogsLoading: false,

      activeChartLogs: [],
      activeChartLogsLoading: false,

      filters: {},

      phone,

      packages: [],
      confirmLoading: false,
    }
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadPackages();
    this.defaultDate();
    this.update();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  defaultDate = () => {
    const { filters } = this.state;
    let defaultDates = Utils.getDefaultDates();
    if (filters.published_at_gte === undefined && filters.published_at_lte === undefined) {
      filters['published_at_gte'] = defaultDates[0].startOf('day').format('YYYY-MM-DD');
      filters['published_at_lte'] = defaultDates[1].endOf('day').format('YYYY-MM-DD');
    }
    if (filters.submit_date_gte === undefined && filters.submit_date_lte === undefined) {
      filters['submit_date_gte'] = defaultDates[0].startOf('day').format('YYYY-MM-DD');
      filters['submit_date_lte'] = defaultDates[1].endOf('day').format('YYYY-MM-DD');
    }
    if (filters.time_gte === undefined && filters.time_lte === undefined) {
      filters['time_gte'] = defaultDates[0].startOf('day').format('YYYY-MM-DD');
      filters['time_lte'] = defaultDates[1].endOf('day').format('YYYY-MM-DD');
    }
    this.setState({
      filters
    });
  }

  getStatistic = async (params) => {

    this.setState({
      statisticLoading: true
    });
    // const { filters } = this.state;
    const __filters = {};
    const __param = Object.assign(__filters, params);
    const statistic = await SubscriptionDetailService.getOverview(__param);

    this._isMounted && this.setState({
      statisticLoading: false,
      statistic
    });
  }

  getTimeline = async (params) => {
    this.setState({
      timelineLoading: true
    });
    const { filters } = this.state;
    const __filters = { submit_date_gte: filters.submit_date_gte, submit_date_lte: filters.submit_date_lte };
    const __param = Object.assign(__filters, params);
    const timeline = await SubscriptionDetailService.getTimeline(__param);

    this._isMounted && this.setState({
      timelineLoading: false,
      timeline
    });
  }

  getComments = async (params) => {
    this.setState({
      commentLoading: true
    });
    const { filters } = this.state;
    const __filters = { published_at_gte: filters.published_at_gte, published_at_lte: filters.published_at_lte };
    const __param = Object.assign(__filters, params);
    const comments = await CallLogService.getComments(__param);

    this._isMounted && this.setState({
      commentLoading: false,
      comments
    });
  }

  getServicesOfCustomer = async (params) => {
    this.setState({
      servicesOfCustomerLoading: true
    });

    params['_limit'] = -1;
    const servicesOfCustomer = await SubscriptionService.read(params);

    this._isMounted && this.setState({
      servicesOfCustomerLoading: false,
      servicesOfCustomer
    });
  }

  getActiveLogs = async (params) => {
    this.setState({
      activeLogsLoading: true
    });
    const { filters } = this.state;
    params['_limit'] = -1;
    const __filters = { time_gte: filters.time_gte, time_lte: filters.time_lte };
    const __param = Object.assign(__filters, params);
    const activeLogs = await SubscriptionDetailService.getActiveLogs(__param);

    this._isMounted && this.setState({
      activeLogsLoading: false,
      activeLogs
    });
  }

  getActiveLogChart = async (params) => {
    this.setState({
      activeChartLogsLoading: true
    });
    const { filters } = this.state;
    const __filters = { submit_date_gte: filters.submit_date_gte, submit_date_lte: filters.submit_date_lte };
    const __param = Object.assign(__filters, params);
    const activeChartLogs = await SubscriptionDetailService.getActiveLogChart(__param);
    this._isMounted && this.setState({
      activeChartLogsLoading: false,
      activeChartLogs
    });
  }

  loadPackages = async () => {
    const packages = await PackageService.read();
    this.setState({
      packages
    })
  }

  update = (params = { msisdn: this.state.phone }) => {
    const { filters } = this.state;
    const obj_phone = { msisdn: this.state.phone };
    const __filter_statistic = { submit_date_gte: filters.submit_date_gte, submit_date_lte: filters.submit_date_lte };
    const __filter_widget = Object.assign({}, obj_phone)
    const __param_statistic = Object.assign(__filter_statistic, obj_phone);
    const __filter_comment = { published_at_gte: filters.submit_date_gte, published_at_lte: filters.submit_date_lte };
    const __param_comment = Object.assign(__filter_comment, obj_phone);
    const __filter_active_log = { time_gte: filters.submit_date_gte, time_lte: filters.submit_date_lte };
    const __param_active_log = Object.assign(__filter_active_log, obj_phone);
    const __param_service_customer = { msisdn: this.state.phone };
    this.getStatistic(__filter_widget);
    this.getTimeline(__param_statistic);
    this.getComments(__param_comment);
    this.getServicesOfCustomer(__param_service_customer);
    this.getActiveLogs(__param_active_log);
    this.getActiveLogChart(__param_statistic);

    this.setState({
      filters
    })
  }

  loadComment = (params = { msisdn: this.state.phone }) => {
    const { filters } = this.state;
    const obj_phone = { msisdn: this.state.phone };
    const __filter_comment = { published_at_gte: filters.submit_date_gte, published_at_lte: filters.submit_date_lte };
    const __param_comment = Object.assign(__filter_comment, obj_phone);
    this.getComments(__param_comment);

    this.setState({
      filters
    })
  }

  onFinish = async (values) => {
    const phone = this.state.phone;
    this.setState({
      statisticLoading: true,
      timelineLoading: true,
      commentLoading: true,
      servicesOfCustomerLoading: true,
      activeLogsLoading: true,
      activeChartLogsLoading: true,
    });

    let filters = {};
    filters['msisdn'] = phone;

    if (values.time && values.time !== '') {
      let submitTimeFrom = new Date(values.time[0]);
      let submitTimeTo = new Date(values.time[1]);
      submitTimeFrom = moment(submitTimeFrom).format('YYYY-MM-DD');
      submitTimeTo = moment(submitTimeTo).format('YYYY-MM-DD');

      filters['submit_date_gte'] = submitTimeFrom;
      filters['submit_date_lte'] = submitTimeTo;
    }
    this.setState({
      filters,
      statisticLoading: false,
      timelineLoading: false,
      commentLoading: false,
      servicesOfCustomerLoading: false,
      activeLogsLoading: false,
      activeChartLogsLoading: false,
    });
    this.update(filters);
  }

  onAddComment = (comment) => {
    this.loadComment()
  }

  render() {
    const {
      statistic,
      statisticLoading,
      timeline,
      timelineLoading,
      commentLoading,
      comments,
      servicesOfCustomer,
      servicesOfCustomerLoading,
      activeLogs,
      activeLogsLoading,
      activeChartLogs,
      activeChartLogsLoading,
      packages,
      confirmLoading,
      phone
    } = this.state;

    return (
      <>
        <Card>
          <Form layout="vertical" onFinish={this.onFinish}>
            <Row gutter={[24, 24]}>
              <Col md={6} sm={24} xs={24}>
                <Form.Item name="msisdn" label="Số điện thoại" initialValue={phone}>
                  <Input size="default" readOnly
                    prefix={<PhoneOutlined />} />
                </Form.Item>
              </Col>
              <Col md={6} sm={24} xs={24} className="d-flex">
                <Form.Item name="time" label="Thời gian lọc"
                  style={{ width: '100%', float: 'left', marginRight: '5px' }}>
                  <RangePicker style={{ width: '100%' }} format="DD/MM/YYYY" />
                </Form.Item>
                <Form.Item style={{ width: '30%', float: 'left', marginLeft: '5px' }}>
                  <Button className="float-right" type="primary" htmlType="submit"
                    style={{ width: '100%', marginTop: '20px', padding: '0px' }}>Lọc</Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
        <Statistic phone={phone} statistic={statistic} loading={statisticLoading} />
        <Row gutter={[24, 24]}>
          <Col xs={24}>
            <Card title="Danh sách dịch vụ thuê bao đang sử dụng">
              <TableServicesOfCustomer loading={servicesOfCustomerLoading} servicesOfCustomer={servicesOfCustomer} />
            </Card>
          </Col>
        </Row>
        <Row gutter={[24, 24]}>
          <Col md={12} sm={24} xs={24}>
            <Card title="Thống kê hoạt động">
              <History loading={activeChartLogsLoading} data={activeChartLogs} />
            </Card>
              <CSKHTimeline timeline={timeline} loading={timelineLoading} />
          </Col>
          <Col md={12} sm={24} xs={24}>
            {
              comments && comments.length ? (
                <Card title="Nội dung">
                  <CSKHComment loading={commentLoading} comments={comments} />
                </Card>
              ) : null
            }
            <Card title="Thêm ghi chú">
              <AddComment phone={phone} packages={packages} loading={confirmLoading} onAddComment={this.onAddComment} />
            </Card>
          </Col>
        </Row>
        <Row gutter={[24, 24]}>
          <Col xs={24}>
            <Card title="Hoạt động chi tiết">
              <TableSActiveLogs loading={activeLogsLoading} activeLogs={activeLogs} />
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

export default withRouter(CSKHProfile);