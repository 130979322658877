import { all, takeEvery, put, fork, call, take } from 'redux-saga/effects';
import GroupUserService from "services/GroupUserService";
import {
  LOAD_ALL_USER_GROUPS,
  LOAD_DETAIL_GROUPS,
  LOAD_LIST_USERS_BY_GROUP,
  loadAllUserGroupsSuccess,
  loadDetailGroupsSuccess,
  loadListUserByGroupsSuccess,
  SELECT_GROUP
} from "../actions/GroupUser";


export function* loadAllUserGroups() {
  yield takeEvery(LOAD_ALL_USER_GROUPS, function* () {
    try {
      const groups = yield call(GroupUserService.readGroupAll);

      if(groups){
        yield put(loadAllUserGroupsSuccess(groups.data));
      }
    } catch (error) {


    }
  });
}

export function* loadDetailGroups() {
  yield takeEvery(LOAD_DETAIL_GROUPS, function* ({ groupID }) {
    try {
      const groups = yield call(GroupUserService.readGroupByID, groupID);
      if(groups){
        yield put(loadDetailGroupsSuccess(groups.data, groupID));
      }
    } catch (error) {

    }
  });
}

export function* loadListUserByGroups() {
  yield takeEvery(LOAD_LIST_USERS_BY_GROUP, function* ({ id }) {
    try {
      const results = yield call(GroupUserService.getListUserByGroup, id);
      if(results){
        yield put(loadListUserByGroupsSuccess(results.data, id));
      }
    } catch (error) {

    }
  });
}

export function* selectCurrentGroup() {
  yield take(SELECT_GROUP);
}

export default function* rootSaga() {
  yield all([
    fork(loadAllUserGroups),
    fork(loadDetailGroups),
    fork(loadListUserByGroups),
    fork(selectCurrentGroup)
  ]);
}