import fetch from 'interceptors/FetchInterceptor';

const CALL_LOG_URL_API = '/collections/call-logs';

class CallLogService {
    async read(params) {
        const response = await fetch({
            url: CALL_LOG_URL_API,
            method: 'get',
            params
        });
        return response;
    }

    async create(item) {
        const response = await fetch({
            url: CALL_LOG_URL_API,
            method: 'post',
            data: item,
        });
        return response;
    }

    async update(item) {
        const response = await fetch({
            url: CALL_LOG_URL_API + '/' + item.id,
            method: 'put',
            data: item,
        });
        return response;
    }

    /*async delete(item) {
        const response = await fetch({
            url: CALL_LOG_URL_API + '/' + item.id,
            method: 'delete',
        });
        return response;
    }*/

    async count(params){
        const response = await fetch({
            url: CALL_LOG_URL_API + '/count',
            method: 'GET',
            params
        })
        return response;
    }

    async getComments(params) {
        const response = await fetch({
            url: CALL_LOG_URL_API + '/get-comment',
            method: 'get',
            params
        });
        return response;
    }

    async updateNote(params) {
        const response = await fetch({
            url: CALL_LOG_URL_API + '/update-call-log',
            method: 'POST',
            data: params
        });
        return response;
    }

    async export(params) {
        const response = await fetch({
            url: CALL_LOG_URL_API + '/export',
            method: 'get',
            params
        });
        return response;
    }
}

export default new CallLogService();
