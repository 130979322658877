import React, { Component, useEffect, useState } from 'react';

import {
  Row,
  Col,
  Card,
  Table,
  Button,
  Tooltip,
  Tag,
  message,
  Form,
  Modal,
  DatePicker,
  Input,
  Select
} from 'antd';
import packageService from 'services/PackageService';
import serviceService from 'services/ServiceService';
import {
  EditOutlined,
  DownloadOutlined,
  FileAddOutlined,
  PhoneOutlined
} from '@ant-design/icons';
import { Link } from "react-router-dom";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import telcosService from "services/TelcoService";
import partnerService from "services/PartnerService";
import callLogService from "services/CallLogService";
import Utils from "utils";
import { PATTERN_PHONE } from "configs/AppConfig";
import {CALL_TYPE, COMPLAIN_REASONS, COMPLAIN_TYPES} from 'views/app-views/configs';
import moment from "moment";
import ModalForm from './ModalForm';
import FilterForm from './FilterForm';
import PackageService from "../../../../services/PackageService";
import ServiceGroupService from "../../../../services/ServiceGroupService";
import ServiceService from 'services/ServiceService';

const {RangePicker} = DatePicker;
const {TextArea} = Input;

const PopupContent = ({
    okText,
    title,
    initialValues = null,
    isEditing = false,
    visiblePopup,
    onCancel,
    onCreate,
    services = [],
    service_types = [],
    telcos = [],
    packages = [],
    partners = [],
    onChange
}) => {
const [form] = Form.useForm();

useEffect(() => {
    form.resetFields();
    if (initialValues) {
        form.setFieldsValue(initialValues);
    } else {
        form.resetFields();
    }
}, [form, initialValues]);

okText = isEditing ? 'Sửa' : 'Thêm';
title = isEditing ? 'Sửa khiếu nại' : 'Thêm mới khiếu nại';
// var status = 'none';

const [display, setStatus] = useState('none');
const [displayGroup, setDisplayGroup] = useState('none');

async function handleOnChangeService(value, event) {
    onChange(value)
}

return (
        <Modal
            forceRender
            visible={visiblePopup}
            title={title}
            okText={okText}
            cancelText="Hủy"
            onCancel={onCancel}
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                        onCreate({
                            ...initialValues,
                            ...values
                        });
                    })
                    .catch((info) => {
                        console.log(info);
                    });
            }}>
            <Form
                form={form}
                layout="vertical"
                name="contentForm"
                initialValues={initialValues}
            >
                <Form.Item
                    label="Số điện thoại"
                    name="msisdn"
                    rules={[
                        {
                          pattern: PATTERN_PHONE,
                          message: 'Không đúng định dạng số điện thoại',
                        },
                      ]}
                >
                <Input
                    size="default"
                    placeholder="Nhập số điện thoại"
                    prefix={<PhoneOutlined />}
                />
                </Form.Item>
                <Form.Item name={['telco_id', 'id']} label="Nhà mạng" rules={[{
                    required: true,
                    message: 'Nhà mạng không được để trống'
                }]}>
                    <Select onSelect={(value, event) => handleOnChangeService({'telco_id': value})}>
                        {
                            telcos.map((t, index) => <Select.Option key={`telco_${index}`}
                                value={t.id}>{t.name}</Select.Option>)
                        }
                    </Select>
                </Form.Item>
                <Form.Item name={['service_id', 'id']} label="Dịch vụ" rules={[{
                    required: true,
                    message: 'Dịch vụ không được để trống'
                }]}>
                    <Select onSelect={(value, event) => handleOnChangeService({'service_id': value})}>
                        {
                            services.map((s, index) => <Select.Option key={`service_${index}`}
                                value={s.id}>{s.name}</Select.Option>)
                        }

                    </Select>
                </Form.Item>
                <Form.Item name="package_ids_in" label="Gói cước">
                    <Select style={{ width: '100%' }}
                        mode="tags"
                    >
                        {
                            packages.map((p, index) => <Select.Option key={p.id} value={p.id}>{p.code || p.name}</Select.Option>)
                        }
                    </Select>
                </Form.Item>
                <Form.Item name="partner_ids_in" label="Đối tác">
                    <Select style={{ width: '100%' }}
                        mode="tags"
                        filterOption={(input, option) =>
                            option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {
                            partners.map((p, index) => <Select.Option key={p.id} value={p.id}>{p.name}</Select.Option>)
                        }
                    </Select>
                </Form.Item>
                <Form.Item name="complain_reason_eq" label="Nguyên nhân khiếu nại"
                    style={{ width: '100%', float: 'left' }}>
                    <Select style={{ width: '100%' }} placeholder="Lựa chọn nguyên nhân khiếu nại"
                    >
                        {
                            COMPLAIN_REASONS.map((s, i) => <Select.Option key={`complain-reason-${i}`} value={s.value}>{s.name}</Select.Option>)
                        }
                    </Select>
                </Form.Item>
                <Form.Item name="complain_type_eq" label="Vấn đề khiếu nại"
                    style={{ width: '100%', float: 'left' }}>
                    <Select style={{ width: '100%' }} placeholder="Lựa chọn vấn đề khiếu nại"
                    >
                        {
                            COMPLAIN_TYPES.map((s, i) => <Select.Option key={`complain-type-${i}`} value={s.value}>{s.name}</Select.Option>)
                        }
                    </Select>
                </Form.Item>
                <Form.Item name="public_time1"
                    label="Thời gian khiếu nại"
                    rules={[{ required: true, message: 'Thời gian khiếu nại không được để trống' }]}
                    style={{ width: '100%', float: 'left' }}
                >
                    <DatePicker showTime format="YYYY-MM-DDTHH:mm:ss" style={{ width: '100%' }} />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export class CustomerCareHistory extends Component {
  state = {
    callLogs: [],
    packages: [],
    partners: [],
    services: [],
    contents: [],
    service_types: [],
    telcos: [],
    loading: false,
    visible: false,
    visiblePopup: false,
    confirmLoading: false,
    initialValues: {},
    initialValuePopups: {},
    isEditing: false,
    pagination: {
      current: 1,
      pageSize: 10,
    },
    filters: {_sort: "createdAt:DESC", type_note_in: ['call', 'other']},
    filtersPackage: {},
      createPackage: []
  };

  columns = [
    // {
    //   title: 'STT',
    //   dataIndex: 'id',
    //   key: 'id',
    //   render: (text, record, index) => {
    //     const { current, pageSize } = this.state.pagination;
    //
    //     return ((current - 1) * pageSize) + index + 1
    //   },
    //   width: 50
    // },
    {
      title: 'Số điện thoại',
      dataIndex: 'msisdn',
      render: (msisdn, _record) => <Link to={`${APP_PREFIX_PATH}/subscription/phone/${msisdn}`}>{msisdn}</Link>
    },
    {
      title: 'Thời gian gọi',
      dataIndex: 'call_time',
      width: 200,
      render: (_, record) => {
        let callTime = record.call_time;
        if (!record.call_time) {
          callTime = moment(record.createdAt);
        } else {
          callTime = moment(callTime);
        }

        return callTime.utc(true).format('DD-MM-YYYY HH:mm:ss');
      }
    },
    {
      title: 'Nhà mạng',
      dataIndex: ['telco_id', 'name'],
      key: ['telco_id', 'name'],
      width: 60
    },
    {
      title: 'Dịch vụ',
      dataIndex: ['service_id', 'name'],
      key: ['service_id', 'name'],
      width: 100
    },
    {
      title: 'Loại cuộc gọi',
      dataIndex: 'type_call',
      width: 150,
      render: (_, record) => {
        if (record.type_call === 'auto') {
          return 'Tự động';
        } else if (record.type_call === 'staff') {
          return 'Nhân viên';
        } else {
            return 'Khác';
        }
      }
    },
    {
      title: 'Ghi chú',
      dataIndex: 'content',
      width: 100
    },
    {
      title: 'Vấn đề khiếu nại',
      dataIndex: 'complain_type',
      width: 150,
      render: (text, record, index) => {
        let complainType = '';
        switch (text) {
          case 'cancel':
            complainType += 'Hủy dịch vụ';
            break;
          case 'register':
            complainType += 'Đăng ký dịch vụ';
            break;
          case 'check':
            complainType += 'Kiểm tra tình trạng thuê bao';
            break;
          case 'refund':
            complainType += 'Yêu cầu hoàn cước';
            break;
          case 'mt_not_receive':
            complainType += 'Không nhận được MT dịch vụ';
            break;
          case 'membership_code_invalid':
            complainType += 'Nhập mã hội viên không hợp lệ';
            break;
          case 'story_card_not_receive':
            complainType += 'Không nhận được phiếu đọc truyện';
            break;
          case 'other':
            complainType += 'Khác';
            break;
          default:
            complainType += 'Khác';
            break;
        }

        return complainType;
      }
    },
    {
      title: 'Nguyên nhân khiếu nại',
      dataIndex: 'complain_reason',
      width: 150,
      render: (text, record, index) => {
        let complainReason = '';
        switch (text) {
          case 'unregister':
            complainReason += 'Không có nhu cầu sử dụng';
            break;
          case 'register':
            complainReason += 'Có nhu cầu sử dụng';
            break;
          case 'error':
            complainReason += 'Lỗi dịch vụ';
            break;
          case 'membership_code_invalid':
            complainReason += 'Nhập sai mã hội viên';
            break;
          case 'check':
            complainReason += 'Kiểm tra tình trạng thuê bao';
            break;
          case 'other':
            complainReason += 'Khác';
            break;
          default:
            complainReason += 'Khác';
            break;
        }

        return complainReason;
      }
    },
    {
      title: 'Loại ghi chú',
      dataIndex: 'note_type',
      width: 150,
      render: (_, record) => {
        let type_note = '';
        switch (record.type_note) {
          case 'call':
            type_note += 'Cuộc gọi';
            break;
          case 'email':
            type_note += 'Email';
            break;
          case 'message':
            type_note += 'Gửi tin nhắn (SMS / Zalo / Facebook,..)';
            break;
          case 'other':
            type_note += 'Khác';
            break;
          default:
            type_note += 'Khác';
            break;
        }

        return type_note;
      }
    },
    {
      title: 'Đối tác',
      dataIndex: 'partner_ids',
      width: 100,
      render: partner_ids => {
          if (partner_ids) {
              return (
                  <>
                      {
                          partner_ids.map(partner => {
                              let color = '#3f87f5';
                              return (
                                  <>
                                      <Tag color={color} key={partner.id}>
                                          {partner.name}
                                      </Tag><br />
                                  </>
                              );
                          })
                      }
                  </>
              )
          }

          return ''
      }
    },
    {
      title: 'Gói cước',
      dataIndex: 'package_ids',
      width: 150,
      render: package_ids => (
        <>
          {
            package_ids.map(packages => {
              let color = '#3f87f5';
              return (
                <>
                  <Tag color={color} key={packages.id}>
                    {packages.code || packages.name}
                  </Tag><br />
                </>
              );
            })
          }
        </>
      )
    },
    {
      title: 'Hành động',
      dataIndex: 'action',
      fixed: 'right',
      width: 150,
      render: (_, record) => (
        <>
          <Tooltip title="Sửa">
            <Button danger icon={<EditOutlined />} size="small" onClick={() => this.handleEdit(record)} />
          </Tooltip>
        </>
      )
    },
  ];

  constructor(props) {
    super(props);
    this.handleUpdate = this.handleUpdate.bind(this);

    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    this.getDefaultDate();
    this.loadData();
    this.getTotalCount();
    this.getPartner();
    this.getServices();
    this.getPackages();
    this.getTelcos();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getDefaultDate = () => {
    const { filters } = this.state;
    let defaultDates = Utils.getDefaultDates();

    if (filters.call_time_gte === undefined || filters.call_time_lte === undefined) {
      filters['call_time_gte'] = moment.utc(defaultDates[0]).format('YYYY-MM-DDT00:00:00.000Z');
      filters['call_time_lte'] = moment.utc(defaultDates[1]).format('YYYY-MM-DDT23:59:59.000Z');
    }
    this.setState({
      filters
    })
  }

  loadData = async (params) => {
    this.setState({
      loading: true
    })
    const { filters } = this.state;
    const __pagination = Utils.getPaginationParams(params);
    const __params = Object.assign(filters, __pagination);
    const callLogs = await callLogService.read(__params);
    const { pagination } = this.state;
    this._isMounted && this.setState({
      loading: false,
      callLogs,
      pagination: {
        ...pagination,
        ...params
      }
    })
  }

  getTotalCount = async () => {
    const { filters } = this.state;

    const total = await callLogService.count(filters);
    const { pagination } = this.state;
    this._isMounted && this.setState({
      pagination: {
        ...pagination,
        total
      }
    })
  }

  getTelcos = async () => {
    const telcos = await telcosService.read();
    this._isMounted && this.setState({
      telcos
    });
  }

  getPackages = async () => {
    const packages = await packageService.read();
    this._isMounted && this.setState({
      packages
    })
  }

  getPartner = async () => {
    const partners = await partnerService.read();
    this._isMounted && this.setState({
      partners
    })
  }

  getServices = async () => {
    const services = await serviceService.read();
    this._isMounted && this.setState({
      services
    })
  }

  handleAdd = () => {
    this.setState({
      initialValues: null,
      isEditing: false,
      visible: true
    });
  };

  handleAddComplain = () => {
    this.setState({
      initialValues: null,
      isEditing: false,
      visiblePopup: true,
      title: "Thêm mới khiếu nại"
    });
  };

  hideModal = () => {
    this.setState({
      visible: false,
      confirmLoading: false
    });
  }

  hidePopupCreate = () => {
    this.setState({
      visiblePopup: false
    });
  }

  createPackage = async (dataCreate, item) => {
      let package_ids = []
      let dataPackage = {
          service_id: dataCreate.service_id,
          telco_id: dataCreate.telco_id
      }

      let packageNames = item.package_ids_in

      await packageNames.map(async (name) => {
          dataPackage.name = name;
          let packageInfo = await PackageService.create(dataPackage)
          package_ids.push(packageInfo._id)
      })
      return package_ids
  }

  handleCreate = async (item) => {
    try {
        let dataCreate = {}
        dataCreate.telco_id = item?.telco_id?.id ? item.telco_id.id : null
        dataCreate.service_id = item?.service_id?.id ? item.service_id.id : null
        dataCreate.package_ids = item?.package_ids_in ? item.package_ids_in : null
        dataCreate.partner_ids = item?.partner_ids_in ? item.partner_ids_in : null
        dataCreate.complain_reason = item?.complain_reason_eq ? item.complain_reason_eq : null
        dataCreate.complain_type = item?.complain_type_eq ? item.complain_type_eq : null
        dataCreate.call_time = item?.public_time1 ? item.public_time1 : null
        dataCreate.msisdn = item?.msisdn ? item.msisdn : null
        dataCreate.type_note = 'other'
        dataCreate.type_call = 'other'

        if (!dataCreate.package_ids) {
            message.error({content: 'Chưa nhập gói cước', duration: 2});
            this.hidePopupCreate();
            return 0;
        }

        let service = await ServiceService.readDetail(dataCreate.service_id)
        let packageCheckExist = []
        let packageExist = null

        dataCreate.package_ids.map((packageCheck) => {
            if (packageCheck.match(/^[0-9a-fA-F]{24}$/)) {
                packageCheckExist.push(packageCheck)
            }

            return 0
        })

        if (packageCheckExist.length) {
            // console.log(packageCheckExist.length)
            packageExist = await PackageService.read({id_in: packageCheckExist})
        }

        if (!service) {
            message.error({content: 'Lỗi khi thêm khiếu nại', duration: 2});
            this.hidePopupCreate();
            return 0;
        }

        if (service.is_create_new && !packageExist) {
            dataCreate.package_ids = await this.createPackage(dataCreate, item)
        }

        if (!packageExist) {
            message.error({content: 'Dịch vụ khiếu nại không được phép thêm mới gói cước', duration: 2});
            this.hidePopupCreate();
            return 0;
        }

        await callLogService.create(dataCreate);
        this.setState({
            isEditing: true,
            loading: false
        });
        this.loadData()
        message.success({content: 'Thêm khiếu nại thành công', duration: 2});
        this.hidePopupCreate();
    } catch (e) {
        console.log(e)
        this.hidePopupCreate();
    }
  }

  handleEdit = async (item) => {
    await callLogService.update(item);
    this.setState({
      initialValues: item,
      isEditing: true,
      visible: true
    });
  }

  handleUpdate = async (item) => {
    try {
      this.setState({
        loading: true
      })
      // await packageService.update(item);
      // message.success({ content: `Chỉnh sửa khiếu nại ${item.name} thành công`, duration: 3 });

      console.log(item);
      await callLogService.update(item);
      this.setState({
        initialValues: item,
        isEditing: true,
        visible: true,
        loading: false
      });
      this.loadData()
      message.success({content: 'Cập nhật thành công', duration: 2});
      this.hideModal();
    } catch (e) {
      this.hideModal();
    }
  }

  onCancel = () => {
    this.hideModal();
  }

  onCancelPopup = () => {
    this.hidePopupCreate();
  }

  onFinish = async (filters) => {
    filters = {...this.state.filters, ...filters}
    this.setState({
      filters,
      pagination: {
        current: 1,
        pageSize: 10,
      },
    })

    this.loadData();
    this.getTotalCount();
  }

  handleTableChange = (pagination) => {
    this.loadData(pagination);
  }

  handleChange = async (values) => {
    const { filtersPackage } = this.state;
    console.log(values)
    if (values.telco_id) {
      filtersPackage.telco_id_in = values.telco_id[0];
    }

    if (values.service_id !== undefined && values.service_id !== null) {
      filtersPackage.service_id_in = values.service_id[0];
    }

    const packages = await PackageService.read(filtersPackage);
    this._isMounted && this.setState({
      packages
    });
  }

  handleChangePopup = async (values) => {
    const { filtersPackage } = this.state;
    if (values.telco_id) {
      filtersPackage.telco_id_in = values.telco_id;
    }

    if (values.service_id !== undefined && values.service_id !== null) {
      filtersPackage.service_id_in = values.service_id;
    }

    const packages = await PackageService.read(filtersPackage);
    this._isMounted && this.setState({
      packages
    });
  }

  handleDownload = async () => {
    this.state.filters._limit = -1
    const url = await callLogService.export(this.state.filters)
    if (url) {
      Utils.downloadBySaveFile(url, url.replace(/^.*[\\/]/, ''))
    }
  }

  render() {
    const { callLogs, packages, loading, contents, service_types, visible, visiblePopup, confirmLoading, initialValues, initialValuePopups, isEditing, partners, services, telcos, pagination } = this.state;
    const onCreate = isEditing ? this.handleUpdate : this.handleCreate;
    return (
      <>
        <FilterForm packages={packages} partners={partners} services={services} telcos={telcos} onChange={this.handleChange} onHandleFilter={this.onFinish} />
        <PopupContent
            onCreate={onCreate}
            visiblePopup={visiblePopup}
            isEditing={isEditing}
            initialValues={initialValues}
            telcos={telcos}
            contents={contents}
            services={services}
            service_types={service_types}
            packages={packages}
            partners={partners}
            onChange={this.handleChangePopup}

            onCancel={this.onCancelPopup}
        />
        <Row gutter={[24, 24]}>
          <Col md={24} sm={24} xs={24}>
            <Card title="Danh sách lịch sử CSKH">
              <Button type="default"
                style={{ marginBottom: 16, borderRadius: '5px', float: 'right', marginLeft: '15px' }}
                icon={<DownloadOutlined />}
                onClick={this.handleDownload}>
                Tải về
              </Button>
              <Button onClick={() => this.handleAddComplain()} type="primary"
                                    style={{marginBottom: 16, borderRadius: '5px', float: 'right'}}
                                    icon={<FileAddOutlined/>}>Thêm mới khiếu nại</Button>
              <ModalForm
                confirmLoading={confirmLoading}
                visible={visible}
                isEditing={isEditing}
                initialValues={initialValues}
                onCreate={onCreate}
                onCancel={this.onCancel}
                telcos={telcos}
                services={services}
              />
              <Table
                columns={this.columns}
                dataSource={callLogs}
                loading={loading}
                rowKey={record => record.id}
                pagination={pagination}
                onChange={this.handleTableChange}
              />
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

export default CustomerCareHistory;