import React from 'react'
import {
    Button,
    Card,
    Col,
    Form,
    Input,
    message,
    Modal,
    Popconfirm,
    Row,
    Select,
    Table,
    Tooltip
} from "antd";

import {
    EditOutlined,
    FileAddOutlined,
    DeleteOutlined
} from '@ant-design/icons';
import GroupUserService from "services/GroupUserService";
import moment from "moment";
import { connect } from 'react-redux';
import { loadListUserByGroups } from "redux/actions/GroupUser";

const PopupContent = ({
    idGroupCurrent,
    okText,
    title,
    initialValues = null,
    isEditing = false,
    visible,
    onCancel,
    onCreate}) => {
    const [form] = Form.useForm();
    if (initialValues) {
        console.log('initialValues', initialValues)
        form.setFieldsValue(initialValues);
    } else {
        form.resetFields();
    }

    okText = isEditing ? 'Sửa' : 'Thêm';
    title = isEditing ? 'Sửa người dùng' : 'Thêm mới người dùng';
    return (
        <Modal
            visible={visible}
            title={title}
            okText={okText}
            cancelText="Hủy"
            onCancel={onCancel}
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                        onCreate({
                            ...initialValues,
                            ...values
                        });
                    })
                    .catch((info) => {
                        console.log(info);
                    });
            }}>
            <Form
                form={form}
                layout="vertical"
                name="contentForm"
                initialValues={initialValues}
            >
                <Form.Item
                    label="Username"
                    name="username"
                    rules={[{ required: true, message: 'Username không được để trống' }]}
                >
                    <Input placeholder="Username" />
                </Form.Item>

                <Form.Item name="email" label="Email" rules={[{ required: true, type: 'email' }]}>
                    <Input />
                </Form.Item>
                <Form.Item
                    label="FirstName"
                    name="firstName"
                    rules={[{ required: true, message: 'firstName không được để trống' }]}
                >
                    <Input placeholder="firstName" />
                </Form.Item>
                <Form.Item
                    label="LastName"
                    name="lastName"
                    rules={[{ required: true, message: 'lastName không được để trống' }]}
                >
                    <Input placeholder="lastName" />
                </Form.Item>
                <Form.Item
                    label="Password"
                    name="password"
                    rules={[{ required: true, message: 'Please input your password!' }]}
                >
                    <Input.Password placeholder="Password" />
                </Form.Item>

                {/*<Form.Item name="blocked" label="Blocked" >*/}
                {/*    <Switch />*/}
                {/*</Form.Item>*/}

                <Form.Item name="group_id" label="Group" rules={[{
                    required: true,
                    message: 'Trạng thái không được để trống'
                }]}>
                    <Select disabled>
                        <Select.Option value={idGroupCurrent.id} key={idGroupCurrent.id}>
                            {idGroupCurrent.name}
                        </Select.Option>
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export class UserView extends React.Component {
    state = {
        contents: [],
        initialValues: {},
        isEditing: false,
        visible: false,
        loading: false,
        title: ''
    };

    columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Name',
            dataIndex: 'firstName',
            render: (firstName, row) => row.lastName + " " + firstName
        },
        {
            title: 'Username',
            dataIndex: 'username',
        },
        {
            title: 'Email',
            dataIndex: 'email',
        },
        // {
        //     title: 'Role',
        //     dataIndex: ['role', 'name'],
        // },
        // {
        //     title: 'Confirmed',
        //     dataIndex: 'confirmed',
        //     render: (confirmed) => confirmed === true ? "Yes" : "No"
        // },
        // {
        //     title: 'Blocked',
        //     dataIndex: 'blocked',
        //     render: (blocked) => blocked === true ? "Yes" : "No"
        // },
        {
            title: 'Ngày tạo',
            dataIndex: 'createdTimestamp',
            render: (createdTimestamp) => moment(createdTimestamp).format("YYYY-MM-DD")
        },
        {
            title: 'Cập nhật',
            dataIndex: 'update',
            key: 'update',
            width: 120,
            render: (_, record) => (
                <>
                    <Tooltip title="Sửa">
                        <Button icon={<EditOutlined />} size="small" onClick={() => this.handleEdit(record)} />
                    </Tooltip>
                </>
            )
        },
        {
            title: 'Xóa',
            dataIndex: 'delete',
            key: 'delete',
            render: (_, record) => (
                <>
                    <Popconfirm title="Bạn muốn xóa nội dung này phải không?"
                        onConfirm={() => this.handleDelete(record)}>
                        <Tooltip title="Xóa">
                            <Button danger icon={<DeleteOutlined />} size="small" />
                        </Tooltip>
                    </Popconfirm>
                </>
            )
        },
    ];

    constructor(props) {
        super(props);

        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        console.log('this.prop', this.props)
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleAdd = () => {
        this.setState({
            visible: true,
            isEditing: false,
            title: 'Thêm mới người dùng',
            initialValues: this.state.initialValues,
        });
    }
    handleCreate = async (content) => {
        try {
            const { loadListUserByGroups } = this.props;
            await GroupUserService.create(content);
            this.hideModal();
            loadListUserByGroups();
            message.success({ content: 'Thêm mới thành công', duration: 2 });
        } catch (error) {
            this.hideModal();
            message.error({ content: 'Thêm mới không thành công', duration: 2 });
        }
    }

    handleEdit = (content) => {
        this.setState({
            initialValues: content,
            visible: true,
            isEditing: true,
            title: 'Cập nhật người dùng'
        });
        console.log(this.state.isEditing)
    }
    handleUpdate = async (content) => {
        try {
            console.log(content)
            // await GroupUserService.update(content);
            // const {listUsers, allGroups, idGroupCurrent} = await this.props;
            // message.success({content: 'Thêm mới thành công', duration: 2});
            // this.hideModal();
        } catch (error) {
            message.error({ content: 'Thêm mới không thành công', duration: 2 });
            this.hideModal();
        }
    }
    handleDelete = async (content) => {
        try {
            await GroupUserService.delete(content.id);
            const { contents } = this.state;
            this._isMounted && this.setState({
                contents: contents.filter((c) => c.id !== content.id)
            })
            message.success({ content: 'Xóa thành công', duration: 2 });
        } catch (error) {
            message.error({ content: 'Xóa không thành công', duration: 2 });
        }
    }
    hideModal = () => {
        this.setState({
            visible: false
        })
    }
    onCancel = () => {
        this.hideModal();
    }

    render() {
        const { visible, initialValues, isEditing } = this.state;
        const { listUsers, idGroupCurrent } = this.props;
        // this.state.initialValues =
        // {
        //     group_id: idGroupCurrent.id,
        //     blocked: false
        // }

        const onCreate = isEditing ? this.handleUpdate : this.handleCreate;
        return (
            <>
                <PopupContent
                    onCreate={onCreate}
                    visible={visible}
                    idGroupCurrent={idGroupCurrent}
                    isEditing={isEditing}
                    initialValues={initialValues}
                    contents={listUsers}
                    onCancel={this.onCancel}
                />
                <Row gutter={[24, 24]}>
                    <Col xs={24}>
                        <Card title="Danh sách người dùng">
                            {/*<Button type="default"*/}
                            {/*        style={{marginBottom: 16, borderRadius: '5px', float: 'right', marginLeft: '15px'}}*/}
                            {/*        icon={<DownloadOutlined/>}>*/}
                            {/*    Tải về*/}
                            {/*</Button>*/}
                            <Button onClick={() => this.handleAdd(idGroupCurrent)} type="primary"
                                style={{ marginBottom: 16, borderRadius: '5px', float: 'right' }}
                                icon={<FileAddOutlined />}>
                                Thêm mới người dùng
                            </Button>
                            <Table columns={this.columns} dataSource={listUsers} rowKey={record => record.id} />
                        </Card>
                    </Col>
                </Row>
            </>
        );
    }
}

const mapStateToProps = ({ userGroup }) => {
    if (userGroup.currentGroup === null) {
        if (Object.keys(userGroup.allGroups).length !== 0) {
            const idGroupCurrent = userGroup.allGroups[0]
            const { listUsers, allGroups } = userGroup
            return { listUsers, allGroups, idGroupCurrent }
        } else {
            const idGroupCurrent = "";
            const { listUsers, allGroups } = userGroup
            return { listUsers, allGroups, idGroupCurrent }
        }
    } else {
        const idGroupCurrent = userGroup.currentGroup
        const { listUsers, allGroups } = userGroup
        return { listUsers, allGroups, idGroupCurrent }
    }

}
const mapDispathToProps = {
    loadListUserByGroups
}

// adding the oidc user in the props
export default connect(mapStateToProps, mapDispathToProps)(UserView);
