import fetch from 'interceptors/FetchInterceptor';
const CONTENT_API_ENDPOINT = '/collections/contents';

class ContentService {
    async create(content){
        const response = await fetch({
            url: CONTENT_API_ENDPOINT,
            method: 'POST',
            data: content,
        })
        return response;
    }
    async read(params){
        const response = await fetch({
            url: `${CONTENT_API_ENDPOINT}`,
            method: 'GET',
            params
        })
        return response;
    }
    async count(params){
        const response = await fetch({
            url: `${CONTENT_API_ENDPOINT}/count`,
            method: 'GET',
            params

        })
        return response;
    }
    async update(content){

        const response = await fetch({
            url: `${CONTENT_API_ENDPOINT}/${content.id}`,
            method: 'PUT',
            data: content
        })

        return response;
    }
    async delete(id){
        const response = await fetch({
            url: `${CONTENT_API_ENDPOINT}/${id}`,
            method: 'DELETE',
        })
        return response;
    }
    async export(params){
        const response = await fetch({
            url: `${CONTENT_API_ENDPOINT}/export`,
            method: 'GET',
            data: params
        })
        return response
    }
}
export default new ContentService();