import axios from 'axios'
import { API_BASE_URL } from 'configs/AppConfig'
import { notification } from 'antd';
import auth from '../auth';
import qs from "qs";

const service = axios.create({
    baseURL: API_BASE_URL,
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
});

// API Request interceptor
service.interceptors.request.use(
    config => {
        const token = auth.getToken();
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }

        return config;
    },
    error => {
        notification.error({
            message: 'Error'
        })
        Promise.reject(error)
    });

// API respone interceptor
service.interceptors.response.use(
    response => response.data,
    error => {
        let notificationParam = {
            message: ''
        }

        let status;
        let message;

        if (error && error.response && error.response.status) {
            status = error.response.status;
            message = error.response.data.message;
        }

        switch (status) {
            // case 401: // TODO: will remove this code after support refresh token
            // case 400:
            case 403:
                notificationParam.message = message || 'Authentication Fail'
                notificationParam.description = message || 'Please login again'
                auth.backToLogin()
                break;

            case 404:
                notificationParam.message = message || 'Not Found';
                break;

            case 500:
                notificationParam.message = message || 'Internal Server Error';
                break;

            case 508:
                notificationParam.message = message || 'Time Out';
                break;

            default:
                notificationParam.message = message || 'An unknown error.';
                break;
        }

        notification.error(notificationParam);

        return Promise.reject(error);
    });

export default service