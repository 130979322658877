import React from 'react';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { Spin } from 'antd';
import { isEqual, sortBy } from 'lodash'
import { COLORS } from 'views/app-views/components/AmCharts/config';

am4core.useTheme(am4themes_animated);

class History extends React.Component {

    constructor(props) {
        super(props);

        this.chartDivId = this.props.chartDivId || 'mixChartDiv';
    }

    componentDidMount() {
        this.setup();
    }

    componentDidUpdate() {
        this.setup();
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return !isEqual(nextProps, this.props);
    }

    componentWillUnmount() {
        this.dispose();
    }

    setup() {
        const { loading, data } = this.props;
        if (!loading && data && data.length) {
            this.dispose()
            this.build(sortBy(data, (d) => new Date(d.date)));
        }
    }

    dispose() {
        if (this.chart) {
            this.chart.dispose();
        }
    }

    build(data) {
        let chart = am4core.create(this.chartDivId, am4charts.XYChart);

        chart.colors.step = 2;
        chart.maskBullets = false;

        // Add data
        chart.data = data;
        // date: "2021-04-01"
        // mt_error_total: 2400
        // mt_total: 1300
        // payment_error_total: 1100
        // revenue: 250000
        // Create axes
        const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.grid.template.location = 0;
        dateAxis.renderer.minGridDistance = 50;
        dateAxis.renderer.grid.template.disabled = true;
        dateAxis.renderer.fullWidthTooltip = true;

        const distanceAxis = chart.yAxes.push(new am4charts.ValueAxis());
        distanceAxis.title.text = "Doanh thu";
        distanceAxis.min = 0;

        const durationAxis = chart.yAxes.push(new am4charts.ValueAxis());
        durationAxis.title.text = "Số lượng";
        durationAxis.renderer.opposite = true;


        // Create series
        const distanceSeries = chart.series.push(new am4charts.ColumnSeries());
        distanceSeries.dataFields.valueY = "revenue";
        distanceSeries.dataFields.dateX = "date";
        distanceSeries.yAxis = distanceAxis;
        distanceSeries.tooltipText = "{valueY}";
        distanceSeries.name = "Doanh thu";
        distanceSeries.columns.template.fillOpacity = 0.7;
        distanceSeries.columns.template.propertyFields.strokeDasharray = "dashLength";
        distanceSeries.columns.template.propertyFields.fillOpacity = "alpha";
        distanceSeries.showOnInit = true;
        distanceSeries.fill = am4core.color(COLORS[0]);
        distanceSeries.stroke = am4core.color(COLORS[0]);

        const distanceState = distanceSeries.columns.template.states.create("hover");
        distanceState.properties.fillOpacity = 0.9;

        const durationSeries = chart.series.push(new am4charts.LineSeries());
        durationSeries.dataFields.valueY = "mt_total";
        durationSeries.dataFields.dateX = "date";
        durationSeries.yAxis = durationAxis;
        durationSeries.name = "Số lượng MT";
        durationSeries.strokeWidth = 2;
        durationSeries.propertyFields.strokeDasharray = "dashLength";
        durationSeries.tooltipText = "{valueY} hoạt động";
        durationSeries.showOnInit = true;
        durationSeries.fill = am4core.color(COLORS[1]);
        durationSeries.stroke = am4core.color(COLORS[1]);

        const cancelSeries = chart.series.push(new am4charts.LineSeries());
        cancelSeries.dataFields.valueY = "mt_error_total";
        cancelSeries.dataFields.dateX = "date";
        cancelSeries.yAxis = durationAxis;
        cancelSeries.name = "MT lỗi";
        cancelSeries.strokeWidth = 2;
        cancelSeries.propertyFields.strokeDasharray = "dashLength";
        cancelSeries.tooltipText = "{valueY} số MT gửi lỗi";
        cancelSeries.showOnInit = true;
        cancelSeries.fill = am4core.color(COLORS[1]);
        cancelSeries.stroke = am4core.color(COLORS[1]);

        const newSeries = chart.series.push(new am4charts.LineSeries());
        newSeries.dataFields.valueY = "payment_error_total";
        newSeries.dataFields.dateX = "date";
        newSeries.yAxis = durationAxis;
        newSeries.name = "Trừ cước lỗi";
        newSeries.strokeWidth = 2;
        newSeries.propertyFields.strokeDasharray = "dashLength";
        newSeries.tooltipText = "{valueY} trừ cước lỗi";
        newSeries.showOnInit = true;
        newSeries.fill = am4core.color(COLORS[2]);
        newSeries.stroke = am4core.color(COLORS[2]);


        const durationBullet = durationSeries.bullets.push(new am4charts.Bullet());
        const durationRectangle = durationBullet.createChild(am4core.Rectangle);
        durationBullet.horizontalCenter = "middle";
        durationBullet.verticalCenter = "middle";
        durationBullet.width = 7;
        durationBullet.height = 7;
        durationRectangle.width = 7;
        durationRectangle.height = 7;

        const durationState = durationBullet.states.create("hover");
        durationState.properties.scale = 1.2;


        // Add legend
        chart.legend = new am4charts.Legend();

        // Add cursor
        chart.cursor = new am4charts.XYCursor();
        chart.cursor.fullWidthLineX = true;
        chart.cursor.xAxis = dateAxis;
        chart.cursor.lineX.strokeOpacity = 0;
        chart.cursor.lineX.fill = am4core.color("#000");
        chart.cursor.lineX.fillOpacity = 0.1;


        chart.language.locale["_decimalSeparator"] = ",";
        chart.language.locale["_thousandSeparator"] = ".";

        this.chart = chart;
    }

    render() {
        const { data, loading } = this.props;

        return (
            <>
                {loading ? <Spin /> :
                    (
                        !data || !data.length ? (<div>No data found</div>) : (<div id={this.chartDivId} style={{ width: "100%", height: "500px" }}></div>)
                    )
                }
            </>
        );
    }
}

export default History;
