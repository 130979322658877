import React from 'react'
import { Card, Col, Row } from "antd";
import {
  DollarOutlined,
  PhoneOutlined, 
  UserOutlined, 
  ArrowDownOutlined, 
  ArrowUpOutlined
} from "@ant-design/icons";

import NumberFormat from 'react-number-format';

const RevenueReportSummary = ({ data }) => {

  const badge = (ratio) => {
    if (ratio < 0) {
      return 'ant-badge-red';
    } else if (ratio > 0) {
      return 'ant-badge-green';
    } else {
      return '';
    }
  }

  const arrowType = (ratio) => {
    if (ratio < 0) {
      return <ArrowDownOutlined />;
    } else if (ratio > 0) {
      return <ArrowUpOutlined />;
    } else {
      return '';
    }
  }

  return (
    <Row gutter={[24, 24]} >
      <Col md={6} sm={12} xs={24}>
        <Card>
          <div className="media ant-align-items-center">
            <div className="ant-avatar ant-avatar-icon ant-avatar-lg ant-avatar-purple">
              <UserOutlined />
            </div>
            <div className="m-l-15">
              <h2 className="m-b-0">
                {
                  data.revenue_total !== undefined
                    ? <NumberFormat value={data.revenue_total} displayType={'text'} thousandSeparator={true} />
                    : 0
                }
              </h2>
              <span className={'ant-badge ant-badge-pill font-size-12 ' + badge(data.revenue_ratio)}>
                {arrowType(data.revenue_ratio)}
                <span className="ant-font-weight-semibold m-l-5">{data.revenue_ratio !== undefined ? Number(data.revenue_ratio).toFixed(2) : '0%'}</span>
              </span>
              <p className="m-b-0 text-muted">Doanh thu khách hàng</p>
            </div>
          </div>
        </Card>
      </Col>
      <Col md={6} sm={12} xs={24}>
        <Card>
          <div className="media ant-align-items-center">
            <div className="ant-avatar ant-avatar-icon ant-avatar-lg ant-avatar-blue">
              <UserOutlined />
            </div>
            <div className="m-l-15">
              <h2 className="m-b-0">
                {
                  data.revenue_same_period_total !== undefined
                    ? <NumberFormat value={data.revenue_same_period_total} displayType={'text'} thousandSeparator={true} />
                    : 0
                }
              </h2>
              <p className="m-b-0 text-muted" style={{marginTop: '34px'}}>Doanh thu khách hàng cùng kỳ</p>
            </div>
          </div>
        </Card>
      </Col>
      <Col md={6} sm={12} xs={24}>
        <Card>
          <div className="media ant-align-items-center">
            <div className="ant-avatar ant-avatar-icon ant-avatar-lg ant-avatar-cyan">
              <PhoneOutlined />
            </div>
            <div className="m-l-15">
              <h2 className="m-b-0">
                {
                  data.revenue_after_telco_total !== undefined
                    ? <NumberFormat value={data.revenue_after_telco_total} displayType={'text'} thousandSeparator={true} />
                    : 0
                }
              </h2>
              <p className="m-b-0 text-muted" style={{marginTop: '34px'}}>Doanh thu sau Telco</p>
            </div>
          </div>
        </Card>
      </Col>
      <Col md={6} sm={12} xs={24}>
        <Card>
          <div className="media ant-align-items-center">
            <div className="ant-avatar ant-avatar-icon ant-avatar-lg ant-avatar-gold">
              <DollarOutlined />
            </div>
            <div className="m-l-15">
              <h2 className="m-b-0">
                {
                  data.revenue_after_partner_total !== undefined
                    ? <NumberFormat value={data.revenue_after_partner_total} displayType={'text'} thousandSeparator={true} />
                    : 0
                }
              </h2>
              <p className="m-b-0 text-muted" style={{marginTop: '34px'}}>Doanh thu sau phân chia</p>
            </div>
          </div>
        </Card>
      </Col>
    </Row>
  )
}


export default RevenueReportSummary;
