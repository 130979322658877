import React from 'react';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { Spin } from 'antd';
import { isEqual, sortBy } from 'lodash'
import { COLORS } from '../config';

am4core.useTheme(am4themes_animated);

class LineChart extends React.Component {

  constructor(props) {
    super(props);

    this.chartDivId = this.props.chartDivId || 'mixChartDiv';
  }

  componentDidMount() {
    this.setup();
  }

  componentDidUpdate() {
    this.setup();
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return !isEqual(nextProps, this.props);
  }

  componentWillUnmount() {
    this.dispose();
  }

  setup() {
    const { loading, data } = this.props;
    if (!loading && data && data.length) {
      this.dispose()
      this.build(sortBy(data, (d) => new Date(d.date)));
    }
  }

  dispose() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  build(data) {
    let chart = am4core.create(this.chartDivId, am4charts.XYChart);

    chart.colors.step = 2;
    chart.maskBullets = false;

    // Add data
    chart.data = data;
     
    // Create axes
    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.renderer.minGridDistance = 50;
    dateAxis.renderer.grid.template.disabled = true;
    dateAxis.renderer.fullWidthTooltip = true;

    var durationAxis = chart.yAxes.push(new am4charts.ValueAxis());
    durationAxis.title.text = "Số lượng";
    durationAxis.min = 0;

    const unregisterSeries = chart.series.push(new am4charts.LineSeries());
    unregisterSeries.dataFields.valueY = "user_unregister";
    unregisterSeries.dataFields.dateX = "date";
    unregisterSeries.yAxis = durationAxis;
    unregisterSeries.name = "Hủy dịch vụ";
    unregisterSeries.strokeWidth = 2;
    unregisterSeries.propertyFields.strokeDasharray = "dashLength";
    unregisterSeries.tooltipText = "{valueY} lượt hủy";
    unregisterSeries.showOnInit = true;
    unregisterSeries.fill = am4core.color(COLORS[0]);
    unregisterSeries.stroke = am4core.color(COLORS[0]);

    // const newSeries = chart.series.push(new am4charts.LineSeries());
    // newSeries.dataFields.valueY = "new_user_register";
    // newSeries.dataFields.dateX = "date";
    // newSeries.yAxis = durationAxis;
    // newSeries.name = "Đăng ký mới";
    // newSeries.strokeWidth = 2;
    // newSeries.propertyFields.strokeDasharray = "dashLength";
    // newSeries.tooltipText = "{valueY} đăng ký mới";
    // newSeries.showOnInit = true;
    // newSeries.fill = am4core.color(COLORS[1]);
    // newSeries.stroke = am4core.color(COLORS[1]);

    const paymentSuccessSeries = chart.series.push(new am4charts.LineSeries());
    paymentSuccessSeries.dataFields.valueY = "active";
    paymentSuccessSeries.dataFields.dateX = "date";
    paymentSuccessSeries.yAxis = durationAxis;
    paymentSuccessSeries.name = "Đăng ký";
    paymentSuccessSeries.strokeWidth = 3;
    paymentSuccessSeries.propertyFields.strokeDasharray = "dashLength";
    paymentSuccessSeries.tooltipText = "{valueY} lượt đăng ký";
    paymentSuccessSeries.showOnInit = true;
    paymentSuccessSeries.fill = am4core.color(COLORS[2]);
    paymentSuccessSeries.stroke = am4core.color(COLORS[2]);


    // const durationBullet = newSeries.bullets.push(new am4charts.Bullet());
    // const durationRectangle = durationBullet.createChild(am4core.Rectangle);
    // durationBullet.horizontalCenter = "middle";
    // durationBullet.verticalCenter = "middle";
    // durationBullet.width = 7;
    // durationBullet.height = 7;
    // durationRectangle.width = 7;
    // durationRectangle.height = 7;
    //
    // var durationState = durationBullet.states.create("hover");
    // durationState.properties.scale = 1.2;


    // Add legend
    chart.legend = new am4charts.Legend();

    // Add cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.fullWidthLineX = true;
    chart.cursor.xAxis = dateAxis;
    chart.cursor.lineX.strokeOpacity = 0;
    chart.cursor.lineX.fill = am4core.color("#000");
    chart.cursor.lineX.fillOpacity = 0.1;


    chart.language.locale["_decimalSeparator"] = ",";
    chart.language.locale["_thousandSeparator"] = ".";

    this.chart = chart;
  }

  render() {
    const { data, loading } = this.props;

    return (
      <>
        { loading ? <Spin /> :
          (
            !data || !data.length ? (<div>No data found</div>) : (<div id={this.chartDivId} style={{ width: "100%", height: "500px" }}></div>)
          )
        }
      </>
    );
  }
}

export default LineChart;
