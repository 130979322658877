import React from 'react'
import {Button, Card, Col, DatePicker, Form, Row, Select, Table} from "antd";
import {
  ArrowDownOutlined, ArrowUpOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import TelcoService from "services/TelcoService";
import ServiceService from "services/ServiceService";
import PackageService from "services/PackageService";
import RevenueReportService from "services/RevenueReportService";

import NumberFormat from 'react-number-format';
import ReportService from "services/ReportService";
import partnerService from "services/PartnerService";
import moment from "moment";
import Utils from "utils";
import RevenueReportSummary from './RevenueReportSummary';
import PackageGroupService from "../../../services/PackageGroupService";

const { RangePicker } = DatePicker;

class RevenueReports extends React.Component {
  columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      render: (text, record, id) => id + 1,
    },
    {
      title: 'Nhà mạng',
      dataIndex: 'telco',
      width: 150
    },
    {
      title: 'Dịch vụ',
      dataIndex: 'service',
      width: 100
    },
    {
      title: 'Gói cước',
      dataIndex: 'package_code',
      width: 150,
      render: (_text, record) => {
        if (record?.package_code) {
          return record.package_code
        } else {
          return record.package_name
        }
      }
    },
    {
      title: 'Đối tác',
      dataIndex: 'partner',
      width: 150
    },
    {
      title: 'Đăng ký mới',
      dataIndex: 'new_register_total',
      width: 150
    },
    {
      title: 'Đăng ký cùng kỳ',
      dataIndex: 'register_same_period',
      width: 150,
      render: register_same_period => <NumberFormat value={register_same_period} displayType="text" thousandSeparator={true} />
    },
    {
      title: 'Tỉ lệ trừ cước',
      dataIndex: 'rate_of_charge_deduction',
      render: rate_of_charge_deduction => {
        let badge = ' ant-badge-green';
        let arrow = <ArrowUpOutlined />;
        if (rate_of_charge_deduction < 0) {
          badge = ' ant-badge-red';
          arrow = <ArrowDownOutlined />;
        }
        return <>
          <span className={'ant-badge ant-badge-pill font-size-12' + badge} style={{ width: '90px' }}>
            {arrow}<span className="ant-font-weight-semibold m-l-5">{Number(rate_of_charge_deduction).toFixed(2)}%</span>
          </span>
        </>
      }
    },
    {
      title: 'Doanh thu khách hàng',
      dataIndex: 'revenue',
      render: revenue => <NumberFormat value={revenue} displayType="text" thousandSeparator={true} />
    },
    {
      title: 'Doanh thu khách hàng cùng kỳ',
      dataIndex: 'revenue_same_period',
      width: 150,
      render: revenue_same_period => <NumberFormat value={revenue_same_period} displayType="text" thousandSeparator={true} />
    },
    {
      title: 'Doanh thu sau Telco',
      dataIndex: 'revenue_after_telco',
      width: 150,
      render: revenue_after_telco => <NumberFormat value={revenue_after_telco} displayType="text" thousandSeparator={true} />
    },
    {
      title: 'Doanh thu sau Telco cùng kỳ',
      dataIndex: 'revenue_after_telco_same_period',
      width: 150,
      render: revenue_after_telco_same_period => <NumberFormat value={revenue_after_telco_same_period} displayType="text" thousandSeparator={true} />
    },
    {
      title: 'Thời gian',
      dataIndex: 'submit_date',
      width: 150
    }
  ];

  constructor(props) {
    super(props);

    this.state = {
      telcos: [],
      telcosLoading: false,
      services: [],
      servicesLoading: false,
      partners: [],
      partnersLoading: false,
      packages: [],
      packagesLoading: false,

      packageTypes: [],
      packageTypeLoading: false,

      statistic: [],
      statisticLoading: false,

      usersAndRevenue: [],
      usersAndRevenueLoading: false,

      revenue: [],
      revenueLoading: false,

      filters: {}
    };

    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    this.defaultDate();
    // load for filters
    this.loadServices();
    this.loadTelcos();
    this.loadPartners();
    this.loadPackageType();
    this.loadPackages();
    this.update();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  loadTelcos = async () => {
    this.setState({
      telcosLoading: true
    })

    const telcos = await TelcoService.read();
    this._isMounted && this.setState({
      telcos,
      telcosLoading: false
    })
  }

  loadServices = async () => {
    this.setState({
      servicesLoading: true
    })

    const services = await ServiceService.read();
    this._isMounted && this.setState({
      services,
      servicesLoading: false
    })
  }

  loadPackages = async () => {
    this.setState({
      packagesLoading: true
    })

    const packages = await PackageService.read();
    this._isMounted && this.setState({
      packages,
      packagesLoading: false
    })
  }

  loadPartners = async () => {
    this.setState({
      partnersLoading: true
    })

    const partners = await partnerService.read();
    this._isMounted && this.setState({
      partners,
      partnersLoading: false
    })
  }

  loadPackageType = async () => {
    this.setState({
      packageTypeLoading: true
    })

    const packageTypes = await PackageGroupService.read();
    this._isMounted && this.setState({
      packageTypes,
      packageTypeLoading: false
    })
  }

  defaultDate = () => {
    const { filters } = this.state;

    let defaultDates = Utils.getDefaultDates();

    if (filters.submit_date_gte === undefined || filters.submit_date_lte === undefined) {
      filters['submit_date_gte'] = defaultDates[0].startOf('day').format('YYYY-MM-DD');
      filters['submit_date_lte'] = defaultDates[1].endOf('day').format('YYYY-MM-DD HH:mm:ss');
    }

    this.setState({
      filters
    });
  }

  getStatistic = async (params) => {
    this.setState({
      statisticLoading: true
    });
    const { filters } = this.state;
    const __param = Object.assign(filters, params);
    const statistic = await RevenueReportService.getOverview(__param);
    this._isMounted && this.setState({
      statisticLoading: false,
      statistic
    });
  }

  getRevenue = async (params) => {
    this.setState({
      revenueLoading: true
    });
    const { filters } = this.state;
    const __param = Object.assign(filters, params);
    const revenue = await RevenueReportService.getRevenue(__param);
    this._isMounted && this.setState({
      revenueLoading: false,
      revenue
    });
  }

  handleDownload = async () => {
    this.state.filters._limit = -1
    const url = await RevenueReportService.export(this.state.filters)
    if (url) {
      Utils.downloadBySaveFile(url, url.replace(/^.*[\\/]/, ''))
    }
  }

  update = (params = {}) => {
    this.getStatistic(params);
    this.getRevenue(params);
  }

  onFinish = async (values) => {
    this.setState({
      statisticLoading: true,
      usersAndRevenueLoading: true,
      revenueLoading: true,
    });

    let filters = {};
    if (values.telco_id) {
      filters['telco_id_in'] = values.telco_id;
    }

    if (values.service_id) {
      filters['service_id_in'] = values.service_id;
    }

    if (values?.package_type) {
      filters['package_type'] = values.package_type;
    }

    if (values.partner_id) {
      filters['partner_id_in'] = values.partner_id;
    }

    if (values.package_id) {
      filters['package_id_in'] = values.package_id;
    }

    if (values.submit_date === undefined) {
      let defaultDates = Utils.getDefaultDates();
      if (filters.submit_date_gte === undefined || filters.submit_date_lte === undefined) {
        filters['submit_date_gte'] = defaultDates[0].startOf('day').format('YYYY-MM-DD');
        filters['submit_date_lte'] = defaultDates[1].endOf('day').format('YYYY-MM-DD');
      }
    }
    if (values.submit_date !== undefined && values.submit_date !== '') {
      let submitTimeFrom = new Date(values.submit_date[0]);
      let submitTimeTo = new Date(values.submit_date[1]);
      submitTimeFrom = moment(submitTimeFrom).format('YYYY-MM-DD');
      submitTimeTo = moment(submitTimeTo).format('YYYY-MM-DD');

      filters['submit_date_gte'] = submitTimeFrom;
      filters['submit_date_lte'] = submitTimeTo;
    }
    this.update(filters);
    this.setState({
      statisticLoading: false,
      usersAndRevenueLoading: false,
      revenueLoading: false,
    });
  }

  summary = (data) => {
    const { revenue } = this.state
    const { statistic } = this.state
    let sum_new_register = 0;
    let sum_register_same_period = 0;
    let sum_rate_of_charge_deduction = 0;
    let sum_revenue = 0;
    let sum_revenue_after_telco = 0;
    let sum_revenue_after_telco_same_period = 0;
    let sum_revenue_same_period = 0;
    revenue.forEach(({ new_register_total, register_same_period, rate_of_charge_deduction, revenue, revenue_after_telco, revenue_after_telco_same_period, revenue_same_period }) => {
      sum_new_register += new_register_total;
      sum_register_same_period += register_same_period;
      sum_rate_of_charge_deduction += rate_of_charge_deduction;
      sum_revenue += revenue;
      sum_revenue_after_telco += revenue_after_telco;
      sum_revenue_after_telco_same_period += revenue_after_telco_same_period;
      sum_revenue_same_period += revenue_same_period;
    })
    let badge = ' ant-badge-green';
    let arrow = <ArrowUpOutlined />;
    if (sum_rate_of_charge_deduction < 0) {
      badge = ' ant-badge-red';
      arrow = <ArrowDownOutlined />;
    }

    let f_sum_rate_of_charge_deduction = Number(sum_rate_of_charge_deduction / revenue.length);
    if (isNaN(f_sum_rate_of_charge_deduction)) {
      sum_rate_of_charge_deduction = '';
    } else {
      sum_rate_of_charge_deduction = (
        <>
          <span className={'ant-badge ant-badge-pill font-size-12' + badge} style={{ width: '90px' }}>
            {arrow}
            <span className="ant-font-weight-semibold m-l-5">{Number(sum_rate_of_charge_deduction / revenue.length).toFixed(2)}%</span>
          </span>
        </>
      );
    }

    let sumTotalSamePeriod = statistic?.revenue_same_period_total ? statistic.revenue_same_period_total : 0
    let sumAfterTelco = statistic?.revenue_after_telco_total ? statistic.revenue_after_telco_total : 0

    return (
      <Table.Summary.Row>
        <Table.Summary.Cell colSpan={5} align="left">Tổng cộng</Table.Summary.Cell>
        <Table.Summary.Cell>
          <NumberFormat value={sum_new_register} displayType="text" thousandSeparator={true} />
        </Table.Summary.Cell>
        <Table.Summary.Cell>
          <NumberFormat value={sum_register_same_period} displayType="text" thousandSeparator={true} />
        </Table.Summary.Cell>
        <Table.Summary.Cell>
          {sum_rate_of_charge_deduction}
        </Table.Summary.Cell>
        <Table.Summary.Cell>
          <NumberFormat value={sum_revenue} displayType="text" thousandSeparator={true} />
        </Table.Summary.Cell>
        <Table.Summary.Cell>
          <NumberFormat value={sumTotalSamePeriod} displayType="text" thousandSeparator={true} />
        </Table.Summary.Cell>
        <Table.Summary.Cell>
          <NumberFormat value={sumAfterTelco} displayType="text" thousandSeparator={true} />
        </Table.Summary.Cell>
        <Table.Summary.Cell>
          <NumberFormat value={sum_revenue_after_telco_same_period} displayType="text" thousandSeparator={true} />
        </Table.Summary.Cell>
      </Table.Summary.Row>
    )

  }

  render() {
    const {
      statisticLoading,
      partners,
      services,
      statistic,
      telcos,
      revenue,
      packageTypes,
      revenueLoading
    } = this.state;


    return (
      <>
        <Form 
          layout="vertical" 
          onFinish={this.onFinish} 
          initialValues={{ 
            submit_date: Utils.getDefaultDates()
          }}
        >
          <Row style={{ marginTop: '15px' }} gutter={[24, 24]}>
            <Col md={4} sm={24} xs={24}>
              <Form.Item name="telco_id" label="Nhà mạng">
                <Select style={{ width: '100%' }}
                  mode="tags"
                  placeholder="Lựa chọn nhà mạng"
                  optionFilterProp="children"
                  filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {
                    telcos && telcos.map((s, i) => <Select.Option key={s.id} value={s.id}>{s.name}</Select.Option>)
                  }
                </Select>
              </Form.Item>
            </Col>
            <Col md={4} sm={24} xs={24}>
              <Form.Item name="service_id" label="Dịch vụ">
                <Select style={{ width: '100%' }}
                  placeholder="Lựa chọn dịch vụ"
                  mode="tags"
                  optionFilterProp="children"
                  filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {
                    services && services.map((s, i) => <Select.Option key={s.id} value={s.id}>{s.name}</Select.Option>)
                  }
                </Select>
              </Form.Item>
            </Col>
            <Col md={4} sm={24} xs={24}>
              <Form.Item name="partner_id" label="Đối tác" style={{ width: '100%' }}>
                <Select style={{ width: '100%' }}
                  placeholder="Lựa chọn đối tác"
                  mode="tags"
                  optionFilterProp="children"
                  filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {
                    partners && partners.map((s, i) => <Select.Option key={s.id} value={s.id}>{s.name}</Select.Option>)
                  }
                </Select>
              </Form.Item>
            </Col>
            <Col md={4} sm={24} xs={24} className="d-flex">
              <Form.Item name="package_type" label="Gói cước" style={{ width: '100%' }}>
                <Select style={{ width: '100%' }}
                  placeholder="Lựa chọn gói cước"
                  mode="tags"
                  optionFilterProp="children"
                  filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {
                    packageTypes && packageTypes.map((s, i) => <Select.Option key={s.id} value={s.id}>{s.name}</Select.Option>)
                  }
                </Select>
              </Form.Item>
            </Col>
            <Col md={8} sm={24} xs={24}>
              <Form.Item name="submit_date" label="Thời gian lọc"
                style={{ width: '70%', 'float': 'left', marginRight: '5px' }}>
                <RangePicker style={{ width: '100%' }} />
              </Form.Item>
              <Form.Item style={{ width: '25%', 'float': 'left', marginLeft: '5px' }}>
                <Button className="float-right" type="primary" htmlType="submit"
                  style={{ marginTop: '20px', width: '100%', padding: '0px' }}>Tra cứu</Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <RevenueReportSummary data={statistic} loading={statisticLoading} />
        <Card>
          <h5 style={{ marginBottom: '30px' }}>Bảng số liệu doanh thu</h5>
          <Row>
            <Col xs={24}>
              <Button type="primary"
                style={{ marginBottom: 16, borderRadius: '5px', float: 'right', marginLeft: '15px' }}
                icon={<DownloadOutlined />} onClick={this.handleDownload}>
                Tải về
              </Button>
              <Table
                columns={this.columns}
                dataSource={revenue}
                loading={revenueLoading}
                rowKey={record => record.id}
                pagination={{ defaultPageSize: 10, showSizeChanger: true }}
                summary={this.summary}
              />
            </Col>
          </Row>
        </Card>
      </>
    );
  }
}

export default RevenueReports;
