import React from 'react'
import { Button, Card, Col, DatePicker, Form, Input, Row, Select, Table } from "antd";
import { TagOutlined } from "@ant-design/icons";
import MoService from "services/MoService";
import ServiceService from "services/ServiceService";
import TelcoService from "services/TelcoService";
import PartnerService from "services/PartnerService";
import moment from "moment";
import Utils from "utils";
import { PATTERN_PHONE } from "configs/AppConfig";
import {
    DownloadOutlined
} from '@ant-design/icons';

const { RangePicker } = DatePicker;

class MO extends React.Component {
    state = {
        mos: [],
        packages: [],
        services: [],
        telcos: [],
        partners: [],
        pagination: {
            current: 1,
            pageSize: 10,
        },
        filters: {},
        visible: 10,
    }
    columns = [
        {
            title: 'STT',
            dataIndex: 'stt',
            key: 'stt',
            render: (text, record, index) => {
                const { current, pageSize } = this.state.pagination;

                return ((current - 1) * pageSize) + index + 1
            },
        },
        {
            title: 'Số điện thoại',
            dataIndex: 'msisdn',
            key: 'msisdn',
        },
        {
            title: 'Dịch vụ',
            dataIndex: ['service_id', 'name'],
            key: ['service_id', 'name']
        },
        {
            title: 'Nội dung MO',
            dataIndex: 'command',
            key: 'command',
        },
        {
            title: 'Loại MO',
            dataIndex: 'action',
            key: 'action',
            render: (action, record) => {
                if (action === '0') {
                    return 'Đăng ký';
                } else if (action === '1') {
                    return 'Hủy';
                } else if (action === '5') {
                    return 'Sai cú pháp';
                } else {
                    return 'Không có';
                }
            }
        },
        {
            title: 'Nhà mạng',
            dataIndex: ['telco_id', 'name'],
            key: ['telco_id', 'name'],
        },
        {
            title: 'Thời gian nhận',
            dataIndex: 'received_time',
            key: 'received_time',
            render: (received_time) => moment.utc(received_time).format("YYYY-MM-DD HH:mm:ss")
        },
    ];

    constructor(props) {
        super(props);

        this._isMounted = false;
    }

    getTotalCount = async () => {
        const { filters } = this.state;
        const total = await MoService.count(filters);
        const { pagination } = this.state;
        this._isMounted && this.setState({
            pagination: {
                ...pagination,
                total
            }
        })
    }

    handleDownload = async () => {
        this.state.filters._limit = -1
        const url = await MoService.export(this.state.filters)
        if (url) {
            Utils.downloadBySaveFile(url, url.replace(/^.*[\\/]/, ''))
        }
    }

    getDefaultDate = () => {
        const { filters } = this.state;
        let defaultDates = Utils.getDefaultDates();

        if (filters.received_time_gte === undefined || filters.received_time_lte === undefined) {
            filters['received_time_gte'] = moment.utc(defaultDates[0]).format('YYYY-MM-DDT00:00:00.000Z');
            filters['received_time_lte'] = moment.utc(defaultDates[1]).format('YYYY-MM-DDT23:59:59.000Z');
        }
        this.setState({
            filters
        })
    }

    loadData = async (params) => {
        this.setState({
            loading: true
        })
        const { filters } = this.state;

        const __pagination = Utils.getPaginationParams(params);
        const __params = Object.assign(filters, __pagination);
        const mos = await MoService.read(__params);

        const { pagination } = this.state;
        this._isMounted && this.setState({
            loading: false,
            mos,
            filters,
            pagination: {
                ...pagination,
                ...params
            }
        });
        await this.getTotalCount();
    }

    loadServices = async () => {
        const services = await ServiceService.read();
        this._isMounted && this.setState({
            services
        })
    }
    loadTelcos = async () => {
        const telcos = await TelcoService.read();
        this._isMounted && this.setState({
            telcos
        })
    }
    loadPartners = async () => {
        const partners = await PartnerService.read();
        this._isMounted && this.setState({
            partners
        })
    }

    componentDidMount() {
        this._isMounted = true;
        this.getDefaultDate();
        this.loadData();
        this.getTotalCount();
        this.loadServices();
        this.loadTelcos();
        this.loadPartners();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onScroll = async (params) => {
        let { visible } = this.state;
        const mos = await MoService.read();
        const mo = mos.slice(0, visible);
        this._isMounted && this.setState((prev) => {
            return {
                visible: prev.visible + 10,
                mos: mo
            }
        })
    }

    onFinish = async (values) => {
        this.setState({
            loading: true
        })
        let filters = {};
        if (values.phone !== undefined) {
            let _phone = values.phone;
            if ((/(^(09|03|07|08|05)+([0-9]{8}$))/).test(_phone)) {
                _phone = _phone.replace('0', '84')
            }
            filters['msisdn_contains'] = _phone;
        }
        if (values.syntax !== undefined) {
            filters['command_contains'] = values.syntax;
        }

        if (values.received_time !== undefined && values.received_time !== null && values.received_time !== '') {
            let received_time_from = new Date(values.received_time[0]);
            let received_time_to = new Date(values.received_time[1]);
            received_time_from = moment.utc(received_time_from).format('YYYY-MM-DDT00:00:00.000Z');
            received_time_to = moment.utc(received_time_to).format('YYYY-MM-DDT23:59:59.000Z');
            filters['received_time_gte'] = received_time_from;
            filters['received_time_lte'] = received_time_to;
        }
        if (values.telco !== undefined && typeof values.telco === "object") {
            filters['telco_id_in'] = values.telco;
        }
        if (values.service !== undefined && typeof values.service === "object") {
            filters['service_id_in'] = values.service;
        }
        if (values.status !== undefined) {
            filters['action_eq'] = values.status;
        }
        this.setState({
            filters,
            pagination: {
                current: 1,
                pageSize: 10,
            },
            loading: false
        });
        this.loadData();
    }
    onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    handleTableChange = (pagination) => {
        this.loadData(pagination);
    }

    render() {
        const { mos, telcos, services, pagination, loading}  = this.state;
        return (
            <>
                <Card>
                    <Form layout="vertical" onFinish={this.onFinish} onFinishFailed={this.onFinishFailed}>
                        <Row gutter={[24, 24]}>
                            <Col md={6} sm={24} xs={24}>
                                <Form.Item name="phone"
                                    label="Số điện thoại"
                                    rules={[
                                        {
                                            pattern: PATTERN_PHONE,
                                            message: 'Không đúng định dạng số điện thoại',
                                        },
                                    ]}
                                >
                                    <Input size="default" placeholder="Nhập số điện thoại cần tra cứu" />
                                </Form.Item>
                            </Col>
                            <Col md={6} sm={24} xs={24}>
                                <Form.Item name="syntax" label="Nội dung MO">
                                    <Input size="default" placeholder="Nhập nội dung MO"
                                        prefix={<TagOutlined />} />
                                </Form.Item>
                            </Col>
                            <Col md={6} sm={24} xs={24}>
                                <Form.Item name="status" label="Loại MO"
                                    style={{ width: '100%', float: 'left', marginRight: '5px' }}>
                                    <Select style={{ width: '100%' }}
                                        placeholder="Lựa chọn loại MO"

                                        mode="tags"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        <Select.Option value="0">Đăng ký</Select.Option>
                                        <Select.Option value="1">Hủy</Select.Option>
                                        <Select.Option value="5">Sai cú pháp</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col md={6} sm={24} xs={24}>
                                <Form.Item name="received_time" label="Thời gian nhận" initialValue={Utils.getDefaultDates()}>
                                    <RangePicker style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>

                            <Col md={6} sm={24} xs={24}>
                                <Form.Item name="telco" label="Nhà mạng">
                                    <Select style={{ width: '100%' }}
                                        placeholder="Lựa chọn nhà mạng"

                                        mode="tags"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {
                                            telcos.map((t, index) => <Select.Option key={`telco-${index}`}
                                                value={t.id}>{t.name}</Select.Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col md={6} sm={24} xs={24}>
                                <Form.Item name="service" label="Dịch vụ"
                                    style={{ width: '65%', float: 'left', marginRight: '5px' }}>
                                    <Select style={{ width: '100%' }}
                                        placeholder="Lựa chọn dịch vụ"

                                        mode="tags"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {
                                            services.map((s, index) => <Select.Option key={`service-${index}`}
                                                value={s.id}>{s.name}</Select.Option>)
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item style={{ width: '30%', float: 'left', marginLeft: '5px' }}>
                                    <Button className="float-right" type="primary" htmlType="submit"
                                        style={{ width: '100%', marginTop: '20px', padding: '0px' }}>Lọc</Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Card>
                <Row gutter={[24, 24]}>
                    <Col md={24} sm={24} xs={24}>
                        <Card title="Danh sách MO">
                            <Button type="default"
                                    style={{marginBottom: 16, borderRadius: '5px', float: 'right', marginLeft: '15px'}}
                                    icon={<DownloadOutlined/>}
                                    onClick={this.handleDownload}>
                                Tải về
                            </Button>
                            <Table
                                columns={this.columns}
                                dataSource={mos}
                                loading={loading}
                                pagination={pagination}
                                onChange={this.handleTableChange}
                                rowKey={record => record.id}
                            />
                            {/*<Table columns={this.columns} dataSource={mos} pagination={false}/>*/}
                        </Card>
                    </Col>
                </Row>
            </>
        )
    }
}

export default MO;
