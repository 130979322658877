import React, { useEffect, useState } from 'react';
import { Modal, Form, Select, DatePicker, InputNumber } from 'antd';


const TELCO = 'telco';
const PARTNER = 'partner';

export const TYPES = [{
  name: 'Nhà mạng',
  value: TELCO,
  id: 'type-1'
}, {
  name: 'Đối tác',
  value: PARTNER,
  id: 'type-2'
}];
export const STATUS = [{
  name: 'Không kích hoạt',
  value: 'inactive',
  id: 'status-1'
}, {
  name: 'Kích hoạt',
  value: 'active',
  id: 'status-2'
}];

const BenefitRatesModalForm = ({
  visible,
  initialValues = null,
  onCreate,
  onCancel,
  services = [],
  partners = [],
  telcos = [],

  confirmLoading = false,
  isEditing = false
}) => {
  const [form] = Form.useForm();
  const [isTelco, setIsTelco] = useState(true);

  console.log(initialValues)

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
    } else {
      form.resetFields();
    }
  }, [form, initialValues]);

  const okText = isEditing ? 'Sửa' : 'Thêm';
  let title;

  if (isEditing) {
    title = 'Chỉnh sửa';
  } else {
    title = 'Thêm mới';
  }

  const handleTypeChange = (value) => {
    if (value === TELCO) {
      setIsTelco(true)
    } else {
      setIsTelco(false)
    }
  };

  return (
    <Modal
      forceRender
      confirmLoading={confirmLoading}
      visible={visible}
      title={title}
      okText={okText}
      cancelText="Hủy"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onCreate({
              ...initialValues,
              ...values
            });
          })
          .catch((info) => {
            console.log(info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="packageForm"
        initialValues={initialValues}
      >
        <Form.Item
          label="Loại"
          name="type"
          rules={[{ required: true, message: 'Cú pháp không được để trống' }]}
          initialValue={'telco'}
        >
          <Select
            placeholder="Chọn loại"
            filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            onChange={handleTypeChange}
          >
            {
              TYPES && TYPES.map(s => <Select.Option key={s.id} value={s.value}>{s.name}</Select.Option>)
            }
          </Select>
        </Form.Item>

        <Form.Item
          label="Tỉ lệ"
          name="rate"
          rules={[{ required: true, message: 'Vui lòng nhập tỉ lệ phân chia' }]}
        >
          <InputNumber min={0} max={100} formatter={value => `${value}%`} parser={value => value.replace('%', '')} style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          label="Trạng thái"
          name="status"
          rules={[{ required: true, message: 'Vui lòng chọn trạng thái' }]}
        >
          <Select
            placeholder="Chọn trạng thái"
            filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {
              STATUS && STATUS.map(s => <Select.Option key={s.id} value={s.value}>{s.name}</Select.Option>)
            }
          </Select>
        </Form.Item>

        <Form.Item
          label="Ngày bắt đầu"
          name="start_date"
          rules={[{ required: true, message: 'Vui lòng nhập ngày bắt đầu' }]}
          format="DD/MM/YYYY HH:mm:ss"
        >
          <DatePicker showTime style={{ width: '100%' }}  />
        </Form.Item>
        {
          isTelco ? (
            <Form.Item
              label="Nhà mạng"
              name={['telco_id', 'id']}
              rules={[{ required: true, message: 'Vui lòng chọn nhà mạng' }]}
            >
              <Select>
                {telcos.map(d => <Select.Option key={d.id} value={d.id}>{d.name}</Select.Option>)}
              </Select>
            </Form.Item>
          ) : null
        }
        {
          !isTelco ? (
            <Form.Item
              label="Đối tác"
              name={['partner_id', 'id']}
              rules={[{ required: true, message: 'Vui lòng chọn đối tác' }]}
            >
              <Select>
                {partners.map(d => <Select.Option key={d.id} value={d.id}>{d.name}</Select.Option>)}
              </Select>
            </Form.Item>
          ) : null
        }
        <Form.Item
          label="Dịch vụ"
          name={['service_id', 'id']}
          rules={[{ required: true, message: 'Vui lòng chọn dịch vụ' }]}
        >
          <Select>
            {services.map(d => <Select.Option key={d.id} value={d.id}>{d.name}</Select.Option>)}
          </Select>
        </Form.Item>
        <Form.Item
          label="Ngưỡng trên"
          name="upper_threshold"
          rules={[{ required: true, message: 'Vui lòng ngưỡng trên' }]}
        >
          <InputNumber style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          label="Ngưỡng dưới"
          name="lower_threshold"
          rules={[{ required: true, message: 'Vui lòng ngưỡng dưới' }]}
        >
          <InputNumber style={{ width: '100%' }} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default BenefitRatesModalForm;
