//
export const LOAD_ALL_USER_GROUPS = 'LOAD_ALL_USER_GROUPS'
export const LOAD_ALL_USER_GROUPS_SUCCESS = 'LOAD_ALL_USER_GROUPS_SUCCESS'
export const LOAD_ALL_USER_GROUPS_ERROR = 'LOAD_ALL_USER_GROUPS_ERROR'
//
export const LOAD_DETAIL_GROUPS = 'LOAD_DETAIL_GROUPS'
export const LOAD_DETAIL_GROUPS_SUCCESS = 'LOAD_DETAIL_GROUPS_SUCCESS'
export const LOAD_DETAIL_GROUPS_ERROR = 'LOAD_DETAIL_GROUPS_ERROR'
//
export const LOAD_LIST_USERS_BY_GROUP = 'LOAD_LIST_USERS_BY_GROUP'
export const LOAD_LIST_USERS_BY_GROUP_SUCCESS = 'LOAD_LIST_USERS_BY_GROUP_SUCCESS'
export const LOAD_LIST_USERS_BY_GROUP_ERROR = 'LOAD_LIST_USERS_BY_GROUP_ERROR'

export const SELECT_GROUP = 'SELECT_GROUP'



export const loadAllUserGroups = () => {
  return {
    type: LOAD_ALL_USER_GROUPS
  }
}


export const loadAllUserGroupsSuccess = (groups) => {
  return {
    type: LOAD_ALL_USER_GROUPS_SUCCESS,
    groups
  }
}

export const loadAllUserGroupError = (error) => {
  return {
    type: LOAD_ALL_USER_GROUPS_ERROR,
    error
  }
}

export const loadDetailGroups = (groupID) => {
  return {
    type: LOAD_DETAIL_GROUPS,
    groupID
  }
}


export const loadDetailGroupsSuccess = (groups, groupID) => {
  return {
    type: LOAD_DETAIL_GROUPS_SUCCESS,
    groups,
    groupID
  }
}

export const loadDetailGroupError = (error) => {
  return {
    type: LOAD_DETAIL_GROUPS_ERROR,
    error
  }
}

export const loadListUserByGroups = (id) => {
  return {
    type: LOAD_LIST_USERS_BY_GROUP, 
    id
  }
}


export const loadListUserByGroupsSuccess = (users, id) => {
  return {
    type: LOAD_LIST_USERS_BY_GROUP_SUCCESS,
    users,
    id
  }
}

export const loadListUserByGroupError = (error) => {
  return {
    type: LOAD_LIST_USERS_BY_GROUP_ERROR,
    error
  }
}

export const selectCurrentGroup = (group) => {
  return {
    type: SELECT_GROUP,
    group
  }
}
