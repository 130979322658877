import {
  CHANGE_SERVICE, GET_STATISTIC_INFO, UPDATE_STATISTIC_INFO
} from '../constants/Statistic';

const initState = {
  	service: '',
	statistic: null
}

const statistic = (state = initState, action) => {
	switch (action.type) {
		case CHANGE_SERVICE:
			return {
				...state,
				service: action.service
			}
		
		case GET_STATISTIC_INFO:
			return {
				...state,
				statistic: action.payload
			}

		case UPDATE_STATISTIC_INFO:
			return {
				...state,
				statistic: action.payload
			}

		default:
			return state;
	}
}

export default statistic