import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import Dashboard from './dashboard';
import RevenueReports from "./revenue-reports";
import SubscriptionInformation from "./customer-service/subscription-information";
import Partner from "./general-management/partner";
import ListContent from "./content-management";
import MO from "./customer-service/mo";
import MT from "./customer-service/mt";
import History from "./customer-service/history";
import HomeNetWork from "./general-management/home-network";
import Services from "./service-management/services";
import Package from "./service-management/package";
import Syntax from "./service-management/syntax";
import SmartReport from "./tools/smart-report";
import MobifoneDoiSoat from "./tools/mobifone-doi-soat";
import Support from "./tools/support";
import MobifoneApi from "./tools/mobifone-api";
import VinafoneDoiSoat from "./tools/vinafone-doi-soat";
import Lottery from "./tools/xoso";
import Tool9029 from "./tools/tool-9029";
import UserProfile from "./user-profile/index"
import ChangePassword from './user-profile/ChangePassword'
import UserList from "./settings/user";
import ForControlHomeNetwork from "./for-control/home-network";
import CSKHProfile from "./customer-service/CSKH-Profile";
import BenefitRatesManagement from "./settings/benefit-rates-management";
import PackageGroup from "./service-management/package-group";
import ServiceGroup from "./service-management/service-group";
import CustomerCareHistory from "./customer-service/CustomerCareHistory";
import {ForPartner} from "./for-control/partner";

export const AppViews = () => {
    return (
        <Switch>
            {/* Dashboard */}
            <Route resource="viewapp" path={`${APP_PREFIX_PATH}/dashboard`} component={Dashboard} />
            
            {/* Báo cáo doanh thu */}
            <Route resource="viewapp" path={`${APP_PREFIX_PATH}/bao-cao-doanh-thu`} component={RevenueReports} />
            
            {/* Chăm sóc khách hàng */}
            <Route resource="viewapp" path={`${APP_PREFIX_PATH}/cham-soc-khach-hang/thong-tin-thue-bao`} component={SubscriptionInformation} />
            <Route resource="viewapp" path={`${APP_PREFIX_PATH}/cham-soc-khach-hang/lich-su-mo`} component={MO} />
            <Route resource="viewapp" path={`${APP_PREFIX_PATH}/cham-soc-khach-hang/lich-su-mt`} component={MT} />
            <Route resource="viewapp" path={`${APP_PREFIX_PATH}/cham-soc-khach-hang/lich-su-cskh`} component={CustomerCareHistory} />
            <Route resource="viewapp" path={`${APP_PREFIX_PATH}/cham-soc-khach-hang/lich-su-dang-ky`} component={History} />
            <Route resource="viewapp" path={`${APP_PREFIX_PATH}/subscription/phone/:phone`} component={CSKHProfile} />

            {/* Quản lý chung */}
            <Route resource="viewapp" path={`${APP_PREFIX_PATH}/quan-ly-chung/doi-tac`} component={Partner} />
            <Route resource="viewapp" path={`${APP_PREFIX_PATH}/quan-ly-chung/nha-mang`} component={HomeNetWork} />
            
            {/* Quản lý nội dung */}
            <Route resource="viewapp" path={`${APP_PREFIX_PATH}/quan-ly-noi-dung/danh-sach-noi-dung`} component={ListContent} />
            
            {/* Quản lý dịch vụ */}
            <Route resource="viewapp" path={`${APP_PREFIX_PATH}/quan-ly-dich-vu/dich-vu`} component={Services} />
            <Route resource="viewapp" path={`${APP_PREFIX_PATH}/quan-ly-dich-vu/nhom-dich-vu`} component={ServiceGroup} />
            <Route resource="viewapp" path={`${APP_PREFIX_PATH}/quan-ly-dich-vu/goi-cuoc`} component={Package} />
            <Route resource="viewapp" path={`${APP_PREFIX_PATH}/quan-ly-dich-vu/nhom-goi-cuoc`} component={PackageGroup} />
            <Route resource="viewapp" path={`${APP_PREFIX_PATH}/quan-ly-dich-vu/cu-phap`} component={Syntax} />

            {/* Thiết lập */}
            <Route resource="viewapp" path={`${APP_PREFIX_PATH}/thiet-lap/nguoi-dung`} component={UserList} />
            <Route resource="viewapp" path={`${APP_PREFIX_PATH}/thiet-lap/quan-ly-ti-le-phan-chia`} component={BenefitRatesManagement} />
            
            {/* Công cụ */}
            <Route resource="viewapp" path={`${APP_PREFIX_PATH}/cong-cu/bao-cao-thong-minh`} component={SmartReport} />
            <Route resource="viewapp" path={`${APP_PREFIX_PATH}/cong-cu/cham-soc-khach-hang`} component={Support} />
            <Route resource="viewapp" path={`${APP_PREFIX_PATH}/cong-cu/mobifone/api-gateway-mobiedu`} component={MobifoneApi} />
            <Route resource="viewapp" path={`${APP_PREFIX_PATH}/cong-cu/mobifone/doi-soat`} component={MobifoneDoiSoat} />
            <Route resource="viewapp" path={`${APP_PREFIX_PATH}/cong-cu/vinafone/doi-soat`} component={VinafoneDoiSoat} />
            <Route resource="viewapp" path={`${APP_PREFIX_PATH}/cong-cu/vinafone/cskh-xoso`} component={Lottery} />
            <Route resource="viewapp" path={`${APP_PREFIX_PATH}/cong-cu/vinafone/cskh-9029`} component={Tool9029} />

            {/* Thông tin cập nhật */}
            <Route resource="viewapp" path={`${APP_PREFIX_PATH}/thong-tin-nguoi-dung`} component={UserProfile} />
            <Route resource="viewapp" path={`${APP_PREFIX_PATH}/cap-nhat-mat-khau`} component={ChangePassword} />
            
            {/* Đối soát */}
            <Route resource="viewapp" path={`${APP_PREFIX_PATH}/for-control/partner`} component={ForPartner}/>
            <Route resource="viewapp" path={`${APP_PREFIX_PATH}/for-control/home-network`} component={ForControlHomeNetwork} />
            
            <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/dashboard`} />
        </Switch>
    )
}

export default AppViews;