import React, {Component} from 'react';
import SyntaxService from 'services/SyntaxService';
import ServiceService from "services/ServiceService";
import TelcoService from "services/TelcoService";
import {
    Row,
    Col,
    Card,
    Table,
    Button,
    Tooltip,
    Popconfirm,
    Form,
    Input,
    Modal,
    Select,
    message
} from 'antd';
import {
    EditOutlined,
    FileAddOutlined,
    DownloadOutlined,
    DeleteOutlined
} from '@ant-design/icons';
import moment from "moment";
import PackageService from "services/PackageService";
import PartnerService from "services/PartnerService";
import Utils from "utils";

const {TextArea} = Input;

const SyntaxPopup = ({visible, onCreate, onCancel, initialValues = null, confirmLoading = false, isEditing = false,telcos=[],services=[],packages=[], partners =[]}) => {
    const [form] = Form.useForm();
    if (initialValues) {
        form.setFieldsValue(initialValues);
    } else {
        form.resetFields();
    }

    const okText = isEditing ? 'Sửa' : 'Thêm';
    const title = isEditing ? 'Sửa cú pháp' : 'Thêm mới cú pháp';
    return (
        <Modal confirmLoading={confirmLoading}
               visible={visible}
               title={title}
               okText={okText}
               cancelText="Hủy"
               onCancel={onCancel}
               onOk={() => {
                   form
                       .validateFields()
                       .then((values) => {
                           onCreate({
                               ...initialValues,
                               ...values
                           });
                       })
                       .catch((info) => {
                           console.log(info);
                       });
               }}>
            <Form
                form={form}
                layout="vertical"
                name="syntaxForm"
                initialValues={initialValues}
            >
                <Form.Item
                    label="Cú pháp"
                    name="command_code"
                    rules={[{required: true, message: 'Cú pháp không được để trống'}]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="Mô tả dịch vụ"
                    name="content"
                    rules={[{
                        required: true,
                        message: 'Mô tả dịch vụ không được để trống'
                    }]}
                >
                    <TextArea/>
                </Form.Item>
                <Form.Item name={['package_id','id']} label="Gói cước" rules={[{
                    required: true,
                    message: 'Gói cước không được để trống'
                }]}>
                    <Select>
                        {
                            packages.map((p, index) => <Select.Option key={`package_${index}`}
                                                                      value={p.id}>{p.name}</Select.Option>)
                        }

                    </Select>
                </Form.Item>
                <Form.Item name={['service_id','id']} label="Dịch vụ" rules={[{
                    required: false,
                    message: 'Dịch vụ không được để trống'
                }]}>
                    <Select>
                        {
                            services.map((t, index) => <Select.Option key={`service_${index}`}
                                                                           value={t.id}>{t.name}</Select.Option>)
                        }

                    </Select>
                </Form.Item>
                <Form.Item name={['telco_id','id']} label="Nhà mạng" rules={[{
                    required: false,
                    message: 'Nhà mạng không được để trống'
                }]}>
                    <Select>
                        {
                            telcos.map((t, index) => <Select.Option key={`telco${index}`}
                                                                      value={t.id}>{t.name}</Select.Option>)
                        }

                    </Select>
                </Form.Item>
                <Form.Item name={['partner_id','id']} label="Đối tác" rules={[{
                    required: false,
                    message: 'Đối tác không được để trống'
                }]}>
                    <Select>
                        {
                            partners.map((p, index) => <Select.Option key={`partner_${index}`}
                                                                           value={p.id}>{p.name}</Select.Option>)
                        }
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export class Syntax extends Component {
    state = {
        syntaxs: [],
        packages: [],
        services: [],
        telcos: [],
        partners: [],
        loading: false,
        visible: false,
        confirmLoading: false,
        initialValues: {},
        isEditing: false,
        title: '',
        pagination: {
            current: 1,
            pageSize: 10,
        },
        filters: {}
    };
    columns = [
        {
            title: 'STT',
            dataIndex: 'id',
            key: 'id',
            render: (text, record, index) => {
                const { current, pageSize } = this.state.pagination;

                return ((current - 1) * pageSize) + index + 1
            },
            width:50
        },
        {
            title: 'Cú pháp',
            dataIndex: 'command_code',
            key: 'command_code',
            width: 150
        },
        {
            title: 'Mô tả',
            dataIndex: 'content',
            key: 'content',
            width: 300
        },
        {
            title: 'Loại cú pháp',
            dataIndex: 'type',
            key: 'type',
            width: 150,
            render: (type, record) => {
                if(type === 0) {
                    return 'Hủy';
                }
                else if(type === 1) {
                    return 'Đăng ký';
                }else{
                    return 'Unknown';
                }
            }
        },
        {
            title: 'Gói cước',
            dataIndex: ['package_id','name'],
            key: ['package_id','name'],
            width: 200
        },
        {
            title: 'Dịch vụ',
            dataIndex: ['service_id','name'],
            key: ['service_id','name'],
            width: 150
        },
        {
            title: 'Nhà mạng',
            dataIndex: ['telco_id','name'],
            key: ['telco_id','name'],
            width: 150
        },
        {
            title: 'Đối tác',
            dataIndex: ['partner_id','name'],
            key: ['partner_id','name'],
            width: 150
        },
        {
            title: 'Ngày tạo',
            dataIndex: 'published_at',
            key: 'published_at',
            width: 150,
            render: (createdAt)=>moment(createdAt).format("YYYY-MM-DD")
        },
        {
            title: 'Hành động',
            dataIndex: 'action',
            key: 'action',
            fixed: 'right',
            width: 150,
            render: (_, record) => (
                <>
                    <Tooltip title="Sửa">
                        <Button danger icon={<EditOutlined/>} size="small" onClick={() => this.handleEdit(record)}/>
                    </Tooltip>&nbsp;
                    <Popconfirm onConfirm={() => this.handleDelete(record)} title="Bạn muốn xóa cú pháp này phải không?">
                        <Tooltip title="Xóa">
                            <Button danger icon={<DeleteOutlined/>} size="small"/>
                        </Tooltip>
                    </Popconfirm>
                </>
            )
        },
    ];

    constructor(props) {
        super(props);

        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadData();
        this.getTotalCount();
        this.loadPackages();
        this.loadServices();
        this.loadTelcos();
        this.loadPartner();
    }

    getTotalCount = async () => {
        const {filters} = this.state;
        const total = await SyntaxService.count(filters);
        const {pagination} = this.state;
        this._isMounted && this.setState({
            pagination: {
                ...pagination,
                total
            }
        })
    }

    loadData = async (params) => {
        this.setState({
            loading: true
        })
        const {filters} = this.state;
        const __pagination = Utils.getPaginationParams(params);
        const __params = Object.assign(filters, __pagination);
        const syntaxs = await SyntaxService.read(__params);
        const { pagination } = this.state;
        this._isMounted && this.setState({
            loading: false,
            syntaxs,
            pagination: {
                ...pagination,
                ...params
            }
        })
    }

    loadPackages = async () => {
        const packages = await PackageService.read();
        this._isMounted && this.setState({
            packages
        })
    }

    loadServices = async () => {
        const services = await ServiceService.read();
        this._isMounted && this.setState({
            services
        })
    }

    loadPartner = async () => {
        const partners = await PartnerService.read();
        this._isMounted && this.setState({
            partners
        })
    }

    loadTelcos = async () => {
        const telcos = await TelcoService.read();
        this._isMounted && this.setState({
            telcos
        })
    }

    handleAdd = () => {
        this.setState({
            initialValues: null,
            isEditing: false,
            visible: true,
            title: 'Thêm cú pháp'
        })
    }
    handleCreate = async (syntax) => {
        try {
            const package_id = syntax.package_id.id;
            const service_id = syntax.service_id.id;
            const telco_id = syntax.telco_id.id;
            const partner_id = syntax.partner_id.id;
            syntax.package_id = package_id;
            syntax.service_id = service_id;
            syntax.telco_id = telco_id;
            syntax.partner_id = partner_id;
            await SyntaxService.create(syntax);
            await this.loadData();
            this.hideModal();
            message.success({content: 'Thêm mới thành công', duration: 2});
        } catch (error) {
            this.hideModal();
            message.error({content: 'Thêm mới không thành công', duration: 2})
        }
    }
    handleEdit = (syntax) => {
        this.setState({
            initialValues: syntax,
            isEditing: true,
            visible: true,
            title: 'Sửa cú pháp'
        })
    }
    handleUpdate = async (syntax) => {
        try {
            const package_id = syntax.package_id.id;
            const service_id = syntax.service_id.id;
            const telco_id = syntax.telco_id.id;
            const partner_id = syntax.partner_id.id;
            syntax.package_id = package_id;
            syntax.service_id = service_id;
            syntax.telco_id = telco_id;
            syntax.partner_id = partner_id;
            await SyntaxService.update(syntax);
            message.success({ content: `Cú pháp mới ${syntax.command_code} đã cập nhật`, duration: 2 });
            await this.loadData();
            this.hideModal();
            // const { syntaxs } = this.state;
            // const foundIndex = syntaxs.findIndex(u => u.id === syntax.id);
            //
            // if (foundIndex !== -1) {
            //     syntaxs[foundIndex] = {
            //         ...syntaxs[foundIndex],
            //         ...syntax
            //     };
            //     this.setState({
            //         syntaxs: [ ...syntaxs ],
            //         visible: false,
            //         confirmLoading: false,
            //         isEditing: false
            //     })
            // }
        } catch (error) {
            message.error({ content: `Cập nhật không thành công`, duration: 2 });
            this.hideModal();
        }
    }
    handleDelete = async (syntax) => {
        try {
            await SyntaxService.delete(syntax.id);
            const {syntaxs} = this.state;
            this._isMounted && this.setState({
                syntaxs: syntaxs.filter(d => d.id !== syntax.id)
            })
            message.success({content: 'Xóa thành công', duration: 2});
        } catch (error) {
            message.error({content: 'Xóa không thành công', duration: 2})
        }
    }
    hideModal = () => {
        this.setState({visible: false, confirmLoading: false})
    }
    onCancel = () => {
        this.hideModal();
    }

    onFinish = async (values) => {
        let filters = {};
        if (values.syntax !== undefined) {
            filters['command_code_contains'] = values.syntax;
        }
        if(values.telco !== undefined && typeof values.telco === "object"){
            filters['telco_id_in'] = values.telco;
        }
        if(values.service !== undefined && typeof values.service === "object"){
            filters['service_id_in'] = values.service;
        }
        if(values.package !== undefined && typeof values.package === "object"){
            filters['package_id_in'] = values.package;
        }
        if(values.partner !== undefined && typeof values.partner === "object"){
            filters['partner_id_in'] = values.partner;
        }

        this.setState({
            filters,
            pagination: {
                current: 1,
                pageSize: 10,
            },
        })
        await this.getTotalCount();
        this.loadData();
    }
    onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    handleTableChange = (pagination) => {
        this.loadData(pagination);
    }

    render() {
        const {visible, isEditing, confirmLoading, initialValues,loading,telcos,services,syntaxs,packages, partners,pagination} = this.state;
        const onCreate = isEditing ? this.handleUpdate : this.handleCreate;
        return (
            <>
                <Form layout="vertical" onFinish={this.onFinish} onFinishFailed={this.onFinishFailed}>
                    <Row style={{marginBottom: '15px'}} gutter={[24, 24]}>
                        <Col md={6} sm={24} xs={24}>
                            <Form.Item label="Cú pháp" name="syntax">
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col md={6} sm={24} xs={24}>
                            <Form.Item label="Dịch vụ" name="service" style={{width: '47%', float: 'left', marginRight: '5px'}}>
                                <Select style={{width: '100%'}}
                                        mode="tags"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                >
                                    {
                                        services.map((s, index) =>
                                            <Select.Option key={'service-option-' + index} value={s.id}>{s.name}</Select.Option>)
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item label="Đối tác" name="partner" style={{width: '47%', float: 'left', marginLeft: '5px'}}>
                                <Select style={{width: '100%'}}
                                        mode="tags"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                >
                                    {
                                        partners.map((p, index) =>
                                            <Select.Option key={'partner-option-' + index} value={p.id}>{p.name}</Select.Option>)
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col md={6} sm={24} xs={24}>
                            <Form.Item label="Tên gói" name="package">
                                <Select style={{width: '100%'}}
                                        mode="tags"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                >
                                    {
                                        packages.map((p, index) =>
                                            <Select.Option key={'package-option-' + index} value={p.id}>{p.name}</Select.Option>)
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col md={6} sm={24} xs={24}>
                            <Form.Item label="Nhà mạng" name="telco" style={{width: '65%', float: 'left', marginRight: '5px'}}>
                                <Select style={{width: '100%'}}
                                        mode="tags"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                >
                                    {
                                        telcos.map((t, index) =>
                                            <Select.Option key={'telco-option-' + index} value={t.id}>{t.name}</Select.Option>)
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item style={{width: '30%', float: 'left', marginLeft: '5px', marginTop: '20px'}}>
                                <Button type="primary" htmlType="submit" style={{float: 'right'}}>Lọc</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <SyntaxPopup
                    confirmLoading={confirmLoading}
                    visible={visible}
                    isEditing={isEditing}
                    initialValues={initialValues}
                    onCancel={this.onCancel}
                    onCreate={onCreate}
                    services={services}
                    telcos={telcos}
                    partners={partners}
                    packages={packages}/>
                <Row>
                    <Col xs={24}>
                        <Card title="Danh sách cú pháp">
                            <Button type="default"
                                    style={{marginBottom: 16, borderRadius: '5px', float: 'right', marginLeft: '15px'}}
                                    icon={<DownloadOutlined/>}>
                                Tải về
                            </Button>
                            <Button onClick={() => this.handleAdd()} type="primary"
                                    style={{marginBottom: 16, borderRadius: '5px', float: 'right'}}
                                    icon={<FileAddOutlined/>}>
                                Thêm mới cú pháp
                            </Button>
                            <Table 
                                columns={this.columns} 
                                pagination={pagination} 
                                onChange={this.handleTableChange} 
                                dataSource={syntaxs} 
                                loading={loading} 
                                rowKey={record => record.id}
                            />
                        </Card>
                    </Col>
                </Row>
            </>
        );
    }
}

export default Syntax