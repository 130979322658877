import React, { Component } from 'react';
import PropTypes from 'prop-types';
import socketIO from 'configs/web-sockets';

class Websocket extends Component {
  constructor(props) {
    super(props);

    this.state = {
      socket: socketIO
    }
  }

  componentDidMount() {
    this.setupWebsocket();
  }

  // componentWillUnmount() {
  //   // this.shouldReconnect = false;
  //   // clearTimeout(this.timeoutID);
  //   // this.state.ws.close();
  // }

  render() {
    return <></>;
  }

  setupWebsocket = () => {
    const { socket } = this.state;
    socket.on('call_log_event', (data) => {
      this.props.onMessage(data);
      
        console.log(data)


        // {msisdn: 849829382}
    })


    socket.on('connection', socket => {
      console.log('test')
    })

    socket.on('disconnect', () =>{
      console.log('a user disconnected')
    });

    socket.io.on('disconnect', () =>{
      console.log('a user disconnected')
    });
  }
}

Websocket.defaultProps = {
  debug: false,
  reconnect: true
};

Websocket.propTypes = {
  onMessage: PropTypes.func.isRequired,
  // onOpen: PropTypes.func,
  // onClose: PropTypes.func,
  // onError: PropTypes.func
};

export default Websocket;