import React from 'react';
import { Comment, Tooltip } from 'antd';
import moment from 'moment';

class CSKHComment extends React.Component {

    render() {
        const comments = this.props.comments;
        return (
            <Comment>
                {
                    comments.map((item) => {
                        let complain_type = 'Vấn đề: ';
                        let complain_reason = 'Nguyên nhân: ';
                        let type = 'Loại: ';
                        switch (item.complain_type) {
                            case 'cancel':
                                complain_type += 'Hủy dịch vụ';
                                break;
                            case 'register':
                                complain_type += 'Đăng ký dịch vụ';
                                break;
                            case 'check':
                                complain_type += 'Kiểm tra tình trạng thuê bao';
                                break;
                            case 'refund':
                                complain_type += 'Yêu cầu hoàn cước';
                                break;
                            case 'mt_not_receive':
                                complain_type += 'Không nhận được MT dịch vụ';
                                break;
                            case 'membership_code_invalid':
                                complain_type += 'Nhập mã hội viên không hợp lệ';
                                break;
                            case 'story_card_not_receive':
                                complain_type += 'Không nhận được phiếu đọc truyện';
                                break;
                            case 'other':
                                complain_type += 'Khác';
                                break;
                            default:
                                complain_type += 'Khác';
                                break;
                        }

                        switch (item.complain_reason) {
                            case 'unregister':
                                complain_reason += 'Không có nhu cầu sử dụng';
                                break;
                            case 'register':
                                complain_reason += 'Có nhu cầu sử dụng';
                                break;
                            case 'error':
                                complain_reason += 'Lỗi dịch vụ';
                                break;
                            case 'membership_code_invalid':
                                complain_reason += 'Nhập sai mã hội viên';
                                break;
                            case 'check':
                                complain_reason += 'Kiểm tra tình trạng thuê bao';
                                break;
                            case 'other':
                                complain_reason += 'Khác';
                                break;
                            default:
                                complain_reason += 'Khác';
                                break;
                        }

                        switch (item.type_note) {
                            case 'call':
                                type += 'Cuộc gọi';
                                break;
                            case 'email':
                                type += 'Email';
                                break;
                            case 'message':
                                type += 'Gửi tin nhắn (SMS / Zalo / Facebook,..)';
                                break;
                            case 'other':
                                type += 'Khác';
                                break;
                            default:
                                type += 'Khác';
                                break;
                        }

                        return <Comment
                            author={item.msisdn}
                            // avatar={
                            //     <Avatar
                            //         src={item.avatar}
                            //         alt={item.fullname}
                            //     />
                            // }
                            content={
                                <div>
                                    <p>
                                        <span style={{ fontSize: '13px' }} className="ant-badge-red">{complain_type}</span>
                                        <span style={{ marginLeft: '15px', fontSize: '13px' }} className="ant-badge-red">{complain_reason}</span>
                                        <span style={{ marginLeft: '15px', fontSize: '13px' }} className="ant-badge-black">{type}</span>
                                    </p>
                                    <p>
                                        {item.content}
                                    </p>
                                </div>
                            }
                            datetime={
                                <Tooltip>
                                    <div>{moment(item.published_at).format('DD-MM-YYYY HH:mm:ss')}</div>
                                </Tooltip>
                            }
                        >
                        </Comment>
                    })
                }
            </Comment>
        )
    }
}

export default CSKHComment;