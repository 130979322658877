import React, { Component } from 'react';

import { Row, Col, Card, message, Table, Button, Tooltip, Popconfirm } from 'antd';
import {
  EditOutlined,
  FileAddOutlined,
  DeleteOutlined
} from '@ant-design/icons';
import Utils from 'utils';
import BenefitRatesService from 'services/BenefitRatesService';
import TelcoService from 'services/TelcoService';
import ServiceService from 'services/ServiceService';
import PartnerService from 'services/PartnerService';
import BenefitRatesModalForm from './benefit-rates-form';
import moment from 'moment';
import BenefitRatesFormFilter from './benefit-rates-form-filter';


export class BenefitRatesManagement extends Component {
  state = {
    partners: [],
    partnersLoading: false,
    services: [],
    servicesLoading: false,
    telcos: [],
    telcosLoading: false,

    loading: false,
    benefitRates: [],

    visible: false,
    confirmLoading: false,
    initialValues: {},
    isEditing: false,
    pagination: {
      current: 1,
      pageSize: 10,
    },
    filters: {}
  };

  columns = [
    {
      title: 'STT',
      dataIndex: 'id',
      key: 'id',
      render: (text, record, index) => {
        const { current, pageSize } = this.state.pagination;

        return ((current - 1) * pageSize) + index + 1
      },
      width: 50
    },
    {
      title: 'Tỉ lệ',
      dataIndex: 'rate',
      render: (text, record, index) => `${text}%`
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
    },
    {
      title: 'Ngày bắt đầu',
      dataIndex: 'start_date',
      render: (text, record, index) => moment(text).format('DD/MM/YYYY'),
    },
    {
      title: 'Loại',
      dataIndex: 'type',
      render: (text, record, index) => {
        if (record.type === 'telco') {
            return 'Nhà mạng'
        } else {
          return 'Đối tác'
        }
      },
    },
    {
      title: 'Nhà mạng',
      dataIndex: ['telco_id', 'name'],
    },
    {
      title: 'Đối tác',
      dataIndex: ['partner_id', 'name'],
    },
    {
      title: 'Dịch vụ',
      dataIndex: ['service_id', 'name'],
    },
    {
      title: 'Ngưỡng trên',
      dataIndex: 'upper_threshold',
    },
    {
      title: 'Ngưỡng dưới',
      dataIndex: 'lower_threshold',
    },
    {
      title: 'Cập nhật',
      dataIndex: 'update',
      render: (_, record) => (
        <>
          <Tooltip title="Sửa">
            <Button icon={<EditOutlined />} size="small" onClick={() => this.handleEdit(record)} />
          </Tooltip>
        </>
      )
    },
    {
      title: 'Xóa',
      dataIndex: 'delete',
      key: 'delete',
      render: (_, record) => (
        <>
          <Popconfirm title="Bạn muốn xóa gói cước này không?" onConfirm={() => this.handleDelete(record.id)}>
            <Tooltip title="Xóa">
              <Button danger icon={<DeleteOutlined />} size="small" />
            </Tooltip>
          </Popconfirm>
        </>
      )
    },
  ];

  constructor(props) {
    super(props);
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadData();
    this.loadServices();
    this.loadTelcos();
    this.loadPartners();
    this.getTotalCount();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  loadData = async (params) => {
    this.setState({
      loading: true
    })
    const { filters, pagination } = this.state;
    const _pagination = {
      ...pagination,
      ...params
    };
    const __pagination = Utils.getPaginationParams(_pagination);
    const __params = {
      ...filters,
      ...__pagination
    };
    const benefitRates = await BenefitRatesService.read(__params);
    this._isMounted && this.setState({
      loading: false,
      benefitRates,
      pagination: _pagination
    })
  }

  loadTelcos = async () => {
    this.setState({
      telcosLoading: true
    })

    const telcos = await TelcoService.read();
    this._isMounted && this.setState({
      telcos,
      telcosLoading: false
    })
  }

  loadServices = async () => {
    this.setState({
      servicesLoading: true
    })

    const services = await ServiceService.read();
    this._isMounted && this.setState({
      services,
      servicesLoading: false
    })
  }

  loadPartners = async () => {
    this.setState({
      partnersLoading: true
    })

    const partners = await PartnerService.read();
    this._isMounted && this.setState({
      partners,
      partnersLoading: false
    })
  }

  getTotalCount = async () => {
    const { filters } = this.state;

    const total = await BenefitRatesService.count(filters);
    const { pagination } = this.state;
    this._isMounted && this.setState({
      pagination: {
        ...pagination,
        total
      }
    })
  }

  handleAdd = () => {
    this.setState({
      initialValues: null,
      isEditing: false,
      visible: true
    });
  };

  handleCreate = async (item) => {
    try {
      const param = this.handleParam(item);

      await BenefitRatesService.create(param);
      message.success({ content: `Thêm mới thành công`, duration: 3 });
      await this.loadData();

      this.hideModal();
    } catch (e) {
      console.log(e);
      this.hideModal();
    }
  }

  handleParam = (item) => {
    const param = {
      ...item,
      start_date: moment(item.start_date).toDate(),
      service_id: item.service_id.id
    };

    if (item.type === 'telco' && item.telco_id) {
      param.telco_id = item.telco_id.id;
    } else {
      delete param['telco_id'];
    }

    if (item.type === 'partner' && item.partner_id) {
      param.partner_id = item.partner_id.id;
    } else {
      delete param['partner_id'];
    }

    return param;
  }

  handleEdit = (item) => {
    this.setState({
      initialValues: {
        ...item,
        start_date: moment(item.start_date)
      },
      isEditing: true,
      visible: true
    });
  }

  handleUpdate = async (data) => {
    try {
      const { benefitRates } = this.state;
      const newData = await BenefitRatesService.update(this.handleParam(data));
      message.success({ content: `Tỉ lệ phân chia đã được cập nhật`, duration: 2 });

      const foundIndex = benefitRates.findIndex(p => p.id === data.id);
      if (foundIndex !== -1) {
        benefitRates[foundIndex] = {
          ...benefitRates[foundIndex],
          ...newData
        };
      }
      this.setState({
        benefitRates: [...benefitRates],
        visible: false,
        isEditing: false,
        confirmLoading: false
      });

    } catch (e) {
      console.log(e);
      this.hideModal();
    }
  }

  handleDelete = async (id) => {
    try {
      await BenefitRatesService.delete(id);
      message.success({ content: `Xóa thành công`, duration: 2 });
      await this.loadData();
    } catch (e) {
      console.log(e);
      this.hideModal();
    }
  }

  hideModal = () => {
    this.setState({
      visible: false,
      confirmLoading: false
    });
  }

  handleTableChange = (pagination) => {
    this.loadData(pagination);
  }

  handleFilter = (filters) => {
    this.setState({
      filters,
      pagination: {
        current: 1,
        pageSize: 10,
      },
    }, () => {
      this.loadData();
    });
  }

  render() {
    const { benefitRates, loading, visible, confirmLoading, initialValues, isEditing, telcos, services, partners, pagination } = this.state;
    const onCreate = isEditing ? this.handleUpdate : this.handleCreate;
    return (
      <>
        <BenefitRatesFormFilter onHandleFilter={this.handleFilter} />
        <Row>
          <Col span={24}>
            <Card title="Danh sách tỉ lệ phân chia">
              <Button type="primary" style={{ marginBottom: 16, borderRadius: '5px', float: 'right' }}
                icon={<FileAddOutlined />} onClick={this.handleAdd}>
                Thêm mới gói
              </Button>

              <BenefitRatesModalForm
                confirmLoading={confirmLoading}
                visible={visible}
                isEditing={isEditing}
                initialValues={initialValues}
                onCreate={onCreate}
                onCancel={this.hideModal}
                telcos={telcos}
                services={services}
                partners={partners}
              />
              <Table
                columns={this.columns}
                dataSource={benefitRates}
                loading={loading}
                rowKey={record => record.id}
                pagination={pagination}
                onChange={this.handleTableChange}
              />
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

export default BenefitRatesManagement;
