import React from "react";
import { Card, Col, Row, Spin } from "antd";
import {
    ArrowDownOutlined,
    DollarOutlined,
    ArrowUpOutlined,
    UserOutlined,
    ShoppingCartOutlined,
    AlertOutlined
} from '@ant-design/icons';
import NumberFormat from 'react-number-format';

class StatisticDashboard extends React.Component {
    render() {
        const { statistic, loading } = this.props;
        const badge = (ratio) => {
            if (ratio < 0) {
                return 'ant-badge-red';
            } else if (ratio > 0) {
                return 'ant-badge-green';
            } else {
                return 'ant-badge-red';
            }
        }

        const arrowType = (ratio) => {
            if (ratio < 0) {
                return <ArrowDownOutlined />;
            } else if (ratio > 0) {
                return <ArrowUpOutlined />;
            } else {
                return '';
            }
        }

        return (
            <>
                {
                    loading ? <Spin /> : (
                        <Row gutter={[24, 24]}>
                            <Col md={6} sm={12} xs={24}>
                                <Card loading={loading}>
                                    <div className="media ant-align-items-center">
                                        <div className="ant-avatar ant-avatar-icon ant-avatar-lg ant-avatar-purple">
                                            <DollarOutlined />
                                        </div>
                                        <div className="m-l-15">
                                            <h2 className="m-b-0"><NumberFormat value={statistic.lifetime_value_total ? statistic.lifetime_value_total : 0} displayType={'text'} thousandSeparator={true} /></h2>
                                            <span className={'ant-badge ant-badge-pill font-size-12 ' + badge(statistic.lifetime_value_ratio)}>
                                                {arrowType(statistic.lifetime_value_ratio)}
                                                <span className="ant-font-weight-semibold m-l-5">
                                                    <NumberFormat value={statistic.lifetime_value_ratio} displayType={'text'} thousandSeparator={true} decimalScale={2} suffix={'%'} />
                                                </span>
                                            </span>
                                            <p className="m-b-0 text-muted">Doanh thu</p>
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                            <Col md={6} sm={12} xs={24}>
                                <Card>
                                    <div className="media ant-align-items-center">
                                        <div className="ant-avatar ant-avatar-icon ant-avatar-lg ant-avatar-blue">
                                            <UserOutlined />
                                        </div>
                                        <div className="m-l-15">
                                            <h2 className="m-b-0">
                                                <NumberFormat value={statistic.user_active_total ? statistic.user_active_total : 0} displayType={'text'} thousandSeparator={true} decimalScale={2} />
                                            </h2>
                                            <span className="ant-badge ant-badge-pill font-size-12">

                                            </span>
                                            <p className="m-b-0 text-muted">User Active</p>
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                            <Col md={6} sm={12} xs={24}>
                                <Card>
                                    <div className="media ant-align-items-center">
                                        <div className="ant-avatar ant-avatar-icon ant-avatar-lg ant-avatar-cyan">
                                            <ShoppingCartOutlined />
                                        </div>
                                        <div className="m-l-15">
                                            <h2 className="m-b-0">
                                                <NumberFormat value={statistic.new_register_total ? statistic.new_register_total : 0} displayType={'text'} thousandSeparator={true} />
                                            </h2>
                                            <span className={'ant-badge ant-badge-pill font-size-12 ' + badge(statistic.new_register_ratio)}>
                                                {arrowType(statistic.new_register_ratio)}
                                                <span className="ant-font-weight-semibold m-l-5">{statistic.new_register_ratio !== undefined ? `${statistic.new_register_ratio}%` : ''}</span>
                                            </span>
                                            <p className="m-b-0 text-muted">Đăng ký mới</p>
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                            <Col md={6} sm={12} xs={24}>
                                <Card>
                                    <div className="media ant-align-items-center">
                                        <div className="ant-avatar ant-avatar-icon ant-avatar-lg ant-avatar-gold">
                                            <AlertOutlined />
                                        </div>
                                        <div className="m-l-15">
                                            <h2 className="m-b-0">
                                                <NumberFormat value={statistic.unregister_total ? statistic.unregister_total : 0} displayType={'text'} thousandSeparator={true} />
                                            </h2>
                                            <span className={'ant-badge ant-badge-pill font-size-12 ' + badge(statistic.unregister_ratio)}>
                                                {arrowType(statistic.unregister_ratio)}
                                                <span className="ant-font-weight-semibold m-l-5">{statistic.unregister_ratio !== undefined ? `${statistic.unregister_ratio}%` : ''}</span>
                                            </span>
                                            <p className="m-b-0 text-muted">Số lượng hủy</p>
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    )
                }
            </>
        );
    }
}

export default StatisticDashboard;