import React, { useState } from 'react'
import useScript from 'hooks/useScript';
import { Button } from 'antd';
import { MessageOutlined } from '@ant-design/icons';
import { Helmet } from 'react-helmet';

const GiveFeedbackButton = props => {
  const [loaded, setLoaded] = useState(false);

  const url = 'https://pm.dbiz.vn/s/5a780b11729ef869fdda6bc746161a86-T/hqrnrg/816000/dgcv47/4351bdce5ee9d680205493d1352f62a7/_/download/contextbatch/js/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/batch.js?locale=en-US&collectorId=bbc113b1'

  useScript({
    url,
    afterLoad: () => {
      setTimeout(() => {
        setLoaded(true)
      }, 50);
    }
  });

  const handelClick = (e) => {
    e.preventDefault();

    const triggerEl = document.getElementById('atlwdg-trigger');
    if (triggerEl) {
      triggerEl.click();
    }
  }
  return (
    <>
      <Helmet>
        <style type="text/css">{`
          .give-feedback-button {
            position: fixed;
            bottom: 65px;
            right: 20px;
            z-index: 1000;
          }
          .atlwdg-trigger {
            display: none !important;
          }
        `}
        </style>
      </Helmet>
      {
        loaded ? (<Button className="give-feedback-button" shape="circle" type="primary" icon={<MessageOutlined />} size="large" onClick={handelClick} />) : ''
      }
    </>

  )
}

export default GiveFeedbackButton;

