import React, { Component } from 'react';
import moment from "moment";
import { Table } from 'antd';


class ActiveLogs extends Component {
    state = {
        pagination: {
            current: 1,
            pageSize: 10,
        }
    };

    columns = [
        {
            title: 'STT',
            dataIndex: 'stt',
            key: 'stt',
            render: (text, record, index) => {
                const { current, pageSize } = this.state.pagination;

                return ((current - 1) * pageSize) + index + 1
            }
        },
        {
            title: 'Hoạt động',
            dataIndex: 'action',
        },
        {
            title: 'Nội dung',
            dataIndex: 'content',
        },
        {
            title: 'Dịch vụ',
            dataIndex: ['service', 'name']
        },
        {
            title: 'Gói cước',
            dataIndex: ['package', 'name']
        },
        {
            title: 'Thời gian',
            dataIndex: 'time',
            render: (_, record) => {
                const date = moment(record.time).utc(false);
                return date.format('DD-MM-YYYY HH:mm:ss');
            }
        },
    ];

    render() {
        return (
            <Table
                columns={this.columns}
                dataSource={this.props.activeLogs}
                loading={this.props.loading}
                rowKey={record => record.id}
            />
        );
    }
}

export default ActiveLogs;
