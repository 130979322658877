import { combineReducers } from 'redux';
import Auth from './Auth';
import Theme from './Theme';
import Statistic from './Statistic';
import User from './User';
import userGroup from "./GroupUser";

const reducers = combineReducers({
    theme: Theme,
    auth: Auth,
    statistic: Statistic,
    user: User,
    userGroup: userGroup
});

export default reducers;