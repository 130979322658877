import {
  LOAD_ALL_USER_GROUPS,
  LOAD_ALL_USER_GROUPS_SUCCESS,
  LOAD_DETAIL_GROUPS_SUCCESS,
  LOAD_LIST_USERS_BY_GROUP_SUCCESS,
  SELECT_GROUP

} from "redux/actions/GroupUser";


const initState = {
  allGroups: [],
  currentGroup: null,
  listUsers: [],
  loading: false,
  loaded: false
}

const userGroup = (state = initState, action) => {
  switch (action.type) {
    case LOAD_ALL_USER_GROUPS:
      return {
        ...state,
        loading: true,
        loaded: false
      }
    case LOAD_ALL_USER_GROUPS_SUCCESS:
      return {
        ...state,
        allGroups: action.groups,
        loading: false
      }
    case LOAD_DETAIL_GROUPS_SUCCESS:
      const { allGroups } = state;
      const foundIndex = allGroups.findIndex(g => g.id === action.groupID);
      if (foundIndex > -1) {
        allGroups[foundIndex] = {
          ...allGroups[foundIndex],
          ...action.groups
        };
      }
      return {
        ...state,
        allGroups,
        loaded: true
      }
    case LOAD_LIST_USERS_BY_GROUP_SUCCESS:
      return {
        ...state,
        listUsers: action.users
      }
    case SELECT_GROUP:
      return {
        ...state,
        currentGroup: action.group,
        listUsers: []
      }
      
    default:
      return state;
  }
}

export default userGroup