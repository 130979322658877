import { all, takeEvery, put, fork, call } from 'redux-saga/effects';

import toolsService from 'services/ToolsService';
import { LOAD_NAVIGATION_CONFIG } from 'redux/constants/Theme';
import { loadNavigationConfigError, loadNavigationConfigSuccess } from 'redux/actions/Theme';

export function* loadNavigationConfig() {
	yield takeEvery(LOAD_NAVIGATION_CONFIG, function* () {
		try {
			const tools = yield call(toolsService.read);
			if (tools) {
				yield put(loadNavigationConfigSuccess(tools));
			}

		} catch (err) {
			yield put(loadNavigationConfigError(err));
			console.log(err)
		}
	});
}

export default function* rootSaga() {
	yield all([
		fork(loadNavigationConfig)
	]);
}
