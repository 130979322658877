import React, { Component, useEffect } from 'react';
import {
    Row,
    Col,
    Card,
    Table,
    Button,
    Tooltip,
    Popconfirm,
    Form,
    Input,
    Modal,
    message
} from 'antd';
import {
    EditOutlined,
    FileAddOutlined,
    DeleteOutlined
} from '@ant-design/icons';
import ServiceService from "services/ServiceService";
import moment from 'moment';
import PartnerService from "services/PartnerService";
import TelcoService from "services/TelcoService";
import Utils from "utils";

const { TextArea } = Input;

const PartnerPopup = ({
    visible,
    onCreate,
    onCancel,
    permissionsData = null,
    initialValues = null,
    confirmLoading = false,
    isEditing = false,
    services = [],
    telcos = []
}) => {
    const [form] = Form.useForm();
    useEffect(() => {
        if (initialValues) {
            form.setFieldsValue(initialValues);
        } else {
            form.resetFields();
        }
    }, [form, initialValues]);

    const okText = isEditing ? 'Sửa' : 'Thêm';
    const title = isEditing ? 'Sửa đối tác' : 'Thêm mới đối tác';
    return (
        <Modal confirmLoading={confirmLoading}
            visible={visible}
            title={title}
            okText={okText}
            cancelText="Hủy"
            onCancel={onCancel}
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                        onCreate({
                            ...initialValues,
                            ...values
                        });
                    })
                    .catch((info) => {
                        console.log(info);
                    });
            }}>
            <Form
                form={form}
                layout="vertical"
                name="serviceForm"
                initialValues={initialValues}
                permissionsData={permissionsData}
            >
                <Form.Item
                    label="Tên đối tác"
                    name="name"
                    rules={[{ required: true, message: 'Tên đối tác không được để trống' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Mô tả đối tác"
                    name="description"
                    rules={[{
                        required: true,
                        message: 'Mô tả đối tác không được để trống'
                    }]}
                >
                    <TextArea />
                </Form.Item>
                <Form.Item
                    label="Tên đăng nhập"
                    name="username"
                    rules={[{ required: true, message: 'Tên đăng nhập không được để trống' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ required: true, message: 'Email không được để trống' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Họ"
                    name="first_name"
                    rules={[{ required: true, message: 'First name không được để trống' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Tên"
                    name="last_name"
                    rules={[{ required: true, message: 'Last name không được để trống' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Số điện thoại"
                    name="phone"
                    rules={[{ required: true, message: 'Số điện thoại không được để trống' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Danh sách quyền"
                    name="permission">
                    {console.log(permissionsData)}
                </Form.Item>
            </Form>
        </Modal>
    )
}

export class Partner extends Component {
    state = {
        partners: [],
        telcos: [],
        services: [],
        loading: false,
        visible: false,
        confirmLoading: false,
        initialValues: {},
        permissionsData: {
            "data": {
                "collection_permissions": {
                    "application": {
                        "controllers": {
                            "application-nodes": {
                                "count": {
                                    "enabled": false,
                                    "policy": ""
                                },
                                "create": {
                                    "enabled": false,
                                    "policy": ""
                                },
                                "delete": {
                                    "enabled": false,
                                    "policy": ""
                                },
                                "find": {
                                    "enabled": false,
                                    "policy": ""
                                },
                                "findone": {
                                    "enabled": false,
                                    "policy": ""
                                },
                                "update": {
                                    "enabled": false,
                                    "policy": ""
                                }
                            }
                        },
                    }
                }
            }
        },
        isEditing: false,
        title: '',
        pagination: {
            current: 1,
            pageSize: 10,
        },
        filters: {}
    };
    columns = [
        {
            title: 'STT',
            dataIndex: 'id',
            key: 'id',
            render: (text, record, index) => {
                const { current, pageSize } = this.state.pagination;

                return ((current - 1) * pageSize) + index + 1
            },
            width: 100
        },
        {
            title: 'Tên đối tác',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Mô tả',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Ngày tạo',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: 200,
            render: (createdAt) => moment(createdAt).format("YYYY-MM-DD")
        },
        {
            title: 'Cập nhật',
            dataIndex: 'update',
            key: 'update',
            render: (_, record) => (
                <>
                    <Tooltip title="Sửa">
                        <Button icon={<EditOutlined />} size="small" onClick={() => this.handleEdit(record)} />
                    </Tooltip>
                </>
            )
        },
        {
            title: 'Xóa',
            dataIndex: 'delete',
            key: 'delete',
            render: (_, record) => (
                <>
                    <Popconfirm onConfirm={() => this.handleDelete(record)}
                        title="Bạn muốn xóa cú pháp này phải không?">
                        <Tooltip title="Xóa">
                            <Button danger icon={<DeleteOutlined />} size="small" />
                        </Tooltip>
                    </Popconfirm>
                </>
            )
        },
    ];

    getTotalCount = async () => {
        const total = await PartnerService.count();
        const { pagination } = this.state;
        this.setState({
            pagination: {
                ...pagination,
                total
            }
        })
    }

    constructor(props) {
        super(props);

        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadData();
        this.getTotalCount();
        this.loadServices();
        this.loadTelcos();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    loadServices = async () => {
        const services = await ServiceService.read();
        this._isMounted && this.setState({
            services
        })
    }

    loadTelcos = async () => {
        const telcos = await TelcoService.read();
        this._isMounted && this.setState({
            telcos
        })
    }
    loadData = async (params) => {
        this.setState({
            loading: true
        })
        const { filters } = this.state;
        const __pagination = Utils.getPaginationParams(params);
        const __params = Object.assign(filters, __pagination);
        const partners = await PartnerService.read(__params);
        const { pagination } = this.state;
        this._isMounted && this.setState({
            loading: false,
            partners,
            pagination: {
                ...pagination,
                ...params
            }
        })
    }

    handleAdd = () => {
        this.setState({
            initialValues: null,
            isEditing: false,
            visible: true,
            title: 'Thêm đối tác'
        })
    }
    handleCreate = async (partner) => {
        try {
            await PartnerService.create(partner);
            await this.loadData();
            this.hideModal();
            message.success({ content: 'Thêm mới thành công', duration: 2 });
        } catch (error) {
            this.hideModal();
            message.error({ content: 'Thêm mới không thành công', duration: 2 })
        }
    }
    handleEdit = (partner) => {
        this.setState({
            initialValues: partner,
            isEditing: true,
            visible: true,
            title: 'Sửa cú pháp'
        })
    }
    handleUpdate = async (partner) => {
        try {
            await PartnerService.update(partner);
            await this.loadData();
            // const foundIndex = partners.findIndex(p => p.id === partner.id);
            // if(foundIndex !== -1) {
            //     partners[foundIndex] = {
            //         ...partners[foundIndex],
            //         ...partner
            //     }
            //     console.log(partners);
            //     this.setState({
            //         partners: [...partners],
            //         visible: false,
            //         isEditing: false,
            //     })
            // }
            this.hideModal();
            message.success({ content: 'Sửa thành công', duration: 2 });
        } catch (error) {
            message.error({ content: 'Sửa không thành công', duration: 2 })
            this.hideModal();
        }
    }
    handleDelete = async (partner) => {
        try {
            await PartnerService.delete(partner.id);
            const { partners } = this.state;
            this._isMounted && this.setState({
                partners: partners.filter(s => s.id !== partner.id)
            })
            message.success({ content: 'Xóa thành công', duration: 2 });
        } catch (error) {
            message.error({ content: 'Xóa không thành công', duration: 2 })
        }
    }
    hideModal = () => {
        this.setState({ visible: false, confirmLoading: false })
    }
    onCancel = () => {
        this.hideModal();
    }
    onFinish = async (values) => {
        let filters = {};
        if (values.partner !== undefined) {
            filters['name_contains'] = values.partner;
        }
        this.setState({
            filters,
            pagination: {
                current: 1,
                pageSize: 10,
            },
        })
        await this.getTotalCount();
        this.loadData();
    }
    handleTableChange = (pagination) => {
        this.loadData(pagination)
    }

    render() {
        const {
            visible,
            isEditing,
            confirmLoading,
            initialValues,
            loading,
            partners,
            services,
            telcos,
            pagination,
            permissionsData
        } = this.state;
        const onCreate = isEditing ? this.handleUpdate : this.handleCreate;
        return (
            <>
                <PartnerPopup
                    confirmLoading={confirmLoading}
                    visible={visible}
                    isEditing={isEditing}
                    initialValues={initialValues}
                    onCancel={this.onCancel}
                    onCreate={onCreate}
                    telcos={telcos}
                    permissionsData={permissionsData}
                    services={services} />
                <Card>
                    <Form layout="vertical" onFinish={this.onFinish}>
                        <Row style={{ marginBottom: '15px' }} gutter={[24, 24]}>
                            <Col md={6} sm={24} xs={24}>
                                <Form.Item label="Tên đối tác" name="partner">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col md={6} sm={24} xs={24}>
                                <Form.Item style={{ float: 'left', marginLeft: '5px', marginTop: '20px' }}>
                                    <Button type="primary" htmlType="submit" style={{ float: 'right' }}>Lọc</Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Card>
                <Row>
                    <Col xs={24}>
                        <Card title="Danh sách đối tác">
                            <Button onClick={() => this.handleAdd()} type="primary"
                                style={{ marginBottom: 16, borderRadius: '5px', float: 'right' }}
                                icon={<FileAddOutlined />}>
                                Thêm mới đối tác
                            </Button>
                            <Table pagination={pagination} onChange={this.handleTableChange} columns={this.columns}
                                dataSource={partners} loading={loading} rowKey={record => record.id} />
                        </Card>
                    </Col>
                </Row>
            </>
        );
    }
}

export default Partner