import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
	GET_STATISTIC_INFO
} from '../constants/Statistic';
import {
	updateStatisticInfo} from "../actions/Statistic";
import StatisticServices from 'services/StatisticService';

export function* getInfo() {
	yield takeEvery(GET_STATISTIC_INFO, function* ({ payload }) {
		try {
			const info = yield call(StatisticServices.getStatisticInfo, payload);
			if (info) {
				yield put(updateStatisticInfo(info));
			}

			// if (user.message) {
			// 	yield put(showAuthMessage(user.message));
			// } else {
			// 	localStorage.setItem(AUTH_TOKEN, user.access_token);
			// 	localStorage.setItem('refresh_token', user.refresh_token)
			// 	yield put(authenticated(user.access_token));
			// }
		} catch (err) {
			// yield put(showAuthMessage(err));
			console.log(err)
		}
	});
}

export default function* rootSaga() {
	yield all([
		fork(getInfo)
	]);
}
