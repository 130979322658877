import fetch from 'interceptors/FetchInterceptor'


const StatisticServices = {}

StatisticServices.getStatisticInfo = function (data) {
    return fetch({
        url: '/data/sample.json',
        method: 'get',
        data: data
    })
}

export default StatisticServices