import React, { Component } from 'react'
import { Button, Card, Col, DatePicker, Form, Input, Row, Select, Table } from "antd";
import { DownloadOutlined, PhoneOutlined, TagOutlined } from "@ant-design/icons";

import telcosService from "services/TelcoService";
import partnerService from "services/PartnerService";
import packageService from "services/PackageService";
import paymentService from "services/PaymentService";
import serviceService from "services/ServiceService";
import moment from "moment";
import Utils from "utils";
import { PATTERN_PHONE } from "configs/AppConfig";
import PaymentService from "services/PaymentService";
import PackageService from "../../../services/PackageService";

const { RangePicker } = DatePicker;

export class PaymentList extends Component {
    state = {
        payments: [],
        packages: [],
        telcos: [],
        services: [],
        partners: [],
        loading: false,
        pagination: {
            current: 1,
            pageSize: 10,
        },
        filters: {},
        filtersPackage: {}
    };

    columns = [
        {
            title: 'STT',
            dataIndex: 'id',
            render: (text, record, index) => {
                const { current, pageSize } = this.state.pagination;

                return ((current - 1) * pageSize) + index + 1
            },
            width: 50
        },
        {
            title: 'Số điện thoại',
            dataIndex: 'msisdn'
        },
        {
            title: 'Số tiền',
            dataIndex: 'amount',
            width: 100
        },
        {
            title: 'Dịch vụ',
            dataIndex: ['service_id', 'name'],
            width: 100
        },
        {
            title: 'Gói cước',
            dataIndex: ['package_id', 'name'],
            width: 150,
            render: (_, record) => {
                if (record?.package_id?.code) {
                    return record.package_id.code;
                } else if (record?.package_id?.name) {
                    return record.package_id.name;
                } else
                    return ''
            }
        },
        {
            title: 'Cú pháp',
            dataIndex: ['command_code_id', 'command_code'],
            width: 100
        },
        {
            title: 'Đối tác',
            dataIndex: ['partner_id', 'name'],
            width: 100
        },
        {
            title: 'Nhà mạng',
            dataIndex: ['telco_id', 'name'],
            width: 150
        },
        {
            title: 'Loại',
            dataIndex: 'type',
            render: (_, record) => {
                if (record.type === 1) {
                    return 'Đăng ký';
                } else if (record.type === 2) {
                    return 'Hủy';
                } else if (record.type === 3) {
                    return 'Gia hạn';
                } else {
                    return 'Unknown';
                }
            }
        },
        {
            title: 'Trạng thái',
            dataIndex: 'charge_status',
            width: 150,
            render: (_, record) => {
                if (record.charge_status === 1) {
                    return 'Thành công';
                } else if (record.charge_status === 0) {
                    return 'Thất bại';
                } else {
                    return 'Unknown';
                }
            }
        },
        {
            title: 'Thời gian charge cước',
            dataIndex: 'submit_time',
            width: 150,
            render: (_, record) => {
                const date = moment.utc(record.submit_time);
                return date.format('DD-MM-YYYY HH:mm:ss');
            }
        }
    ];

    constructor(props) {
        super(props);

        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        this.getDefaultDate();
        this.loadData();
        this.getTotalCount();
        this.getPackages();
        this.getTelcos();
        this.getServices();
        this.getPartner();
    }

    getTotalCount = async () => {
        const { filters } = this.state;
        const total = await paymentService.count(filters);
        const { pagination } = this.state;
        this._isMounted && this.setState({
            pagination: {
                ...pagination,
                total
            }
        })
    }

    getDefaultDate = () => {
        const { filters } = this.state;
        let defaultDates = Utils.getDefaultDates();
        if (filters.submit_time_gte === undefined || filters.submit_time_lte === undefined) {
            filters['submit_time_gte'] = moment.utc(defaultDates[0]).format('YYYY-MM-DDT00:00:00.000Z');
            filters['submit_time_lte'] = moment.utc(defaultDates[1]).format('YYYY-MM-DDT23:59:59.000Z');
        }
        this.setState({
            filters
        })
    }

    loadData = async (params) => {
        this.setState({
            loading: true
        });
        const { filters } = this.state;
        const __pagination = Utils.getPaginationParams(params);
        const __params = Object.assign(filters, __pagination);
        const payments = await paymentService.read(__params);
        const { pagination } = this.state;
        this._isMounted && this.setState({
            loading: false,
            payments,
            filters,
            pagination: {
                ...pagination,
                ...params
            }
        });
        await this.getTotalCount();
    }

    getTelcos = async () => {
        const telcos = await telcosService.read();
        this._isMounted && this.setState({
            telcos
        });
    }

    getPartner = async () => {
        const partners = await partnerService.read();
        this._isMounted && this.setState({
            partners
        })
    }

    getPackages = async () => {
        const packages = await packageService.read();
        this._isMounted && this.setState({
            packages
        })
    }

    getServices = async () => {
        const services = await serviceService.read();
        this._isMounted && this.setState({
            services
        })
    }

    onFinish = async (values) => {
        this.setState({
            loading: true,
        });

        let filters = {};
        if (values.phone && values.phone !== '') {
            let _phone = values.phone;
            if ((/(^(09|03|07|08|05)+([0-9]{8}$))/).test(_phone)) {
                _phone = _phone.replace('0', '84')
            }
            filters['msisdn_eq'] = _phone;
        }

        if (values.amount && values.amount !== '') {
            filters['amount_eq'] = values.amount;
        }

        if (values.charge_status) {
            filters['charge_status_eq'] = values.charge_status;
        }

        if (values.time === undefined) {
            let defaultDates = Utils.getDefaultDates();
            if (filters.submit_time_gte === undefined || filters.submit_time_lte === undefined) {
                filters['submit_time_gte'] = moment.utc(defaultDates[0]).format('YYYY-MM-DDT00:00:00.000Z');
                filters['submit_time_lte'] = moment.utc(defaultDates[1]).format('YYYY-MM-DDT23:59:59.000Z');
            }
        }

        if (values.time) {
            let submitTimeFrom = new Date(values.time[0]);
            let submitTimeTo = new Date(values.time[1]);
            submitTimeFrom = moment.utc(submitTimeFrom).format('YYYY-MM-DDT00:00:00.000Z');
            submitTimeTo = moment.utc(submitTimeTo).format('YYYY-MM-DDT23:59:59.000Z');

            filters['submit_time_gte'] = submitTimeFrom;
            filters['submit_time_lte'] = submitTimeTo;
        }

        if (values.syntax) {
            filters['command_code_id.command_code_contains'] = values.syntax;
        }

        if (values.package) {
            filters['package_id_in'] = values.package;
        }

        if (values.service) {
            filters['service_id_in'] = values.service;
        }

        if (values.partner) {
            filters['partner_id_in'] = values.partner;
        }

        if (values.telco) {
            filters['telco_id_in'] = values.telco;
        }

        if (values.type) {
            filters['type_eq'] = values.type;
        }
        this.setState({
            filters,
            pagination: {
                current: 1,
                pageSize: 10,
            },
        })
        this.loadData();
    }

    handleTableChange = (pagination) => {
        this.loadData(pagination);
    }

    handleChange = async (values) => {
        const { filtersPackage } = this.state;
        if (values.telco_id !== undefined && values.telco_id !== null) {
            filtersPackage.telco_id_in = values.telco_id;
        }

        if (values.service_id !== undefined && values.service_id !== null) {
            filtersPackage.service_id_in = values.service_id;
        }

        const packages = await PackageService.read(filtersPackage);
        this._isMounted && this.setState({
            packages
        });
    }

    handleDownload = async () => {
        this.state.filters._limit = -1
        const url = await PaymentService.export(this.state.filters)
        if (url) {
            Utils.downloadBySaveFile(url, url.replace(/^.*[\\/]/, ''))
        }
    }

    render() {
        const { loading, packages, partners, payments, telcos, pagination, services } = this.state;
        return (
            <>
                <Card>
                    <Form layout="vertical" onFinish={this.onFinish}>
                        <Row gutter={[24, 24]}>
                            <Col md={6} sm={24} xs={24}>
                                <Form.Item name="phone" label="Số điện thoại"

                                    rules={[
                                        {
                                            pattern: PATTERN_PHONE,
                                            message: 'Không đúng định dạng số điện thoại',
                                        },
                                    ]}
                                >
                                    <Input size="default" placeholder="Nhập số điện thoại cần tra cứu"
                                        prefix={<PhoneOutlined />} />
                                </Form.Item>
                            </Col>
                            <Col md={6} sm={24} xs={24}>
                                <Form.Item name="syntax" label="Cú pháp">
                                    <Input size="default" placeholder="Nhập cú pháp cần tra cứu"
                                        prefix={<TagOutlined />} />
                                </Form.Item>
                            </Col>
                            <Col md={6} sm={24} xs={24}>
                                <Form.Item name="type" label="Loại giao dịch">
                                    <Select style={{ width: '100%' }}
                                            placeholder="Lựa chọn loại giao dịch"

                                    >
                                        <Select.Option key="111" value="1">Đăng ký</Select.Option>
                                        <Select.Option key="222" value="2">Hủy</Select.Option>
                                        <Select.Option key="333" value="3">Gia hạn</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col md={6} sm={24} xs={24}>
                                <Form.Item name="charge_status" label="Trạng thái">
                                    <Select style={{ width: '100%' }}
                                        placeholder="Lựa chọn trạng thái"

                                    >
                                        <Select.Option>Tất cả</Select.Option>
                                        <Select.Option key="111" value="1">Thành công</Select.Option>
                                        <Select.Option key="000" value="0">Thất bại</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col md={6} sm={24} xs={24}>
                                <Form.Item name="time" label="Thời gian nhận" initialValue={Utils.getDefaultDates()}
                                    style={{ width: '100%' }}>
                                    <RangePicker style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                            <Col md={6} sm={24} xs={24}>
                                <Form.Item name="telco" label="Nhà mạng">
                                    <Select style={{ width: '100%' }}
                                        placeholder="Lựa chọn nhà mạng"
                                        mode="tags"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        onChange={(value) => this.handleChange({ 'telco_id': value })}
                                    >
                                        {
                                            telcos.map((data) => <Select.Option key={data.id} value={data.id}>{data.name}</Select.Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col md={6} sm={24} xs={24}>
                                <Form.Item name="service" label="Dịch vụ">
                                    <Select style={{ width: '100%' }}
                                        placeholder="Lựa chọn dịch vụ"
                                        mode="tags"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        onChange={(value) => this.handleChange({ 'service_id': value })}
                                    >
                                        {
                                            services.map((data) => <Select.Option key={data.id} value={data.id}>{data.name}</Select.Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col md={6} sm={24} xs={24}>
                                <Form.Item name="package" label="Gói cước">
                                    <Select style={{ width: '100%' }}
                                        placeholder="Lựa chọn gói cước"
                                        mode="tags"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {
                                            packages.map((data) => <Select.Option key={data.id} value={data.id}>{data.code || data.name}</Select.Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col md={6} sm={24} xs={24} className="d-flex">
                                <Form.Item name="partner" label="Đối tác"
                                    style={{ width: '100%' }}>
                                    <Select style={{ width: '100%' }}
                                        placeholder="Lựa chọn đối tác"
                                        mode="tags"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {
                                            partners.map((data) => <Select.Option key={data.id} value={data.id}>{data.name}</Select.Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col md={6} sm={24} xs={24} className="d-flex">
                                <Form.Item name="amount" label="Số tiền"
                                    style={{ width: '65%', float: 'left' }}>
                                    <Input placeholder="Nhập số tiền chính xác" />
                                </Form.Item>
                                <Form.Item style={{ width: '30%', float: 'left', marginLeft: '10px' }}>
                                    <Button className="float-right" type="primary" htmlType="submit"
                                        style={{ width: '100%', marginTop: '20px', padding: '0px' }}>Lọc</Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Card>
                <Row gutter={[24, 24]}>
                    <Col md={24} sm={24} xs={24}>
                        <Card title="Danh sách lịch sử đăng ký/gia hạn">
                            <Button type="primary"
                                style={{ marginBottom: 16, borderRadius: '5px', float: 'right', marginLeft: '15px' }}
                                icon={<DownloadOutlined />}
                                onClick={this.handleDownload}>
                                Tải về
                            </Button>
                            <Table
                                columns={this.columns}
                                dataSource={payments}
                                loading={loading}
                                rowKey={record => record.id}
                                pagination={pagination}
                                onChange={this.handleTableChange}
                            />
                        </Card>
                    </Col>
                </Row>
            </>
        );
    }
}

// adding the oidc user in the props
export default PaymentList;
