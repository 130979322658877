import fetch from 'interceptors/FetchInterceptor';

const PARTNER_API_ENDPOINT = '/collections/partners';
class PartnerService {
    async create(partner){
        const response = await fetch({
            url: PARTNER_API_ENDPOINT,
            method: 'POST',
            data: partner,
        })
        return response;
    }
    async read(params){
        const response = await fetch({
            url: `${PARTNER_API_ENDPOINT}`,
            method: 'GET',
            params
        })
        return response;
    }
    async count(params){
        const response = await fetch({
            url: `${PARTNER_API_ENDPOINT}/count`,
            method: 'GET',
            params

        })
        return response;
    }
    async update(partner){
        const response = await fetch({
            url: `${PARTNER_API_ENDPOINT}/${partner.id}`,
            method: 'PUT',
            data: partner
        })
        return response;
    }
    async delete(id){
        const response = await fetch({
            url: `${PARTNER_API_ENDPOINT}/${id}`,
            method: 'DELETE',
        })
        return response;
    }
}
export default new PartnerService();