import React from 'react';

import {
  Row,
  Col,
  DatePicker,
  Button,
  Form,
  Select,
  Input,
} from 'antd';
import {
  PhoneOutlined
} from '@ant-design/icons';
import { PATTERN_PHONE } from "configs/AppConfig";
import Utils from "utils";
import {CALL_TYPE, COMPLAIN_REASONS, COMPLAIN_TYPES} from 'views/app-views/configs';
import moment from 'moment';

const { RangePicker } = DatePicker;


const FilterForm = ({ onHandleFilter, onChange, packages, partners, telcos, services }) => {
  const onFinish = values => {
    console.log(values)

    if (values.msisdn && values.msisdn !== '') {
      let _phone = values.msisdn;
      if ((/(^(09|03|07|08|05)+([0-9]{8}$))/).test(_phone)) {
        _phone = _phone.replace('0', '84')
      }
      values['msisdn_eq'] = _phone;
    } else {
      values['msisdn_eq'] = undefined;
    }

    values['msisdn'] = undefined
    values['call_time_gte'] = undefined;
    values['call_time_lte'] = undefined;
    if (values.call_time === undefined) {
      let defaultDates = Utils.getDefaultDates();
      if (values.call_time_gte === undefined || values.call_time_lte === undefined) {
        values['call_time_gte'] = moment.utc(defaultDates[0]).format('YYYY-MM-DDT00:00:00.000Z');
        values['call_time_lte'] = moment.utc(defaultDates[1]).format('YYYY-MM-DDT23:59:59.000Z');
      }
    } else if(values.call_time) {
      let callTimeFrom = new Date(values.call_time[0]);
      let callTimeTo = new Date(values.call_time[1]);
      callTimeFrom = moment.utc(callTimeFrom).format('YYYY-MM-DDT00:00:00.000Z');
      callTimeTo = moment.utc(callTimeTo).format('YYYY-MM-DDT23:59:59.000Z');

      values['call_time_gte'] = callTimeFrom;
      values['call_time_lte'] = callTimeTo;

      delete values['call_time']
    }

    values['call_time'] = undefined

    typeof onHandleFilter === 'function' && onHandleFilter(values)
  };

  const handleDataChange = (value) => {
    onChange(value)
  }

  return (
    <Form layout="vertical" onFinish={onFinish}>
      <Row gutter={[24, 24]}>
        <Col md={6} sm={24} xs={24}>
          <Form.Item name="msisdn"
            label="Số điện thoại"
            rules={[
              {
                pattern: PATTERN_PHONE,
                message: 'Không đúng định dạng số điện thoại',
              },
            ]}
          >
            <Input
              size="default"
              placeholder="Nhập số điện thoại cần tra cứu"
              prefix={<PhoneOutlined />}
            />
          </Form.Item>
        </Col>
        <Col md={6} sm={24} xs={24}>
          <Form.Item 
            name="call_time" 
            label="Thời gian gọi" 
            initialValue={Utils.getDefaultDates()}
            style={{ width: '100%', float: 'left', marginRight: '5px' }}>
            <RangePicker style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col md={6} sm={24} xs={24}>
          <Form.Item name="type_call_eq" label="Loại cuộc gọi"
                     style={{ width: '100%', float: 'left' }}>
            <Select style={{ width: '100%' }} placeholder="Chọn loại cuộc gọi"
            >
              {
                CALL_TYPE.map((s, i) => <Select.Option key={`type-call-${i}`} value={s.value}>{s.name}</Select.Option>)
              }
            </Select>
          </Form.Item>
        </Col>
        <Col md={6} sm={24} xs={24}>
          <Form.Item name="complain_type_eq" label="Vấn đề khiếu nại"
            style={{ width: '100%', float: 'left' }}>
            <Select style={{ width: '100%' }} placeholder="Lựa chọn vấn đề khiếu nại"
            >
              {
                COMPLAIN_TYPES.map((s, i) => <Select.Option key={`complain-type-${i}`} value={s.value}>{s.name}</Select.Option>)
              }
            </Select>
          </Form.Item>
        </Col>
        <Col md={6} sm={24} xs={24}>
          <Form.Item name="complain_reason_eq" label="Nguyên nhân khiếu nại"
            style={{ width: '100%', float: 'left' }}>
            <Select style={{ width: '100%' }} placeholder="Lựa chọn nguyên nhân khiếu nại"
            >
              {
                COMPLAIN_REASONS.map((s, i) => <Select.Option key={`complain-reason-${i}`} value={s.value}>{s.name}</Select.Option>)
              }
            </Select>
          </Form.Item>
        </Col>
        <Col md={6} sm={24} xs={24}>
          <Form.Item name="telco_id_in" label="Nhà mạng">
            <Select style={{ width: '100%' }}
                    mode="tags" onChange={(value) => handleDataChange({'telco_id': value})}
            >
              {
                telcos.map((p, index) => <Select.Option key={p.id} value={p.id}>{p.code || p.name}</Select.Option>)
              }
            </Select>
          </Form.Item>
        </Col>
        <Col md={6} sm={24} xs={24}>
          <Form.Item name="service_id_in" label="Dịch vụ">
            <Select style={{ width: '100%' }}
                    mode="tags" onChange={(value) => handleDataChange({'service_id': value})}
            >
              {
                services.map((p, index) => <Select.Option key={p.id} value={p.id}>{p.code || p.name}</Select.Option>)
              }
            </Select>
          </Form.Item>
        </Col>
        <Col md={6} sm={24} xs={24}>
          <Form.Item name="package_ids_in" label="Gói cước">
            <Select style={{ width: '100%' }}
              mode="tags"
            >
              {
                packages.map((p, index) => <Select.Option key={p.id} value={p.id}>{p.code || p.name}</Select.Option>)
              }
            </Select>
          </Form.Item>
        </Col>
        <Col md={6} sm={24} xs={24}>
          <Form.Item name="partner_ids_in" label="Đối tác">
            <Select style={{ width: '100%' }}
              mode="tags"
              filterOption={(input, option) =>
                option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {
                partners.map((p, index) => <Select.Option key={p.id} value={p.id}>{p.name}</Select.Option>)
              }
            </Select>
          </Form.Item>
        </Col>
        <Col md={6} sm={24} xs={24}>
          <Form.Item style={{ width: '30%', float: 'left' }}>
            <Button type="primary" htmlType="submit"
              style={{ width: '100%', marginTop: '20px', padding: '0px' }}>Lọc</Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}


export default FilterForm;
