import React from 'react'
import {
    Button,
    Card,
    Col,
    DatePicker,
    Form, notification,
    Row,
    Select,
    Table
} from "antd";
import {
    DownloadOutlined
} from '@ant-design/icons';
import ForControlHomeNetworkService from "services/ForControlHomeNetworkService";
import TelcoService from "services/TelcoService";
import ServiceService from "services/ServiceService";
import NumberFormat from 'react-number-format';
import 'moment/locale/vi';
import PackageService from "services/PackageService";
import PartnerService from "services/PartnerService";
import Utils from "utils";
import moment from "moment";

export class ForControlHomeNetwork extends React.Component {
    state = {
        services: [],
        packages: [],
        partners: [],
        telcos: [],
        contents: [],
        initialValues: {},
        isEditing: false,
        visible: false,
        loading: false,
        title: '',
        pagination: {
            current: 1,
            pageSize: 10,
        },
        filters: {
            month_eq: moment().format("MM"),
            year_eq: moment().format("YYYY")
        },
        filtersPackage: {}
    };

    columns = [
        // {
        //     title: 'STT',
        //     dataIndex: 'id',
        //     key: 'id',
        //     render: (text, record, index) => {
        //         const { current, pageSize } = this.state.pagination;

        //         return ((current - 1) * pageSize) + index + 1
        //     },
        //     // width: 50
        // },
        {
            title: 'Tháng',
            dataIndex: 'month',
            width: 150,
        },
        {
            title: 'Năm',
            dataIndex: 'year',
            width: 150,
        },
        {
            title: 'Đối tác',
            dataIndex: ['partner_id', 'name'],
            key: ['partner_id', 'id'],
            width: 150,
        },
        {
            title: 'Nhà mạng',
            dataIndex: ['telco_id', 'name'],
            key: ['telco_id', 'id'],
            width: 150,
        },
        {
            title: 'Đầu Số',
            dataIndex: 'tel_service',
            width: 150,
        },
        {
            title: 'Command code',
            dataIndex: ['command_code_id', 'command_code'],
            key: ['command_code_id', 'id'],
            width: 150,
        },
        {
            title: 'Gói Dịch Vụ',
            dataIndex: ['package_id', 'name'],
            key: ['package_id', 'id'],
            width: 150,
            render: (_, record) => {
                if (record?.package_id?.code) {
                    return record.package_id.code;
                } else if (record?.package_id?.name) {
                    return record.package_id.name;
                } else
                    return ''
            }
        },
        {
            title: 'Đơn Giá',
            dataIndex: 'amount',
            render: (item) => (
                <NumberFormat value={item} displayType={'text'} thousandSeparator={true} suffix={' đ'} />
            ),
            width: 150,
        },
        {
            title: 'Số Lượng Đăng Ký Thành Công',
            dataIndex: 'count_success',
            render: (item) => (
                <NumberFormat value={item} displayType={'text'} thousandSeparator={true} />
            ),
            width: 150,
        },
        {
            title: 'Số Lượng Gia Hạn Thành Công',
            dataIndex: 'count_renew',
            render: (item) => (
                <NumberFormat value={item} displayType={'text'} thousandSeparator={true} />
            ),
            width: 150,
        },
        {
            title: 'Telco Thu Khách Hàng',
            dataIndex: 'telco_customer',
            render: (item) => (
                <NumberFormat value={item} displayType={'text'} thousandSeparator={true} suffix={' đ'} />
            ),
            width: 150,

        },
        {
            title: 'Doanh Thu Phân Chia Telco Được Hưởng',
            dataIndex: 'benefit_telco',
            render: (_, record) => {
                let value = record.telco_customer - record.benefit_telco
                return <NumberFormat value={value} displayType={'text'} thousandSeparator={true} suffix={' đ'} />
            },
            width: 200,

        },
        {
            title: 'Doanh Thu Phân Chia Sau Telco',
            dataIndex: 'benefit_after_telco',
            render: (item) => (
                <NumberFormat value={item} displayType={'text'} thousandSeparator={true} suffix={' đ'} />
            ),
            width: 150,

        },

    ];

    constructor(props) {
        super(props);

        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadData().then(r => r);
        this.getTotalCount().then(r => r);
        this.loadTelco().then(r => r);
        this.loadService().then(r => r);
        this.loadPackage().then(r => r);
        this.loadPartner().then(r => r);
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getTotalCount = async () => {
        const { filters } = this.state;

        const total = await ForControlHomeNetworkService.count(filters);
        // const { pagination } = this.state;
        this._isMounted && this.setState({
            pagination: {
                // ...pagination,
                total
            }
        })
    }

    loadData = async (params) => {
        this.setState({
            loading: true
        })
        const { filters } = this.state;
        const __pagination = Utils.getPaginationParams(params);
        const __params = Object.assign(filters, __pagination);
        const contents = await ForControlHomeNetworkService.read(__params);
        // const { pagination } = this.state;
        this._isMounted && this.setState({
            loading: false,
            contents,
            // pagination: {
            //     ...pagination,
            //     ...params
            // }
        })
    }
    loadTelco = async () => {
        const telcos = await TelcoService.read();
        this._isMounted && this.setState({
            telcos
        })
    }
    loadPackage = async () => {
        const packages = await PackageService.read();
        this._isMounted && this.setState({
            packages: packages
        })
    }
    loadService = async () => {
        const services = await ServiceService.read();
        this._isMounted && this.setState({
            services
        })
    }
    loadPartner = async () => {
        const partners = await PartnerService.read();
        this._isMounted && this.setState({
            partners
        })
    }

    onFilter = async (values) => {

        let filters = {};
        if (values.time !== undefined && values.time !== null) {
            filters["month_eq"] = String(values.time._d.getMonth() + 1).padStart(2, '0');
            filters["year_eq"] = values.time._d.getFullYear();
        } else {
            filters["month_eq"] = moment().format("MM");
            filters["year_eq"] = moment().format("YYYY");
        }
        if (values.partner_id !== undefined && values.partner_id !== null) {

            filters["partner_id_eq"] = values.partner_id;

        }
        if (values.telco_id !== undefined && values.telco_id !== null) {

            filters["telco_id_eq"] = values.telco_id;
            filters["telco_id"] = values.telco_id;

        }
        if (values.package_id !== undefined && values.package_id !== null) {

            filters["package_id_in"] = values.package_id;

        }
        if (values.service_id !== undefined && values.service_id !== null) {

            filters["service_id_eq"] = values.service_id;
            filters["service_id"] = values.service_id;

        }

        this.setState({
            filters,
            pagination: {
                current: 1,
                pageSize: 10,
            },
        })
        await this.getTotalCount();
        this.loadData();

    };

    onFilterFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    ExportExcelSubmit = async () => {
        this.state.filters._limit = -1
        console.log(this.state.filters)
        const result = await ForControlHomeNetworkService.exportTelco(this.state.filters)

        if (result?.msg && result.msg) {
            notification.error({
                message: result.msg
            })
            return
        }

        if (result?.url && result.url) {
            let url = result.url
            Utils.downloadBySaveFile(url, url.replace(/^.*[\\/]/, ''))
        }
    };

    onReset = () => {
        this.form.resetFields();
    };

    handleTableChange = () => {
        this.loadData();
    }

    handleChange = async (values) => {
        const { filtersPackage } = this.state;
        if (values.telco_id !== undefined && values.telco_id !== null) {
            filtersPackage.telco_id_in = values.telco_id;
        }

        if (values.service_id !== undefined && values.service_id !== null) {
            filtersPackage.service_id_in = values.service_id;
        }

        const packages = await PackageService.read(filtersPackage);
        this._isMounted && this.setState({
            packages
        });
    }

    summary = (data) => {
        const { contents } = this.state
        let register_success = 0;
        let renew_success = 0;
        let revenue = 0;
        let selfRevenue = 0;
        let revenuePartner = 0;
        contents.forEach(({ telco_customer, count_renew, count_success, benefit_telco, benefit_after_telco }) => {
            register_success += count_success;
            renew_success += count_renew;
            revenue += parseFloat(telco_customer);
            selfRevenue += parseFloat(telco_customer - benefit_telco);
            revenuePartner += parseFloat(benefit_after_telco);
        })

        return (
            <Table.Summary.Row>
              <Table.Summary.Cell colSpan={8} align="left">Tổng cộng</Table.Summary.Cell>
              <Table.Summary.Cell>
                <NumberFormat value={register_success} displayType="text" thousandSeparator={true} />
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                <NumberFormat value={renew_success} displayType="text" thousandSeparator={true} />
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                <NumberFormat value={revenue} displayType="text" thousandSeparator={true} />
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                <NumberFormat value={selfRevenue} displayType="text" thousandSeparator={true} />
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                <NumberFormat value={revenuePartner} displayType="text" thousandSeparator={true} />
              </Table.Summary.Cell>
            </Table.Summary.Row>
          )
    }
    

    render() {
        const { pagination, telcos, contents, loading, packages, services } = this.state;
        return (
            <>
                <Card title="Bộ lọc">
                    <Form
                        layout="vertical"
                        onFinish={this.onFilter}
                        onFinishFailed={this.onFilterFailed}
                        initialValues={{
                            time: moment(moment(), "MM/YYYY")
                        }}
                    >
                        <Row style={{ marginBottom: '15px' }} gutter={[24, 24]}>
                            <Col md={8} sm={24} xs={24}>
                                <Form.Item name="time" label="Thời gian lọc" rules={[{ required: true, message: 'Vui lòng chọn thời gian' }]}>
                                    <DatePicker.MonthPicker format="MM/YYYY" style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>

                            <Col md={8} sm={24} xs={24}>
                                <Form.Item label="Nhà mạng" name="telco_id">
                                    <Select style={{ width: '100%' }}
                                            optionFilterProp="children"
                                            placeholder="Lựa chọn nhà mạng"
                                            filterOption={(input, option) =>
                                                option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            onChange={(value) => this.handleChange({ 'telco_id': value })}
                                    >

                                        {
                                            telcos.map((t, index) => <Select.Option key={t.id} value={t.id}>{t.name}</Select.Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col md={8} sm={24} xs={24}>
                                <Form.Item label="Dịch vụ" name="service_id">
                                    <Select style={{ width: '100%' }}
                                        optionFilterProp="children"
                                        placeholder="Lựa chọn dịch vụ"
                                        filterOption={(input, option) =>
                                            option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        onChange={(value) => this.handleChange({ 'service_id': value })}
                                    >
                                        {
                                            services.map((s, index) => <Select.Option key={s.id} value={s.id}>{s.name}</Select.Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col md={8} sm={24} xs={24}>

                                <Form.Item label="Gói cước" name="package_id">
                                    <Select style={{ width: '100%' }}
                                        optionFilterProp="children"
                                        placeholder="Lựa chọn gói cước"
                                        mode="tags"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {
                                            packages.map((s, index) => <Select.Option key={s._id}
                                                value={s._id}>{s.code || s.name}</Select.Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col md={8} sm={24} xs={24}>
                                <Form.Item style={{ marginTop: '20px' }}>
                                    <Button type="primary" htmlType="submit">Lọc</Button>
                                </Form.Item>
                            </Col>

                        </Row>
                    </Form>

                </Card>

                <Row gutter={[24, 24]}>
                    <Col xs={24}>
                        <Card title="Đối soát nhà mạng">
                            <Button type="default"
                                style={{ marginBottom: 16, borderRadius: '5px', float: 'right', marginLeft: '15px' }}
                                icon={<DownloadOutlined />}
                                onClick={this.ExportExcelSubmit}
                            >
                                Excel
                            </Button>
                            <Table
                                columns={this.columns}
                                // scroll={{x: 1500}}
                                loading={loading}
                                dataSource={contents}
                                rowKey={record => record.id}
                                pagination={pagination}
                                onChange={this.handleTableChange}
                                summary={this.summary}
                            />
                        </Card>
                    </Col>
                </Row>
            </>
        );
    }
}

// adding the oidc user in the props
export default ForControlHomeNetwork;
