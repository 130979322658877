import React from 'react';
import {
  SearchOutlined
} from '@ant-design/icons';
import { Form, Input, notification } from 'antd';
import { APP_PREFIX_PATH } from 'configs/AppConfig';
import { useHistory } from 'react-router';
import CustomerService from "../../../services/CustomerService";

const SearchInput = ({ isMobile, mode}) => {
  const history = useHistory();

  const onFinish = async (values) => {
    const data = await CustomerService.read(values);

    if (!data || !data.length) {
      notification.error({
        message: `Số điện thoại ${values.msisdn} chưa đăng ký dịch vụ`
      });
    } else {
      let urlPattern = new RegExp(/(\/subscription\/phone\/)/)
      let url = window.location.href
      if (urlPattern.test(url)) {
        history.push(`${APP_PREFIX_PATH}/subscription/phone/${values.msisdn}`)
        history.go(0)
      } else {
        history.push(`${APP_PREFIX_PATH}/subscription/phone/${values.msisdn}`)
      }
    }
  };

  return (
    <Form
      name="phone_search"
      onFinish={onFinish}
    >
      <Form.Item name="msisdn">
        <Input placeholder="Tra cứu số điện thoại..." className={`nav-search-input ${isMobile ? 'is-mobile' : ''} ${mode === 'light' ? 'light' : ''}`} prefix={<SearchOutlined className="mr-0" />} />
      </Form.Item>
    </Form>
  )
}

export default SearchInput
