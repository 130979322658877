import React from "react";
import {Menu, Dropdown, Avatar} from "antd";
import {Link} from "react-router-dom";
import {connect} from 'react-redux'
import {
    LogoutOutlined,
    LockOutlined
} from '@ant-design/icons';
import Icon from 'components/util-components/Icon';
import {signOut} from 'redux/actions/Auth';
import {APP_PREFIX_PATH} from "../../configs/AppConfig";
import auth from "auth";

const menuItem = [
    // {
    // 	title: "Edit Profile",
    // 	icon: EditOutlined ,
    // 	path: "/"
    //   },

    //   {
    // 	title: "Account Setting",
    // 	icon: SettingOutlined,
    // 	path: "/"
    //   },
    //   {
    // 	title: "Help Center",
    // 	icon: QuestionCircleOutlined,
    // 	path: "/"
    // }
]

export const NavProfile = ({signOut}) => {

    let user;

    if (auth && auth.user) {
        user = auth.user.user;
    }

    const profileImg = "/img/avatars/avatars-1.png";
    const profileMenu = (
        <div className="nav-profile nav-dropdown">
            <div className="nav-profile-header">
                <div className="d-flex">
                    <Avatar size={45} src={profileImg}/>
                    <div className="pl-3">
                        <h4 className="mb-0">{ user && user.name ? user.name : '' }</h4>
                        {/* <span className="text-muted">{ user && user.email ? user.email : '' }</span> */}
                    </div>
                </div>
            </div>
            <div className="nav-profile-body">
                <Menu>
                    {menuItem.map((el, i) => {
                        return (
                            <Menu.Item key={i}>
                                <a href={el.path}>
                                    <Icon className="mr-3" type={el.icon}/>
                                    <span className="font-weight-normal">{el.title}</span>
                                </a>
                            </Menu.Item>
                        );
                    })}
                    <Menu.Item>
                        <Link to={`${APP_PREFIX_PATH}/thong-tin-nguoi-dung`}/>
                        <span>
                            <LockOutlined className="mr-3"/>
                            <span className="font-weight-normal">Thiết lập tài khoản</span>
                          </span>
                    </Menu.Item>
                    <Menu.Item key={menuItem.legth + 1} onClick={e => signOut()}>
                        <span>
                          <LogoutOutlined className="mr-3"/>
                          <span className="font-weight-normal">Sign Out</span>
                        </span>
                    </Menu.Item>
                </Menu>
            </div>
        </div>
    );
    return (
        <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
            <Menu className="d-flex align-item-center" mode="horizontal">
                <Menu.Item>
                    <Avatar src={profileImg}/>
                </Menu.Item>
            </Menu>
        </Dropdown>
    );
}

export default connect(null, {signOut})(NavProfile)
