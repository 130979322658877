export const COMPLAIN_TYPES = [
  {
    name: 'Hủy dịch vụ',
    value: 'cancel'
  },
  {
    name: 'Đăng ký dịch vụ',
    value: 'register'
  },
  {
    name: 'Kiểm tra tình trạng thuê bao',
    value: 'check'
  },
  {
    name: 'Yêu cầu hoàn cước',
    value: 'refund'
  },
  {
    name: 'Không nhận được MT dịch vụ',
    value: 'mt_not_receive'
  },
  {
    name: 'Nhập mã hội viên không hợp lệ',
    value: 'membership_code_invalid'
  },
  {
    name: 'Không nhận được phiếu đọc truyện',
    value: 'story_card_not_receive'
  },
  {
    name: 'Khác',
    value: 'other'
  }
];

export const COMPLAIN_REASONS = [
  {
    value: 'unregister',
    name: 'Không có nhu cầu sử dụng'
  },
  {
    value: 'register',
    name: 'Có nhu cầu sử dụng'
  },
  {
    value: 'error',
    name: 'Lỗi dịch vụ'
  },
  {
    value: 'membership_code_invalid',
    name: 'Nhập sai mã hội viên'
  },
  {
    value: 'check',
    name: 'Kiểm tra tình trạng thuê bao'
  },
  {
    value: 'other',
    name: 'Khác'
  }
];

export const CALL_TYPE = [
  {
    value: 'auto',
    name: 'Tự động'
  },
  {
    value: 'staff',
    name: 'Nhân viên'
  },
  {
    value: 'other',
    name: 'Khác'
  }
];

export const PACKAGE_TYPE = [
  {
    value: 'date',
    name: 'Ngày'
  },
  {
    value: 'week',
    name: 'Tuần'
  },
  {
    value: 'month',
    name: 'Tháng'
  },
  {
    value: 'turn',
    name: 'Lượt'
  }
];
