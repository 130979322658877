import {all} from 'redux-saga/effects';
import Auth from './Auth';
import Statistic from './Statistic';
import User from './User';
import UserGroup from './UserGroup';
import Theme from './Theme';

export default function* rootSaga(getState) {
    yield all([
        Auth(),
        Statistic(),
        User(),
        UserGroup(),
        Theme()
    ]);
}
