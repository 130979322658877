import React from 'react';

import { Table, Tag } from 'antd';
import moment from "moment";

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    render: (text, record, id) => id + 1
  },
  {
    title: 'Số điện thoại',
    dataIndex: 'msisdn',
    width: 150,
  },
  {
    title: 'Thời gian',
    dataIndex: 'call_time',
    width: 150,
    render: (_, record) => {
      const date = moment(record.call_time);
      return date.format('DD-MM-YYYY HH:mm:ss');
    }
  },
  {
    title: 'Gói cước',
    dataIndex: 'packages',
    width: 150,
    render: packages => (
      <>
        {
          packages.map(pack => {
            let color = '#3f87f5';
            return (
              <Tag color={color} key={pack.id}>
                {pack.code || pack.name}
              </Tag>
            );
          })
        }
      </>
    ),
  },
  {
    title: 'Đối tác',
    dataIndex: 'partners',
    width: 150,
    render: partners => (
      <>
        {
          partners.map(partner => {
            let color = '#3f87f5';
            return (
              <Tag color={color} key={partner.id}>
                {partner.name}
              </Tag>
            );
          }
          )}
      </>
    ),
  },
  {
    title: 'Loại cuộc gọi',
    dataIndex: 'type',
    width: 150,
    render: (_, record) => {
      if (record.type === 'auto') {
        return 'Tự động';
      } else {
        return 'Nhân viên';
      }
    }
  },
];

const TransactionTable = ({ callLogs, loading }) => {
  return (
    <Table
      loading={loading}
      columns={columns}
      dataSource={callLogs}
      rowKey={record => record.id}
    />
  )
}

export default TransactionTable;
