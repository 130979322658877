import React from 'react'
import { Button, Col, Row, Form, Select } from 'antd';
import IntlMessage from "components/util-components/IntlMessage";
import { TYPES } from './benefit-rates-form';


function BenefitRatesFormFilter({ onHandleFilter }) {
  const onFinish = values => {
    typeof onHandleFilter === 'function' && onHandleFilter(values)
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Form layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Row gutter={[24, 24]} align="bottom">
        <Col xs={24} md={5}>
          <Form.Item name="type_in" label="Loại">
            <Select
              placeholder="Chọn loại"
              filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {
                TYPES.map(d => <Select.Option key={d.id} value={d.value}>{d.name}</Select.Option>)
              }
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={3}>
          <Form.Item >
            <Button type="primary" htmlType="submit">
              <IntlMessage id={'button.submit'} />
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}


export default BenefitRatesFormFilter;
