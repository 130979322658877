// load user profile
export const LOAD_USER_PROFILE = 'LOAD_USER_PROFILE'
export const LOAD_USER_PROFILE_SUCCESS = 'LOAD_USER_PROFILE_SUCCESS'
export const LOAD_USER_PROFILE_ERROR = 'LOAD_USER_PROFILE_ERROR'

// update user profile
export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE'
export const UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS'
export const UPDATE_USER_PROFILE_ERROR = 'UPDATE_USER_PROFILE_ERROR'

// change password
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR'


export const loadUserProfile = () => {
  return {
    type: LOAD_USER_PROFILE
  }
}

export const loadUserProfileSuccess = (user) => {
  return {
    type: LOAD_USER_PROFILE_SUCCESS,
    user,
  }
}

export const loadUserProfileError = () => {
  return {
    type: LOAD_USER_PROFILE_ERROR
  }
}

export const updateUserProfile = (profile) => {
  return {
    type: UPDATE_USER_PROFILE,
    profile
  }
}


export const changePassword = (password) => {
  return {
    type: CHANGE_PASSWORD,
    password
  }
}

export const changePasswordSuccess = (password) => {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
    password
  }
}

export const changePasswordError = (error) => {
  return {
    type: CHANGE_PASSWORD_ERROR,
    error
  }
}
