import {
  CHANGE_SERVICE, GET_STATISTIC_INFO, UPDATE_STATISTIC_INFO
} from '../constants/Statistic';

export const changeService = (service) => {
  return {
    type: CHANGE_SERVICE,
    service
  }
};

export const getStatisticInfo = (payload) => {
  return {
    type: GET_STATISTIC_INFO,
    payload
  }
};


export const updateStatisticInfo = (payload) => {
  return {
    type: UPDATE_STATISTIC_INFO,
    payload
  }
};
