import fetch from 'interceptors/FetchInterceptor';

const DASHBOARD_URL_API = '/collections/reports';

class DashboardService {
  async getOverview(params) {
    const response = await fetch({
      url: DASHBOARD_URL_API + '/total-widget-dashboard',
      method: 'get',
      params
    });
    return response;
  }

  async getCallLog(params) {
    const response = await fetch({
      url: DASHBOARD_URL_API + '/call-log',
      method: 'get',
      params
    });
    return response;
  }
}

export default new DashboardService();