import fetch from 'interceptors/FetchInterceptor';
const CONTENT_API_ENDPOINT = '/collections/customers';

class CustomerService {
    async read(params){
        const response = await fetch({
            url: `${CONTENT_API_ENDPOINT}`,
            method: 'GET',
            params
        })
        return response;
    }
    async count(params){
        const response = await fetch({
            url: `${CONTENT_API_ENDPOINT}/count`,
            method: 'GET',
            params

        })
        return response;
    }
}
export default new CustomerService();