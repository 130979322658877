import React, { Component, useState } from 'react'
import { Tree, Form, Modal, Input, TreeSelect, Button, Card, message, Spin, Checkbox } from 'antd';
import { Redirect, Route, Switch } from 'react-router-dom';
import InnerAppLayout from 'layouts/inner-app-layout';
import {
  FileAddOutlined,
} from '@ant-design/icons';
import UserView from "./UserView";
import { connect } from 'react-redux';
import { loadAllUserGroups, loadDetailGroups, selectCurrentGroup, loadListUserByGroups } from "redux/actions/GroupUser";
import groupUserService from "services/GroupUserService";


const GroupRole = ({ allGroups, selectCurrentGroup, loadListUserByGroups }) => {
  const onSelect = (_selectedKeys, info) => {
    const selectedNode = info.node;

    selectCurrentGroup(selectedNode)
    loadListUserByGroups(selectedNode.id)

  };

  const treeData = convertToTreeData(allGroups);

  return (
    <>
      {treeData.length === 0 ? (<Spin />) : (

        <Tree
          defaultExpandParent={treeData[0].key}
          onSelect={onSelect}
          treeData={treeData}
        />
      )}
    </>
  );
};

const convertToTreeData = (groups) => {
  return groups.map(group => {
    let children = group.subGroups;
    if (children && children.length > 0) {
      children = convertToTreeData(group.subGroups);
    }

    return {
      ...group,
      title: group.name,
      key: group.id,
      children
    }
  });
}

const GroupModalForm = ({
  treeData,
  visible,
  title,
  onCreate,
  onCancel,
  initialValues = null,
  confirmLoading = false,
  isEditing = false
}) => {
  const [form] = Form.useForm();

  if (isEditing) {
    title = 'Chỉnh sửa group';
  } else {
    title = 'Thêm mới group';
  }

  const [roles, setRoles] = useState([]);
  let children = [];
  const handleChange = async (selectedKeys, info) => {
    const groupID = info.id;
    const response = await groupUserService.readGroupByID(groupID);
    var clientRoles = response.data.clientRoles.ams_cms;
    setRoles(clientRoles);
  };
  console.log(children)
  return (
    <Modal
      confirmLoading={confirmLoading}
      visible={visible}
      title={title}
      cancelText="Hủy"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onCreate({
              ...initialValues,
              ...values
            })
          })
          .catch((info) => {
            console.log(info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="groupForm"
      // initialValues={initialValues}
      >
        <Form.Item
          label="Tên group"
          name="name"
          rules={[{ required: true, message: 'Vui lòng nhập tên group' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Thuộc group"
          name="group_id"
          rules={[{ required: true, message: 'Vui lòng chọn group' }]}
        >
          <TreeSelect
            treeData={treeData}
            onSelect={handleChange}
          />
        </Form.Item>

        <Form.Item
          label="Quyền"
          name="clientRoles"
          rules={[{ required: true, message: 'Vui lòng chọn group' }]}
        >
          <Checkbox.Group options={roles} />
        </Form.Item>
      </Form>
    </Modal>
  );
}


const SettingContent = ({ match }) => {
  return (
    <Switch>
      <Redirect exact from={`${match.url}`} to={`${match.url}/user-list`} />
      <Route path={`${match.url}/user-list`} component={UserView} />
    </Switch>
  )
}


export class UserList extends Component {
  state = {
    visible: false,
    loading: false,
    confirmLoading: false,
    initialValues: {},
    isEditing: false,
    clientRoles: []
  }

  componentDidMount() {
    const { loadAllUserGroups, loading } = this.props;

    if (!loading) {
      loadAllUserGroups();
    }
  }

  componentDidUpdate() {
    const { allGroups, loadDetailGroups, loaded } = this.props;

    if (!loaded && allGroups && allGroups.length > 0) {
      allGroups.forEach(group => {
        loadDetailGroups(group.id)
      });
    }
  }

  handleAdd = () => {
    this.setState({
      initialValues: null,
      isEditing: false,
      visible: true
    });
  }

  handleCreate = async (group) => {
    const { loadAllUserGroups } = this.props;
    try {
      this.setState({
        confirmLoading: true
      });

      await groupUserService.createGroupByID(group);
      message.success({ content: `Thêm group ${group.name} thành công`, duration: 3 });

      // load user groups tree
      loadAllUserGroups();

      this.hideModal();
    } catch (e) {
      message.error({ content: `Thêm group ${group.name} thất bại`, duration: 3 });
      console.log(e);
      this.hideModal();
    }
  }

  hideModal = () => {
    this.setState({
      visible: false,
      confirmLoading: false
    });
  }

  onCancel = () => {
    this.hideModal();
  }


  render() {
    const { visible, confirmLoading, initialValues, isEditing } = this.state;
    const treeData = convertToTreeData(this.props.allGroups);
    const onCreate = this.handleCreate;
    return (
      <>
        <Card>
          <Button type="primary"
            icon={<FileAddOutlined />} onClick={this.handleAdd}>
            Thêm mới group
                    </Button>
        </Card>
        <GroupModalForm
          visible={visible}
          confirmLoading={confirmLoading}
          initialValues={initialValues}
          isEditing={isEditing}
          onCancel={this.onCancel}
          treeData={treeData}
          onCreate={onCreate}
        />
        <InnerAppLayout
          sideContentWidth={320}
          sideContent={<GroupRole {...this.props} />}
          mainContent={<SettingContent {...this.props} />}
        />
      </>
    );
  }
}

const mapStateToProps = ({ userGroup }) => {
  const { allGroups, listUsers, loading, loaded } = userGroup
  return { allGroups, listUsers, loading, loaded }
}

const mapDispathToProps = {
  loadAllUserGroups,
  loadDetailGroups,
  loadListUserByGroups,
  selectCurrentGroup
}

export default connect(mapStateToProps, mapDispathToProps)(UserList);