import fetch from 'interceptors/FetchInterceptor';

const REPORT_PREFIX_API_URL = '/collections/reports';

class ReportService {
  
  static async getRatioCharge(params) {
    const response = await fetch({
      url: `${REPORT_PREFIX_API_URL}/ratio-charge`,
      method: 'get',
      params
    });
    return response;
  }

  static async getUsersAndRevenue(params) {
    const response = await fetch({
      url: REPORT_PREFIX_API_URL + '/user-and-revenue',
      method: 'get',
      params
    });
    return response;
  }

  static async getStatisticUser(params) {
    const response = await fetch({
      url: REPORT_PREFIX_API_URL + '/statistic-user',
      method: 'get',
      params
    });
    return response;
  }

  static async getRevenueByTelco(params) {
    const response = await fetch({
      url: REPORT_PREFIX_API_URL + '/revenue-by-telco',
      method: 'get',
      params
    });
    return response;
  }

  static async getServicesAndPackages(params) {
    const response = await fetch({
      url: REPORT_PREFIX_API_URL + '/service-and-package',
      method: 'get',
      params
    });
    return response;
  }

  static async getCallsByService(params) {
    const response = await fetch({
      url: REPORT_PREFIX_API_URL + '/call-by-service',
      method: 'get',
      params
    });
    return response;
  }
}

export default ReportService;
