import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { loadUserProfileSuccess, loadUserProfileError, LOAD_USER_PROFILE, UPDATE_USER_PROFILE, CHANGE_PASSWORD, changePasswordSuccess, changePasswordError } from "redux/actions/User";
import userService from "services/UserService";


export function* getUserProfile() {
  yield takeEvery(LOAD_USER_PROFILE, function* () {
    try {
      console.log('saga')
      const user = yield call(userService.getUserProfile);

      if (user) {
        yield put(loadUserProfileSuccess(user));
      } else {
        yield put(loadUserProfileError('Người dùng không tìm thấy.'));
      }
    } catch (error) {
      yield put(loadUserProfileError('Người dùng không tìm thấy.'));
    }
  });
}

export function* updateUserProfile() {
  yield takeEvery(UPDATE_USER_PROFILE, function* (payload) {
    try {
      const user = yield call(userService.updateUserProfile, payload);
      if (user) {
        yield put(loadUserProfileSuccess(user));
      } else {
        yield put(loadUserProfileError('Cập nhật không hợp lệ'));
      }
    } catch (error) {
      yield put(loadUserProfileError('Cập nhật không hợp lệ'));
    }
  })
}

export function* updatePassword() {
  yield takeEvery(CHANGE_PASSWORD, function* (payload) {
    try {
      const data = yield call(userService.updatePassword, payload);
      if (data) {
        yield put(changePasswordSuccess(data));
      } else {
        yield put(changePasswordError());
      }
    } catch (error) {
      yield put(changePasswordError(error));
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(getUserProfile),
    fork(updateUserProfile),
    fork(updatePassword),
  ]);
}