import React, { Component } from 'react';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { Spin } from 'antd';
import { COLORS } from '../config';
import { sortBy } from 'lodash';

am4core.useTheme(am4themes_animated);

class StackColumnChart extends Component {
  constructor(props) {
    super(props);
    
    this.chartDivId = this.props.chartDivId || 'stackColumnChartDiv';
  }

  componentDidMount() {
    this.setup();
  }

  componentDidUpdate(_prevProps, _prevState, _snapshot) {
    this.setup();
  }

  componentWillUnmount() {
    this.dispose();
  }

  toData(data) {
    if (!data) {
      return [];
    } 

    return data.map(d => {
      const services = d.services.reduce((a,c) => {
        if (!a[c.name]) {
          a[c.name] = c.revenue
        }
        return a
      }, {});

      return {
        date: d.date,
        ...services
      };
    })
  }

  setup() {
    const { loading, data, seriesServices } = this.props;
    if (!loading && data && data.length) {
      this.dispose();
      this.build(sortBy(this.toData(data), (d) => new Date(d.date)), seriesServices);
    }
  }

  dispose() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  build(data, seriesServices) {
    let chart = am4core.create(this.chartDivId, am4charts.XYChart);

    chart.data = data;
    // Create axes

    chart.language.locale["_decimalSeparator"] = ",";
    chart.language.locale["_thousandSeparator"] = ".";

    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.renderer.minGridDistance = 50;
    dateAxis.renderer.grid.template.disabled = true;
    dateAxis.renderer.fullWidthTooltip = true;

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.title.text = "Doanh Thu (VND)";

    let count = 0;
    // Create series
    function createSeries(field, name, stacked) {
      const series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = field;
      series.dataFields.dateX = "date";
      series.name = name;
      series.columns.template.tooltipText = "{date} - {name}: [bold]{valueY}[/]";
      series.stacked = stacked;
      series.columns.template.width = am4core.percent(95);
      series.fill = am4core.color(COLORS[count]);
      series.stroke = am4core.color(COLORS[count]);
      count++;
    }

    const firstData = seriesServices.map((item) => {return item.name});

    firstData.map((value, index) => {
      createSeries(value, value, true);
    })

    // Add legend
    chart.legend = new am4charts.Legend();

    this.chart = chart;
  }

  render() {
    const { data, loading } = this.props;
    return (
      <>
        { loading ? <Spin /> :
          (
            !data || !data.length ? (<div>No data found</div>) : (<div id={this.chartDivId} style={{ width: "100%", height: "500px" }}></div>)
          )
        }
      </>
    );
  }
}

export default StackColumnChart;
