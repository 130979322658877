import fetch from 'interceptors/FetchInterceptor';

const MT_URL_API = '/collections/mt-logs';

class MtService {
    async read(params) {
        const response = await fetch({
            url: MT_URL_API,
            method: 'get',
            params
        });
        return response;
    }

    async count(params){
        const response = await fetch({
            url: MT_URL_API + '/count',
            method: 'GET',
            params
        })
        return response;
    }

    async export(params){
        const response = await fetch({
            url: MT_URL_API + '/export',
            method: 'GET',
            params
        })
        return response;
    }
}

export default new MtService();