import React, { Component, useEffect } from 'react';

import { Row, Col, Card, message, Table, Button, Modal, Form, Input, Tooltip, Popconfirm, } from 'antd';
import telcosService from 'services/TelcoService';
import serviceService from 'services/ServiceService';
import {
    EditOutlined,
    FileAddOutlined,
    DeleteOutlined
} from '@ant-design/icons';
import Utils from 'utils';
import PackageGroupService from 'services/PackageGroupService';

const PackageGroupModalForm = ({
    visible,
    title = 'Thêm mới nhóm gói',
    onCreate,
    onCancel,
    initialValues = null,
    confirmLoading = false,
    isEditing = false
}) => {
    const [form] = Form.useForm();

    useEffect(() => {
        if (initialValues) {
            form.setFieldsValue(initialValues);
        } else {
            form.resetFields();
        }
    }, [form, initialValues]);

    const okText = isEditing ? 'Sửa' : 'Thêm';

    if (isEditing) {
        title = 'Chỉnh sửa nhóm gói';
    }

    return (
        <Modal
            forceRender
            confirmLoading={confirmLoading}
            visible={visible}
            title={title}
            okText={okText}
            cancelText="Hủy"
            onCancel={onCancel}
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                        onCreate({
                            ...initialValues,
                            ...values
                        });
                    })
                    .catch((info) => {
                        console.log(info);
                    });
            }}
        >
            <Form
                form={form}
                layout="vertical"
                name="packageForm"
                initialValues={initialValues}
            >
                <Form.Item
                    label="Tên gói"
                    name="name"
                    rules={[{ required: true, message: 'Vui lòng nhập tên gói' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Mô tả"
                    name="description"
                >
                    <Input.TextArea row={4} />
                </Form.Item>

            </Form>
        </Modal>
    );
};

export class PackageGroup extends Component {
    state = {
        packageGroups: [],
        telcos: [],
        services: [],
        loading: false,
        visible: false,
        confirmLoading: false,
        initialValues: {},
        isEditing: false,
        pagination: {
            current: 1,
            pageSize: 10,
        },
        filters: {}
    };

    columns = [
        {
            title: 'STT',
            dataIndex: 'id',
            key: 'id',
            render: (text, record, index) => {
                const { current, pageSize } = this.state.pagination;

                return ((current - 1) * pageSize) + index + 1
            },
            width: 50
        },
        {
            title: 'Tên nhóm',
            dataIndex: 'name',
        },
        {
            title: 'Mô tả',
            dataIndex: 'description',
        },
        {
            title: 'Cập nhật',
            dataIndex: 'update',
            render: (_, record) => (
                <>
                    <Tooltip title="Sửa">
                        <Button icon={<EditOutlined />} size="small" onClick={() => this.handleEdit(record)} />
                    </Tooltip>
                </>
            )
        },
        {
            title: 'Xóa',
            dataIndex: 'delete',
            key: 'delete',
            render: (_, record) => (
                <>
                    <Popconfirm title="Bạn muốn xóa gói cước này không?" onConfirm={() => this.handleDelete(record)}>
                        <Tooltip title="Xóa">
                            <Button danger icon={<DeleteOutlined />} size="small" />
                        </Tooltip>
                    </Popconfirm>
                </>
            )
        },
    ];

    constructor(props) {
        super(props);
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadData();
        this.getTotalCount();
        this.getTelcos();
        this.getServices();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getTotalCount = async () => {
        const { filters } = this.state;

        const total = await PackageGroupService.count(filters);
        const { pagination } = this.state;
        this._isMounted && this.setState({
            pagination: {
                ...pagination,
                total
            }
        })
    }

    loadData = async (params) => {
        this.setState({
            loading: true
        })
        const { filters } = this.state;
        const __pagination = Utils.getPaginationParams(params);
        const __params = Object.assign(filters, __pagination);
        const packageGroups = await PackageGroupService.read(__params);
        const { pagination } = this.state;
        this._isMounted && this.setState({
            loading: false,
            packageGroups,
            pagination: {
                ...pagination,
                ...params
            }
        })
    }

    getTelcos = async () => {
        const telcos = await telcosService.read();
        this._isMounted && this.setState({
            telcos
        });
    }

    getServices = async () => {
        const services = await serviceService.read();
        this._isMounted && this.setState({
            services
        })
    }

    handleAdd = () => {
        this.setState({
            initialValues: null,
            isEditing: false,
            visible: true
        });
    };

    handleCreate = async (item) => {
        try {
            await PackageGroupService.create(item);
            message.success({ content: `Thêm gói cước ${item.name} thành công`, duration: 3 });
            await this.loadData();

            this.hideModal();
        } catch (e) {
            console.log(e);
            this.hideModal();
        }
    }

    handleEdit = (item) => {
        this.setState({
            initialValues: item,
            isEditing: true,
            visible: true
        });
    }

    handleUpdate = async (item) => {
        try {
            await PackageGroupService.update(item);
            message.success({ content: `Gói cước ${item.name} đã được cập nhật`, duration: 2 });

            await this.loadData();
            this.hideModal();
        } catch (e) {
            console.log(e);
            this.hideModal();
        }
    }

    handleDelete = async (item) => {
        try {
            await PackageGroupService.delete(item);
            message.success({ content: `Xóa gói cước ${item.name} thành công`, duration: 2 });
            await this.loadData();
        } catch (e) {
            console.log(e);
            this.hideModal();
        }
    }

    hideModal = () => {
        this.setState({
            visible: false,
            confirmLoading: false
        });
    }

    onCancel = () => {
        this.hideModal();
    }

    onFinish = async (values) => {
        this.setState({
            loading: true,
        });

        let filters = {};
        if (values.name && values.name !== '') {
            filters['name_contains'] = values.name;
        }

        if (values.service) {
            filters['service_id_in'] = values.service;
        }

        if (values.telco) {
            filters['telco_id_in'] = values.telco;
        }
        this.setState({
            filters,
            pagination: {
                current: 1,
                pageSize: 10,
            },
        })
        await this.getTotalCount();
        this.loadData();

    }

    handleTableChange = (pagination) => {
        this.loadData(pagination);
    }

    render() {
        const { packageGroups, loading, visible, confirmLoading, initialValues, isEditing, telcos, services, pagination } = this.state;
        const onCreate = isEditing ? this.handleUpdate : this.handleCreate;
        return (
            <Row>
                <Col span={24}>
                    <Card>
                        <h5 style={{ float: 'left' }}>Danh sách nhóm gói cước</h5>
                        <Button type="primary" style={{ marginBottom: 16, borderRadius: '5px', float: 'right' }}
                            icon={<FileAddOutlined />} onClick={this.handleAdd}>
                            Thêm mới gói
                        </Button>
                        <PackageGroupModalForm
                            confirmLoading={confirmLoading}
                            visible={visible}
                            isEditing={isEditing}
                            initialValues={initialValues}
                            onCreate={onCreate}
                            onCancel={this.onCancel}
                            telcos={telcos}
                            services={services}
                        />
                        <Table
                            columns={this.columns}
                            dataSource={packageGroups}
                            loading={loading}
                            rowKey={record => record.id}
                            pagination={pagination}
                            onChange={this.handleTableChange}
                        />
                    </Card>
                </Col>
            </Row>
        );
    }
}

export default PackageGroup;
