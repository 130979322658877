import { Timeline, Collapse } from 'antd';
import {
    UserDeleteOutlined,
    ClockCircleOutlined,
    PlayCircleOutlined,
    PauseOutlined,
    CustomerServiceOutlined,
    MessageOutlined,
    RightOutlined
} from '@ant-design/icons';
import moment from "moment";
import { Component } from "react";
const { Panel } = Collapse;

class CSKHTimeline extends Component {
    render() {
        const timeline = this.props.timeline;
        return (
            <Collapse accordion defaultActiveKey={['CSKHTimeline-active-1']}>
                <Panel header="Lịch sử hoạt động" key="CSKHTimeline-active-1">
                    <Timeline>
                        {
                            timeline.map((item, i) => {
                                let dot;
                                if (item.action === 'unregister') {
                                    dot = <UserDeleteOutlined style={{ width: '13px' }} />
                                } else if (item.action === 'renew') {
                                    dot = <PlayCircleOutlined style={{ width: '13px' }} />
                                } else if (item.action === 'pause') {
                                    dot = <PauseOutlined style={{ width: '13px' }} />
                                } else if (item.action === 'call') {
                                    dot = <CustomerServiceOutlined style={{ width: '13px' }} />
                                } else if (item.action === 'message') {
                                    dot = <MessageOutlined style={{ width: '13px' }} />
                                } else if (item.action === 'register') {
                                    dot = <RightOutlined style={{ width: '13px' }} />
                                }

                                return <Timeline.Item dot={dot} key={`timeline-${i}`}>
                                    <h5>{item.title}</h5>
                                    <div style={{ color: 'rgba(114,132,154,0.7)', fontSize: '13px' }}>
                                        <ClockCircleOutlined style={{ width: '13px' }} />
                                        <span style={{ marginLeft: '8px' }}>{moment(item.time).utc(false).format('DD-MM-YYYY HH:mm:ss')}</span>
                                    </div>
                                </Timeline.Item>
                            })
                        }
                    </Timeline>
                </Panel>
            </Collapse>
        )
    }
}

export default CSKHTimeline;