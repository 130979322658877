import fetch from 'interceptors/FetchInterceptor';

const SYNTAX_API_ENDPOINT = '/collections/command-codes';
class SyntaxService {
    async create(syntax){
        const response = await fetch({
            url: SYNTAX_API_ENDPOINT,
            method: 'POST',
            data: syntax,
        })
        return response;
    }
    async read(params){
        if(params){
            params['_sort'] = 'published_at:DESC';
        }
        const response = await fetch({
            url: `${SYNTAX_API_ENDPOINT}`,
            method: 'GET',
            params
        })
        return response;
    }
    async count(params){
        const response = await fetch({
            url: `${SYNTAX_API_ENDPOINT}/count`,
            method: 'GET',
            params

        })
        return response;
    }
    async update(syntax){
        const response = await fetch({
            url: `${SYNTAX_API_ENDPOINT}/${syntax.id}`,
            method: 'PUT',
            data: syntax
        })
        return response;
    }
    async delete(id){
        const response = await fetch({
            url: `${SYNTAX_API_ENDPOINT}/${id}`,
            method: 'DELETE',
        })
        return response;
    }
}
export default new SyntaxService();