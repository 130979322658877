
class User {
  user;

  constructor(decoded) {
    this.setUserFromToken(decoded);
  }
  
  setUserFromToken(decoded) {
    if (!decoded) {
      this.user = {
        name: null,
        given_name: null,
        family_name: null,
        email: null,
        resource_access: null,
        member_groups: []
      };
    } else {
      this.user = {
        name: decoded.name,
        given_name: decoded.given_name,
        family_name: decoded.family_name,
        email: decoded.email,
        resource_access: decoded.resource_access,
        member_groups: decoded.member_groups
      }
    }
  }

  isResourceAccess(resource) {
    if (!this.user || !this.user.resource_access) return false;

    const { resource_access } = this.user;

    const { roles } = resource_access.ams_cms

    return (roles || []).some((role => role.indexOf(resource) !== -1))
  }
}

export default User;
