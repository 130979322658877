import fetch from 'interceptors/FetchInterceptor';

const SERVICE_API_ENDPOINT = '/collections/services';
class ServiceService {
    async create(service){
        const response = await fetch({
            url: SERVICE_API_ENDPOINT,
            method: 'POST',
            data: service,
        })
        return response;
    }
    async read(params){
        const response = await fetch({
            url: `${SERVICE_API_ENDPOINT}`,
            method: 'GET',
            params
        })
        return response;
    }

    async readDetail(id){
        const response = await fetch({
            url: `${SERVICE_API_ENDPOINT}/${id}`,
            method: 'GET'
        })
        return response;
    }

    async count(params){
        const response = await fetch({
            url: `${SERVICE_API_ENDPOINT}/count`,
            method: 'GET',
            params
        })
        return response;
    }
    async update(service){
        const response = await fetch({
            url: `${SERVICE_API_ENDPOINT}/${service.id}`,
            method: 'PUT',
            data: service
        })
        return response;
    }
    async delete(id){
        const response = await fetch({
            url: `${SERVICE_API_ENDPOINT}/${id}`,
            method: 'DELETE',
        })
        return response;
    }
}
export default new ServiceService();