import fetch from 'interceptors/FetchInterceptor';

const SUBSCRIPTION_URL_API = '/collections/subscribers';

class SubscriptionServiceService {
    async read(params) {
        if(params){
            params['_sort'] = 'registered_time:DESC';
        }
        const response = await fetch({
            url: SUBSCRIPTION_URL_API,
            method: 'get',
            params
        });
        return response;
    }

    async count(params){
        const response = await fetch({
            url: SUBSCRIPTION_URL_API + '/count',
            method: 'GET',
            params
        })
        return response;
    }

    async export(params){
        console.log(params)
        const response = await fetch({
            url: SUBSCRIPTION_URL_API + '/export',
            method: 'GET',
            params
        })
        return response;
    }
}

export default new SubscriptionServiceService();