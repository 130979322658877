import React, {useState, useEffect} from 'react'
import {
    Button,
    Card,
    Col,
    DatePicker,
    Form,
    Input,
    message,
    Modal,
    Popconfirm,
    Row,
    Select,
    Table,
    Tooltip
} from "antd";
import {
    EditOutlined,
    FileAddOutlined,
    DeleteOutlined,
    DownloadOutlined
} from '@ant-design/icons';
import ContentService from "services/ContentService";
import TelcoService from "services/TelcoService";
import ServiceService from "services/ServiceService";
import moment from "moment";
import Utils from 'utils';
import ServiceGroupService from "../../../services/ServiceGroupService";

const {RangePicker} = DatePicker;
const {TextArea} = Input;

const PopupContent = ({
                          okText,
                          title,
                          initialValues = null,
                          isEditing = false,
                          visible,
                          loading,
                          onCancel,
                          onCreate,
                          services = [],
                          service_types = [],
                          telcos = [],
                          packages = []
                      }) => {
    const [form] = Form.useForm();

    useEffect(() => {
        form.resetFields();
        if (initialValues) {
            if (initialValues.status === 0) {
                setStatus('block');
            }

            form.setFieldsValue(initialValues);
        } else {
            form.resetFields();
        }
    }, [form, initialValues]);

    okText = isEditing ? 'Sửa' : 'Thêm';
    title = isEditing ? 'Sửa nội dung' : 'Thêm mới nội dung';
    // var status = 'none';

    const [display, setStatus] = useState('none');
    const [displayGroup, setDisplayGroup] = useState('none');
    const [timeRequired, setTimeRequired] = useState(false);

    function handleOnChange(value, event) {
        if (value === 0) {
            setStatus('block');
            setTimeRequired(true)
        } else {
            setStatus('none');
            setTimeRequired(false)
        }
    }

    async function handleOnChangeService(value, event) {
        let filter = {services_in: value}
        let result = await ServiceGroupService.read(filter)

        if (result.length) {
            setDisplayGroup('block')
        } else {
            setDisplayGroup('none')
        }
    }

    return (
        <Modal
            forceRender
            visible={visible}
            title={title}
            okText={okText}
            cancelText="Hủy"
            onCancel={onCancel}
            onOk={async () => {
                await form
                    .validateFields()
                    .then((values) => {
                        onCreate({
                            ...initialValues,
                            ...values
                        });
                    })
                    .catch((info) => {
                        console.log(info);
                    });
                form.resetFields()
            }}>
            <Form
                form={form}
                layout="vertical"
                name="contentForm"
                initialValues={initialValues}
            >
                <Form.Item
                    label="Nội dung"
                    name="mt_content"
                    rules={[{required: true, message: 'Nội dung không được để trống'}]}
                >
                    <TextArea/>
                </Form.Item>
                <Form.Item name={['telco_id', 'id']} label="Nhà mạng" rules={[{
                    required: true,
                    message: 'Nhà mạng không được để trống'
                }]}>
                    <Select>
                        {
                            telcos.map((t, index) => <Select.Option key={`telco_${index}`}
                                                                    value={t.id}>{t.name}</Select.Option>)
                        }
                    </Select>
                </Form.Item>
                <Form.Item name={['service_id', 'id']} label="Dịch vụ" rules={[{
                    required: true,
                    message: 'Dịch vụ không được để trống'
                }]}>
                    <Select onSelect={(value, event) => handleOnChangeService(value, event)}>
                        {
                            services.map((s, index) => <Select.Option key={`service_${index}`}
                                                                      value={s.id}>{s.name}</Select.Option>)
                        }

                    </Select>
                </Form.Item>
                <Form.Item name={['service_type', 'id']} style={{display: displayGroup}} label="Loại dịch vụ">
                    <Select>
                        {
                            service_types.map((s, index) => <Select.Option key={`service_type_${index}`}
                                                                      value={s.id}>{s.name}</Select.Option>)
                        }

                    </Select>
                </Form.Item>
                <Form.Item name="status" label="Trạng thái" rules={[{
                    required: true,
                    message: 'Trạng thái không được để trống'
                }]}>
                    <Select onSelect={(value, event) => handleOnChange(value, event)}>
                        <Select.Option value={0}>
                            Lên lịch
                        </Select.Option>
                        <Select.Option value={1}>
                            Đang gửi
                        </Select.Option>
                        <Select.Option value={3}>
                            Đã gửi
                        </Select.Option>
                        <Select.Option value={4}>
                            Tạm dừng
                        </Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item name="public_time1" style={{display: display}}
                           label="Thời gian gửi"
                    rules={[{ required: timeRequired, message: 'Thời gian gửi không được để trống' }]}
                >
                    <DatePicker showTime format="YYYY-MM-DDTHH:mm:ss" style={{width: '100%'}}/>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export class ContentManagement extends React.Component {
    state = {
        services: [],
        service_types: [],
        packages: [],
        telcos: [],
        contents: [],
        initialValues: {},
        isEditing: false,
        visible: false,
        loading: false,
        title: '',
        pagination: {
            current: 1,
            pageSize: 10,
        },
        filters: {_sort: "public_time:DESC"},
    };

    columns = [
        {
            title: 'STT',
            dataIndex: 'id',
            key: 'id',
            render: (text, record, index) => {
                const { current, pageSize } = this.state.pagination;

                return ((current - 1) * pageSize) + index + 1
            },
            width: 50
        },
        {
            title: 'Nội dung',
            dataIndex: 'mt_content',
            key: 'mt_content',
            width: 500
        },
        {
            title: 'Nhà mạng',
            dataIndex: ['telco_id', 'name'],
            key: ['telco_id', 'name'],
            width: 120
        },
        {
            title: 'Dịch vụ',
            dataIndex: ['service_id', 'name'],
            key: ['service_id', 'name'],
            width: 120
        },
        {
            title: 'Loại dịch vụ',
            dataIndex: ['service_group', 'name'],
            key: ['service_group', 'name'],
            width: 120
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            key: 'status',
            width: 120,
            render: (status, record) => {
                if(status === 0) {
                    return 'Lên lịch';
                }
                else if(status === 1) {
                    return 'Đang gửi';
                }
                else if(status === 3) {
                    return 'Đã gửi';
                }
                else if(status === 4) {
                    return 'Tạm dừng';
                }
            }
        },
        {
            title: 'Thời gian lên lịch',
            dataIndex: 'public_time',
            key: 'public_time',
            width: 150,
            render: (public_time) => moment.utc(public_time).format("YYYY-MM-DD HH:mm:ss"),
        },
        {
            title: 'Hành động',
            dataIndex: 'action',
            key: 'action',
            width: 150,
            fixed: 'right',
            render: (_, record) => (
                <>
                    <Tooltip title="Sửa">
                        <Button danger icon={<EditOutlined/>} size="small" onClick={() => this.handleEdit(record)}/>
                    </Tooltip>&nbsp;
                    <Popconfirm title="Bạn muốn xóa nội dung này phải không?"
                                onConfirm={() => this.handleDelete(record)}>
                        <Tooltip title="Xóa">
                            <Button danger icon={<DeleteOutlined/>} size="small"/>
                        </Tooltip>
                    </Popconfirm>
                </>
            )
        },
    ];

    constructor(props) {
        super(props);

        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        this.getDefaultDate();
        this.loadData();
        this.getTotalCount();
        this.loadTelco();
        this.loadService();
        this.loadServiceType();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getTotalCount = async () => {
        const {filters} = this.state;
        const total = await ContentService.count(filters);
        const {pagination} = this.state;
        this._isMounted && this.setState({
            pagination: {
                ...pagination,
                total
            }
        })
    }


    getDefaultDate = () => {
        const {filters} = this.state;
        let defaultDates = Utils.getDefaultDates();

        if (filters.public_time_gte === undefined || filters.public_time_lte === undefined){
            filters['public_time_gte'] = moment.utc(defaultDates[0]).format('YYYY-MM-DDT00:00:00.000Z');
            filters['public_time_lte'] = moment.utc(defaultDates[1]).format('YYYY-MM-DDT23:59:59.000Z');
        }
        this.setState({
            filters
        })
    }

    loadData = async (params) => {
        this.setState({
            loading: true
        })
        const {filters} = this.state;
        const __pagination = Utils.getPaginationParams(params);
        const __params = Object.assign(filters, __pagination);
        const contents = await ContentService.read(__params);
        const { pagination } = this.state;
        this._isMounted && this.setState({
            loading: false,
            contents,
            filters,
            pagination: {
                ...pagination,
                ...params
            }
        });
        await this.getTotalCount();
    }
    loadTelco = async () => {
        const telcos = await TelcoService.read();
        this._isMounted && this.setState({
            telcos
        })
    }
    loadService = async () => {
        const services = await ServiceService.read();
        this._isMounted && this.setState({
            services
        })
    }

    loadServiceType = async () => {
        const service_types = await ServiceGroupService.read();
        this._isMounted && this.setState({
            service_types
        })
    }
    handleOnChange = (event) => {
        console.log(event); // here I'm receiving only the value selected (1 or 2)
    }
    handleAdd = () => {
        this.setState({
            visible: true,
            isEditing: false,
            title: 'Thêm mới nội dung',
            initialValues: null,
        });
    }
    handleCreate = async (content) => {
        try {
            content.service_id = content.service_id.id;
            content.telco_id = content.telco_id.id;
            content.service_group = content?.service_type ? content?.service_type.id : '';
            if (content.status === 0) {
                content.public_time = moment(content.public_time1).utc(true).format('YYYY-MM-DDTHH:mm:ss.000Z');
            }
            await ContentService.create(content);
            await this.loadData();
            this.hideModal();
            message.success({content: 'Thêm mới thành công', duration: 2});
        } catch (error) {
            this.hideModal();
            message.error({content: 'Thêm mới không thành công', duration: 2});
        }
    }

    handleEdit = (content) => {
        content.public_time1 = moment(content.public_time).utc()
        this.setState({
            initialValues: content,
            visible: true,
            isEditing: true,
            title: 'Cập nhật nội dung'
        });
    }
    handleUpdate = async (content) => {
        try {
            const telco_id = content.telco_id.id;
            const service_id = content.service_id.id;
            content.service_type_id = content?.service_type ? content?.service_type.id : '';
            content.telco_id = telco_id;
            content.service_id = service_id;
            if (content.status === 0) {
                content.public_time = moment(content.public_time1).utc(true).format('YYYY-MM-DDTHH:mm:ss.000Z');
            } else {
                delete content.public_time
                delete content.public_time1
            }
            await ContentService.update(content);
            await this.loadData();
            message.success({content: 'Cập nhật thành công', duration: 2});
            this.hideModal();
        } catch (error) {
            message.error({content: 'Cập nhật không thành công', duration: 2});
            this.hideModal();
        }
    }
    handleDelete = async (content) => {
        try {
            await ContentService.delete(content.id);
            const {contents} = this.state;
            this._isMounted && this.setState({
                contents: contents.filter((c) => c.id !== content.id)
            })
            message.success({content: 'Xóa thành công', duration: 2});
        } catch (error) {
            message.error({content: 'Xóa không thành công', duration: 2});
        }
    }
    handleDownload = async () => {
        this.state.filters._limit = -1
        const url = await ContentService.export(this.state.filters)
        if (url) {
            Utils.downloadBySaveFile(url, url.replace(/^.*[/]/, ''))
        }
    }
    hideModal = () => {
        this.setState({
            visible: false
        })
    }
    onCancel = () => {
        this.hideModal();
    }

    onFinish = async (values) => {
        let filters = {};
        if (values.syntax !== undefined) {
            filters['mt_content_contains'] = values.syntax;
        }
        if(values.time === undefined){
            let defaultDates = Utils.getDefaultDates();
            if (filters.public_time_gte === undefined || filters.public_time_lte === undefined){
                filters['public_time_gte'] = moment.utc(defaultDates[0]).format('YYYY-MM-DDT00:00:00.000Z');
                filters['public_time_lte'] = moment.utc(defaultDates[1]).format('YYYY-MM-DDT23:59:59.000Z');
            }
        }
        if (values.time !== undefined && values.time !== null) {
            let time_1 = new Date(values.time[0]);
            let time_2 = new Date(values.time[1]);
            time_1 = moment.utc(time_1).format('YYYY-MM-DDT00:00:00.000Z');
            time_2 = moment.utc(time_2).format('YYYY-MM-DDT23:59:59.000Z');
            filters['public_time_gte'] = time_1;
            filters['public_time_lte'] = time_2;
        }
        if (values.telco !== undefined && typeof values.telco === "object") {
            filters['telco_id_in'] = values.telco;
        }
        if (values.service !== undefined && typeof values.service === "object") {
            filters['service_id_in'] = values.service;
        }
        if (values.status !== undefined) {
            filters['status_eq'] = values.status;
        }

        if (values?.service_type) {
            filters['service_type'] = values.service_type;
        }
        filters = {...this.state.filters, ...filters}
        this.setState({
            filters,
            pagination: {
                current: 1,
                pageSize: 10,
            },
        })
        // await this.getTotalCount();
        this.loadData();
    }
    onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    handleTableChange = (pagination) => {
        this.loadData(pagination);
    }

    render() {
        const {visible, initialValues, services, service_types, telcos, contents, isEditing, loading, pagination} = this.state;
        const onCreate = isEditing ? this.handleUpdate : this.handleCreate;
        return (
            <>
                <Form layout="vertical" onFinish={this.onFinish} onFinishFailed={this.onFinishFailed}>
                    <Row style={{marginBottom: '15px'}} gutter={[24, 24]}>
                        <Col md={6} sm={24} xs={24}>
                            <Form.Item name="time" label="Thời gian lọc" style={{width: '100%'}} initialValue={Utils.getDefaultDates()}>
                                <RangePicker style={{width: '100%'}}/>
                            </Form.Item>
                        </Col>
                        <Col md={6} sm={24} xs={24}>
                            <Form.Item label="Dịch vụ" name="service">
                                <Select style={{width: '100%'}}
                                        mode="tags"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                >
                                    {
                                        services.map((s, index) => <Select.Option key={`service-${index}`}
                                                                                  value={s.id}>{s.name}</Select.Option>)
                                    }
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col md={6} sm={24} xs={24}>
                            <Form.Item label="Loại dịch vụ" name="service_type">
                                <Select style={{width: '100%'}}
                                        // mode="tags"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                >
                                    {
                                        service_types.map((s, index) => <Select.Option key={`service-type-${index}`}
                                                                                  value={s.id}>{s.name}</Select.Option>)
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col md={6} sm={24} xs={24}>
                            <Form.Item label="Nhà mạng" name="telco">
                                <Select style={{width: '100%'}}
                                        mode="tags"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                >
                                    {
                                        telcos.map((t, index) => <Select.Option key={`telco-${index}`}
                                                                                value={t.id}>{t.name}</Select.Option>)
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col md={6} sm={24} xs={24}>
                            <Form.Item label="Trạng thái" name="status"
                                       style={{width: '60%', float: 'left', marginRight: '5px'}}>
                                <Select style={{width: '100%'}}
                                        mode="tags"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                >
                                    <Select.Option value="0">
                                        Lên lịch
                                    </Select.Option>
                                    <Select.Option value="1">
                                        Đang gửi
                                    </Select.Option>
                                    <Select.Option value="3">
                                        Đã gửi
                                    </Select.Option>
                                    <Select.Option value="4">
                                        Tạm dừng
                                    </Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item style={{width: '35%', float: 'left', marginTop: '20px', marginLeft: '5px'}}>
                                <Button type="primary" htmlType="submit"
                                        style={{float: 'right', width: '100%'}}>Lọc</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <PopupContent
                    onCreate={onCreate}
                    visible={visible}
                    isEditing={isEditing}
                    initialValues={initialValues}
                    telcos={telcos}
                    contents={contents}
                    services={services}
                    service_types={service_types}
                    onCancel={this.onCancel}
                />
                <Row gutter={[24, 24]}>
                    <Col xs={24}>
                        <Card title="Danh sách nội dung">
                            <Button type="default"
                                    style={{marginBottom: 16, borderRadius: '5px', float: 'right', marginLeft: '15px'}}
                                    icon={<DownloadOutlined/>}
                                    onClick={this.handleDownload}>
                                Tải về
                            </Button>
                            <Button onClick={() => this.handleAdd()} type="primary"
                                    style={{marginBottom: 16, borderRadius: '5px', float: 'right'}}
                                    icon={<FileAddOutlined/>}>Thêm mới nội dung</Button>

                            <Table columns={this.columns}
                                   loading={loading}
                                   dataSource={contents}
                                   pagination={pagination}
                                   onChange={this.handleTableChange}
                                   rowKey={record => record.id}/>
                        </Card>
                    </Col>
                </Row>
            </>
        );
    }
}

export default ContentManagement;
