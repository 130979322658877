import React, { Component, useEffect } from 'react';

import { Row, Col, Card, message, Table, Button, Modal, Form, Select, Input, Tooltip, Popconfirm, } from 'antd';
import packageService from 'services/PackageService';
import telcosService from 'services/TelcoService';
import serviceService from 'services/ServiceService';
import {
    EditOutlined,
    FileAddOutlined,
    DeleteOutlined
} from '@ant-design/icons';
import Utils from "utils";
import PackageGroupService from 'services/PackageGroupService';

const PackageModalForm = ({ 
    visible, 
    title = 'Thêm mới gói', 
    onCreate, 
    onCancel, 
    initialValues = null, 
    confirmLoading = false, 
    isEditing = false, 
    telcos = [], 
    services = [], 
    packageGroups = [] 
}) => {
    const [form] = Form.useForm();

    useEffect(() => {
        if (initialValues) {
            form.setFieldsValue(initialValues);
        } else {
            form.resetFields();
        }
    }, [form, initialValues]);

    const okText = isEditing ? 'Sửa' : 'Thêm';

    if (isEditing) {
        title = 'Chỉnh sửa gói';
    }

    return (
        <Modal
            forceRender
            confirmLoading={confirmLoading}
            visible={visible}
            title={title}
            okText={okText}
            cancelText="Hủy"
            onCancel={onCancel}
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                        onCreate({
                            ...initialValues,
                            ...values
                        });
                    })
                    .catch((info) => {
                        console.log(info);
                    });
            }}
        >
            <Form
                form={form}
                layout="vertical"
                name="packageForm"
                initialValues={initialValues}
            >
                <Form.Item
                    label="Tên gói"
                    name="name"
                    rules={[{ required: true, message: 'Vui lòng nhập tên gói' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Nhà mạng"
                    name={['telco_id', 'id']}
                    // name="telco_id"
                    rules={[{ required: true, message: 'Vui lòng chọn nhà mạng' }]}
                >
                    <Select>
                        {telcos.map((data) => <Select.Option key={data.id} value={data.id}>{data.name}</Select.Option>)}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Dịch vụ"
                    name={['service_id', 'id']}
                    rules={[{ required: true, message: 'Vui lòng chọn dịch vụ' }]}
                >
                    <Select>
                        {services.map((data) => <Select.Option key={data.id} value={data.id}>{data.name}</Select.Option>)}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Mức phí"
                    name="amount"
                    rules={[{ required: true, message: 'Vui lòng nhập mức phí' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Đầu số"
                    name="tel_service"
                    rules={[{ required: true, message: 'Vui lòng nhập đầu số' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Nhóm gói cước"
                    name="package_group"
                    rules={[{ required: true, message: 'Cú pháp không được để trống' }]}
                >
                    <Select
                        placeholder="Chọn nhóm gói cước"
                        filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                        {
                            packageGroups && packageGroups.map(s => <Select.Option key={s.id} value={s.id}>{s.name}</Select.Option>)
                        }
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Mô tả"
                    name="description"
                >
                    <Input.TextArea row={4} />
                </Form.Item>

            </Form>
        </Modal>
    );
};

export class PackageList extends Component {
    state = {
        packages: [],
        telcos: [],
        services: [],
        loading: false,
        visible: false,
        confirmLoading: false,

        packageGroups: [],
        packageGroupsLoading: false,

        initialValues: {},
        isEditing: false,
        pagination: {
            current: 1,
            pageSize: 10,
        },
        filters: {}
    };

    columns = [
        {
            title: 'STT',
            dataIndex: 'id',
            key: 'id',
            render: (text, record, index) => {
                const { current, pageSize } = this.state.pagination;

                return ((current - 1) * pageSize) + index + 1
            },
            width: 50
        },
        {
            title: 'Tên gói',
            dataIndex: 'name',
        },
        {
            title: 'Nhóm gói cước',
            dataIndex: ['package_group', 'name'],
        },
        {
            title: 'Nhà mạng',
            dataIndex: ['telco_id', 'name'],
        },
        {
            title: 'Dịch vụ',
            dataIndex: ['service_id', 'name'],
        },
        {
            title: 'Mức phí',
            dataIndex: 'amount',
        },
        {
            title: 'Đầu số',
            dataIndex: 'tel_service',
        },
        {
            title: 'Ngày khởi tạo',
            dataIndex: 'published_at',
            render: (_, record) => {
                const date = record.published_at.split('T');
                return date[0].split('-').reverse().join('-');
            }
        },
        {
            title: 'Cập nhật',
            dataIndex: 'update',
            render: (_, record) => (
                <>
                    <Tooltip title="Sửa">
                        <Button icon={<EditOutlined />} size="small" onClick={() => this.handleEdit(record)} />
                    </Tooltip>
                </>
            )
        },
        {
            title: 'Xóa',
            dataIndex: 'delete',
            key: 'delete',
            render: (_, record) => (
                <>
                    <Popconfirm title="Bạn muốn xóa gói cước này không?" onConfirm={() => this.handleDelete(record)}>
                        <Tooltip title="Xóa">
                            <Button danger icon={<DeleteOutlined />} size="small" />
                        </Tooltip>
                    </Popconfirm>
                </>
            )
        },
    ];

    constructor(props) {
        super(props);
        this._isMounted = false;

        this.handleUpdate = this.handleUpdate.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadData();
        this.loadServiceGroups();
        this.getTotalCount();
        this.getTelcos();
        this.getServices();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getTotalCount = async () => {
        const { filters } = this.state;

        const total = await packageService.count(filters);
        const { pagination } = this.state;
        this._isMounted && this.setState({
            pagination: {
                ...pagination,
                total
            }
        })
    }

    loadData = async (params) => {
        this.setState({
            loading: true
        })
        const { filters } = this.state;
        const __pagination = Utils.getPaginationParams(params);
        const __params = Object.assign(filters, __pagination);
        const packages = await packageService.read(__params);
        const { pagination } = this.state;
        this._isMounted && this.setState({
            loading: false,
            packages,
            pagination: {
                ...pagination,
                ...params
            }
        })
    }

    loadServiceGroups = async () => {
        this.setState({
            packageGroupsLoading: true
        })

        const packageGroups = await PackageGroupService.read();
        this._isMounted && this.setState({
            packageGroups,
            packageGroupsLoading: false
        })
    }

    getTelcos = async () => {
        const telcos = await telcosService.read();
        this._isMounted && this.setState({
            telcos
        });
    }

    getServices = async () => {
        const services = await serviceService.read();
        this._isMounted && this.setState({
            services
        })
    }

    handleAdd = () => {
        this.setState({
            initialValues: null,
            isEditing: false,
            visible: true
        });
    };

    handleCreate = async (item) => {
        try {
            const data = this.handleData(item);
            await packageService.create(data);
            message.success({ content: `Thêm gói cước ${item.name} thành công`, duration: 3 });
            await this.loadData();

            this.hideModal();
        } catch (e) {
            console.log(e);
            this.hideModal();
        }
    }

    handleEdit = (data) => {
        this.setState({
            initialValues: {
                ...data,
                package_group: data.package_group && data.package_group.id ? data.package_group.id : null
            },
            isEditing: true,
            visible: true
        });
    }

    handleUpdate = async (data) => {
        try {
            const { packages } = this.state;
            const newPackage = await packageService.update(this.handleData(data));
            const foundIndex = packages.findIndex(p => p.id === data.id);
            if (foundIndex !== -1) {
                packages[foundIndex] = {
                    ...packages[foundIndex],
                    ...newPackage
                }
            }
            this.setState({
                packages: [...packages],
                visible: false,
                isEditing: false,
            })
            this.hideModal();


        } catch (e) {
            console.log(e);
            this.hideModal();
        }
    }

    handleDelete = async (item) => {
        try {
            await packageService.delete(item);
            message.success({ content: `Xóa gói cước ${item.name} thành công`, duration: 2 });
            await this.loadData();
        } catch (e) {
            console.log(e);
            this.hideModal();
        }
    }

    hideModal = () => {
        this.setState({
            visible: false,
            confirmLoading: false
        });
    }

    onCancel = () => {
        this.hideModal();
    }

    handleData = (item) => {
        const telco_id = item.telco_id.id;
        const service_id = item.service_id.id;
        const description = item.description;
        item['telco_id'] = telco_id;
        item['service_id'] = service_id;

        if (description) {
            item['description'] = description;
        } else {
            item['description'] = '';
        }

        return item;
    }

    onFinish = async (values) => {
        this.setState({
            loading: true,
        });

        let filters = {};
        if (values.name && values.name !== '') {
            filters['name_contains'] = values.name;
        }

        if (values.service) {
            filters['service_id_in'] = values.service;
        }

        if (values.telco) {
            filters['telco_id_in'] = values.telco;
        }
        this.setState({
            filters,
            pagination: {
                current: 1,
                pageSize: 10,
            },
        })
        await this.getTotalCount();
        this.loadData();

    }

    handleTableChange = (pagination) => {
        this.loadData(pagination);
    }

    render() {
        const { packages, loading, visible, confirmLoading, initialValues, isEditing, telcos, services, pagination, packageGroups } = this.state;
        const onCreate = isEditing ? this.handleUpdate : this.handleCreate;
        return (
            <>
                <Form layout="vertical" onFinish={this.onFinish}>
                    <Row style={{ marginBottom: '15px' }} gutter={[24, 24]}>
                        <Col md={6} sm={24} xs={24}>
                            <Form.Item label="Tên gói" name="name">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col md={6} sm={24} xs={24}>
                            <Form.Item label="Dịch vụ" name="service">
                                <Select style={{ width: '100%' }}
                                    mode="tags"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {
                                        services.map((s, index) =>
                                            <Select.Option key={'service-option-' + index} value={s.id}>{s.name}</Select.Option>)
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col md={6} sm={24} xs={24}>
                            <Form.Item label="Nhà mạng" name="telco">
                                <Select style={{ width: '100%' }}
                                    mode="tags"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {
                                        telcos.map((p, index) =>
                                            <Select.Option key={'telco_' + index} value={p.id}>{p.name}</Select.Option>)
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col md={6} sm={24} xs={24}>
                            <Form.Item style={{ marginTop: '20px' }}>
                                <Button type="primary" htmlType="submit">Lọc</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <Row>
                    <Col span={24}>
                        <Card>
                            <h5 style={{ float: 'left' }}>Danh sách gói cước</h5>
                            <Button type="primary" style={{ marginBottom: 16, borderRadius: '5px', float: 'right' }}
                                icon={<FileAddOutlined />} onClick={this.handleAdd}>
                                Thêm mới gói
                            </Button>
                            <PackageModalForm
                                confirmLoading={confirmLoading}
                                packageGroups={packageGroups}
                                visible={visible}
                                isEditing={isEditing}
                                initialValues={initialValues}
                                onCreate={onCreate}
                                onCancel={this.onCancel}
                                telcos={telcos}
                                services={services}
                            />
                            <Table
                                columns={this.columns}
                                dataSource={packages}
                                loading={loading}
                                rowKey={record => record.id}
                                pagination={pagination}
                                onChange={this.handleTableChange}
                            />
                        </Card>
                    </Col>
                </Row>
            </>
        );
    }
}

export default PackageList