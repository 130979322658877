import React, {Component} from 'react'
import {Form, Button, Input, Row, Col, message} from 'antd';
import {changePassword} from "redux/actions/User";
import {connect} from "react-redux";

export class ChangePassword extends Component {

    changePasswordFormRef = React.createRef();

    onFinish = (values) => {
        const key = 'updatable';

        message.loading({content: 'Updating...', key});

        this.props.updatePassword({"oldPassword": values.currentPassword, "newPassword": values.newPassword});

        // message.success({ content: 'Password Changed!', duration: 2 });
        // this.onReset()
    };

    onReset = () => {
        this.changePasswordFormRef.current.resetFields();
    };

    render() {

        return (
            <>
                <h2 className="mb-4">Change Password</h2>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={8}>
                        <Form
                            name="changePasswordForm"
                            layout="vertical"
                            ref={this.changePasswordFormRef}
                            onFinish={this.onFinish}
                        >
                            <Form.Item
                                label="Mật khẩu cũ"
                                name="currentPassword"
                                rules={[{
                                    required: true,
                                    message: 'Please enter your currrent password!'
                                }]}
                            >
                                <Input.Password/>
                            </Form.Item>
                            <Form.Item
                                label="Mật khẩu mới"
                                name="newPassword"
                                rules={[{
                                    required: true,
                                    message: 'Please enter your new password!'
                                }]}
                            >
                                <Input.Password/>
                            </Form.Item>
                            <Form.Item
                                label="Nhập lại mật khẩu mới"
                                name="confirmPassword"
                                rules={
                                    [
                                        {
                                            required: true,
                                            message: 'Please confirm your password!'
                                        },
                                        ({getFieldValue}) => ({
                                            validator(rule, value) {
                                                if (!value || getFieldValue('newPassword') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject('Password not matched!');
                                            },
                                        }),
                                    ]
                                }
                            >
                                <Input.Password/>
                            </Form.Item>
                            <Button type="primary" htmlType="submit">
                                Đổi mật khẩu
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </>
        )
    }
}


const mapDispatchToProps = (dispatch) => ({
    updatePassword: (newPassword) => dispatch(changePassword(newPassword))
});

export default connect(null, mapDispatchToProps)(ChangePassword)

