import React, { Component, useEffect } from 'react';
import {
    Row,
    Col,
    Card,
    Table,
    Button,
    Tooltip,
    Popconfirm,
    Form,
    Input,
    Modal,
    message
} from 'antd';
import {
    EditOutlined,
    FileAddOutlined,
    DeleteOutlined
} from '@ant-design/icons';
import ServiceGroupService from "services/ServiceGroupService";
import Utils from "utils";

const { TextArea } = Input;

const ServicePopup = ({ visible, onCreate, onCancel, initialValues = null, confirmLoading = false, isEditing = false }) => {
    const [form] = Form.useForm();
    
    useEffect(() => {
        if (initialValues) {
            form.setFieldsValue(initialValues);
        } else {
            form.resetFields();
        }
    }, [form, initialValues]);

    const okText = isEditing ? 'Sửa' : 'Thêm';
    const title = isEditing ? 'Sửa dịch vụ' : 'Thêm mới dịch vụ';
    return (
        <Modal 
            forceRender
            confirmLoading={confirmLoading}
            visible={visible}
            title={title}
            okText={okText}
            cancelText="Hủy"
            onCancel={onCancel}
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                        onCreate({
                            ...initialValues,
                            ...values
                        });
                    })
                    .catch((info) => {
                        console.log(info);
                    });
            }}>
            <Form
                form={form}
                layout="vertical"
                name="serviceForm"
                initialValues={initialValues}
            >
                <Form.Item
                    label="Tên nhóm dịch vụ"
                    name="name"
                    rules={[{ required: true, message: 'Cú pháp không được để trống' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Mô tả nhóm dịch vụ"
                    name="description"
                    rules={[{
                        required: true,
                        message: 'Mô tả dịch vụ không được để trống'
                    }]}
                >
                    <TextArea />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export class ServiceGroup extends Component {
    state = {
        serviceGroups: [],
        loading: false,
        visible: false,
        confirmLoading: false,
        initialValues: {},
        isEditing: false,
        title: '',
        pagination: {
            current: 1,
            pageSize: 10,
        },
        filters: {}
    };
    columns = [
        {
            title: 'STT',
            dataIndex: 'id',
            key: 'id',
            render: (text, record, index) => {
                const { current, pageSize } = this.state.pagination;

                return ((current - 1) * pageSize) + index + 1
            },
        },
        {
            title: 'Tên nhóm dịch vụ',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Mô tả nhóm dịch vụ',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Cập nhật',
            dataIndex: 'update',
            key: 'update',
            render: (_, record) => (
                <>
                    <Tooltip title="Sửa">
                        <Button icon={<EditOutlined />} size="small" onClick={() => this.handleEdit(record)} />
                    </Tooltip>
                </>
            )
        },
        {
            title: 'Xóa',
            dataIndex: 'delete',
            key: 'delete',
            render: (_, record) => (
                <>
                    <Popconfirm onConfirm={() => this.handleDelete(record)} title="Bạn muốn xóa cú pháp này phải không?">
                        <Tooltip title="Xóa">
                            <Button danger icon={<DeleteOutlined />} size="small" />
                        </Tooltip>
                    </Popconfirm>
                </>
            )
        },
    ];

    constructor(props) {
        super(props);

        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadData();
        this.getTotalCount();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getTotalCount = async () => {
        const { filters } = this.state;
        const total = await ServiceGroupService.count(filters);
        const { pagination } = this.state;
        this._isMounted && this.setState({
            pagination: {
                ...pagination,
                total
            }
        })
    }

    loadData = async (params) => {
        this.setState({
            loading: true
        })
        const { filters } = this.state;
        const __pagination = Utils.getPaginationParams(params);
        const __params = Object.assign(filters, __pagination);
        const serviceGroups = await ServiceGroupService.read(__params);
        const { pagination } = this.state;
        this._isMounted && this.setState({
            loading: false,
            serviceGroups,
            pagination: {
                ...pagination,
                ...params
            }
        });
    }

    handleAdd = () => {
        this.setState({
            initialValues: null,
            isEditing: false,
            visible: true,
            title: 'Thêm dịch vụ'
        })
    }
    handleCreate = async (service) => {
        try {
            await ServiceGroupService.create(service);
            await this.loadData();
            this.hideModal();
            message.success({ content: 'Thêm mới thành công', duration: 2 });
        } catch (error) {
            this.hideModal();
            message.error({ content: 'Thêm mới không thành công', duration: 2 })
        }
    }
    handleEdit = (service) => {
        this.setState({
            initialValues: service,
            isEditing: true,
            visible: true,
            title: 'Sửa cú pháp'
        })
    }
    handleUpdate = async (data) => {
        try {
            const { serviceGroups } = this.state;
            await ServiceGroupService.update(data);
            const foundIndex = serviceGroups.findIndex(s => s.id === data.id);
            if (foundIndex !== -1) {
                serviceGroups[foundIndex] = {
                    ...serviceGroups[foundIndex],
                    ...data
                }
            }
            this.setState({
                serviceGroups: [...serviceGroups],
                visible: false,
                isEditing: false,
            })
            this.hideModal();
            message.success({ content: 'Sửa thành công', duration: 2 });
        } catch (error) {
            message.error({ content: 'Sửa không thành công', duration: 2 })
            this.hideModal();
        }
    }
    handleDelete = async (data) => {
        try {
            await ServiceGroupService.delete(data.id);
            const { serviceGroups } = this.state;
            this._isMounted && this.setState({
                serviceGroups: serviceGroups.filter(s => s.id !== data.id)
            })
            message.success({ content: 'Xóa thành công', duration: 2 });
        } catch (error) {
            message.error({ content: 'Xóa không thành công', duration: 2 })
        }
    }
    hideModal = () => {
        this.setState({ visible: false, confirmLoading: false })
    }
    onCancel = () => {
        this.hideModal();
    }

    onFinish = async (values) => {
        let filters = {};
        if (values.service !== undefined) {
            filters['name_contains'] = values.service;
        }
        this.setState({
            filters,
            pagination: {
                current: 1,
                pageSize: 10,
            },
        })
        await this.getTotalCount();
        this.loadData();
    }
    handleChange = (pagination) => {
        this.loadData(pagination)
    }
    render() {
        const { visible, isEditing, confirmLoading, initialValues, loading, serviceGroups, pagination } = this.state;
        const onCreate = isEditing ? this.handleUpdate : this.handleCreate;
        return (
            <>
                <ServicePopup
                    confirmLoading={confirmLoading}
                    visible={visible}
                    isEditing={isEditing}
                    initialValues={initialValues}
                    onCancel={this.onCancel}
                    onCreate={onCreate} />
                <Row>
                    <Col xs={24}>
                        <Card title="Danh sách nhóm dịch vụ">
                            <Button onClick={() => this.handleAdd()} type="primary"
                                style={{ marginBottom: 16, borderRadius: '5px', float: 'right' }}
                                icon={<FileAddOutlined />}>
                                Thêm mới dịch vụ
                            </Button>
                            <Table
                                columns={this.columns}
                                dataSource={serviceGroups}
                                onChange={this.handleChange}
                                pagination={pagination}
                                loading={loading}
                                rowKey={record => record.id}
                            />
                        </Card>
                    </Col>
                </Row>
            </>
        );
    }
}

export default ServiceGroup