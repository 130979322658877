import fetch from 'interceptors/FetchInterceptor';
const ENDPOINT = '/collections/tools';

class ToolsService {
    async create(content){
        const response = await fetch({
            url: ENDPOINT,
            method: 'POST',
            data: content,
        })
        console.log(response);
        return response;
    }
    async read(){
        const response = await fetch({
            url: ENDPOINT,
            method: 'GET',
        })
        return response;
    }
    async update(content){

        const response = await fetch({
            url: `${ENDPOINT}/${content.id}`,
            method: 'PUT',
            data: content
        })

        return response;
    }
    async delete(id){
        const response = await fetch({
            url: `${ENDPOINT}/${id}`,
            method: 'DELETE',
        })
        return response;
    }
}
export default new ToolsService();