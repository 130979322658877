import fetch from 'interceptors/FetchInterceptor';

const GROUP_USER_API = '/groups';


class GroupUserService {
    async create(user) {
        const response = await fetch({
            url: "/users",
            method: 'post',
            data: user
        });
        return response;
    }

    async read() {
        const response = await fetch({
            url: GROUP_USER_API,
            method: 'get'
        });
        
        return response;
    }


    async update(user) {
        const response = await fetch({
            url: "/users/" + user.id,
            method: 'put',
            data: user
        });
        
        return response;
    }

    async delete(idUser) {
        const response = await fetch({
            url: "/users/" + idUser,
            method: 'delete'
        });
        
        return response;
    }
    //Group
    async readGroupAll() {
        const response = await fetch({
            url: GROUP_USER_API,
            method: 'get'
        });
        
        return response;
    }
    async readGroupByID(id) {
        const response = await fetch({
            url: GROUP_USER_API + "/" + id,
            method: 'get'
        });
        
        return response;
    }

    async createGroupByID(group) {
        const response = await fetch({
            url: GROUP_USER_API + "/" + group.group_id,
            method: 'post',
            data: group
        });
        
        return response;
    }

    async getListUserByGroup(id) {
        const response = fetch({
            url: `/users?group_id=${id}`,
            method: 'GET'
        })
        console.log(response)
        return response;
    }
}


export default new GroupUserService();
