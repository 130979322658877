import fetch from 'interceptors/FetchInterceptor';


const IAM_API_URL = '/auth';

class AuthService {
  async login(data) {
    const response = await fetch({
      url: IAM_API_URL + '/login',
      method: 'post',
      data
    });
    return response.data;
  }

  logout() {

  }

  checkAuth() {

  }
  checkError() {

  }

  getPermissions() {
  }
}

export default new AuthService();
