import React, { Component, useEffect } from 'react';
import {
    Row,
    Col,
    Card,
    Table,
    Button,
    Tooltip,
    Popconfirm,
    Form,
    Input,
    Modal,
    message,
    Select
} from 'antd';
import {
    EditOutlined,
    FileAddOutlined,
    DeleteOutlined
} from '@ant-design/icons';
import ServiceService from "services/ServiceService";
import moment from 'moment';
import Utils from "utils";
import ServiceGroupService from 'services/ServiceGroupService';

const { TextArea } = Input;

const ServicePopup = ({ visible = false, onCreate, onCancel, initialValues = null, confirmLoading = false, isEditing = false, serviceGroups = [] }) => {
    const [form] = Form.useForm();

    useEffect(() => {
        if (initialValues) {
            form.setFieldsValue(initialValues);
        } else {
            form.resetFields();
        }
    }, [form, initialValues]);

    const okText = isEditing ? 'Sửa' : 'Thêm';
    const title = isEditing ? 'Sửa dịch vụ' : 'Thêm mới dịch vụ';
    return (
        <Modal 
            forceRender
            confirmLoading={confirmLoading}
            visible={visible}
            title={title}
            okText={okText}
            cancelText="Hủy"
            onCancel={onCancel}
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                        onCreate({
                            ...initialValues,
                            ...values
                        });
                    })
                    .catch((info) => {
                        console.log(info);
                    });
            }}>
            <Form
                form={form}
                layout="vertical"
                name="serviceForm"
                initialValues={initialValues}
            >
                <Form.Item
                    label="Tên dịch vụ"
                    name="name"
                    rules={[{ required: true, message: 'Cú pháp không được để trống' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Nhóm dịch vụ"
                    name="service_group"
                    rules={[{ required: true, message: 'Cú pháp không được để trống' }]}
                >
                    <Select
                        placeholder="Chọn nhóm dịch vụ"
                        filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                        {
                            serviceGroups && serviceGroups.map(s => <Select.Option key={s.id} value={s.id}>{s.name}</Select.Option>)
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Mô tả dịch vụ"
                    name="description"
                    rules={[{
                        required: true,
                        message: 'Mô tả dịch vụ không được để trống'
                    }]}
                >
                    <TextArea />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export class Service extends Component {
    state = {
        services: [],
        loading: false,
        visible: false,
        confirmLoading: false,

        serviceGroups: [],
        serviceGroupsLoading: false,
        initialValues: {},
        isEditing: false,
        title: '',
        pagination: {
            current: 1,
            pageSize: 10,
        },
        filters: {}
    };

    columns = [
        {
            title: 'STT',
            dataIndex: 'id',
            key: 'id',
            render: (text, record, index) => {
                const { current, pageSize } = this.state.pagination;

                return ((current - 1) * pageSize) + index + 1
            },
        },
        {
            title: 'Tên dịch vụ',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Mô tả',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Nhóm dịch vụ',
            dataIndex: ['service_group', 'name']
        },
        {
            title: 'Ngày tạo',
            dataIndex: 'published_at',
            key: 'published_at',
            render: (published_at) => moment(published_at).format("YYYY-MM-DD")
        },
        {
            title: 'Cập nhật',
            dataIndex: 'update',
            key: 'update',
            render: (_, record) => (
                <>
                    <Tooltip title="Sửa">
                        <Button icon={<EditOutlined />} size="small" onClick={() => this.handleEdit(record)} />
                    </Tooltip>
                </>
            )
        },
        {
            title: 'Xóa',
            dataIndex: 'delete',
            key: 'delete',
            render: (_, record) => (
                <>
                    <Popconfirm onConfirm={() => this.handleDelete(record)} title="Bạn muốn xóa cú pháp này phải không?">
                        <Tooltip title="Xóa">
                            <Button danger icon={<DeleteOutlined />} size="small" />
                        </Tooltip>
                    </Popconfirm>
                </>
            )
        },
    ];

    constructor(props) {
        super(props);

        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadData();
        this.getTotalCount();
        this.loadServiceGroups();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getTotalCount = async () => {
        const { filters } = this.state;
        const total = await ServiceService.count(filters);
        const { pagination } = this.state;
        this._isMounted && this.setState({
            pagination: {
                ...pagination,
                total
            }
        })
    }

    loadData = async (params) => {
        this.setState({
            loading: true
        })
        const { filters } = this.state;
        const __pagination = Utils.getPaginationParams(params);
        const __params = Object.assign(filters, __pagination);
        const services = await ServiceService.read(__params);
        const { pagination } = this.state;
        this._isMounted && this.setState({
            loading: false,
            services,
            pagination: {
                ...pagination,
                ...params
            }
        });
    }

    loadServiceGroups = async () => {
        this.setState({
            serviceGroupsLoading: true
        })

        const serviceGroups = await ServiceGroupService.read();
        this._isMounted && this.setState({
            serviceGroups,
            serviceGroupsLoading: false
        })
    }

    handleAdd = () => {
        this.setState({
            initialValues: null,
            isEditing: false,
            visible: true,
            title: 'Thêm dịch vụ'
        })
    }

    handleCreate = async (service) => {
        try {
            await ServiceService.create(service);
            await this.loadData();
            this.hideModal();
            message.success({ content: 'Thêm mới thành công', duration: 2 });
        } catch (error) {
            this.hideModal();
            message.error({ content: 'Thêm mới không thành công', duration: 2 })
        }
    }

    handleEdit = (service) => {
        this.setState({
            initialValues: {
                ...service,
                service_group: service.service_group.id
            },
            isEditing: true,
            visible: true,
            title: 'Sửa cú pháp'
        })
    }

    handleUpdate = async (service) => {
        try {
            const { services } = this.state;
            const newService = await ServiceService.update(service);
            const foundIndex = services.findIndex(s => s.id === service.id);
            if (foundIndex !== -1) {
                services[foundIndex] = {
                    ...services[foundIndex],
                    ...newService
                }
            }
            this.setState({
                services: [...services],
                visible: false,
                isEditing: false,
            })
            this.hideModal();
            message.success({ content: 'Sửa thành công', duration: 2 });
        } catch (error) {
            message.error({ content: 'Sửa không thành công', duration: 2 })
            this.hideModal();
        }
    }

    handleDelete = async (service) => {
        try {
            await ServiceService.delete(service.id);
            const { services } = this.state;
            this._isMounted && this.setState({
                services: services.filter(s => s.id !== service.id)
            })
            message.success({ content: 'Xóa thành công', duration: 2 });
        } catch (error) {
            message.error({ content: 'Xóa không thành công', duration: 2 })
        }
    }

    hideModal = () => {
        this.setState({ visible: false, confirmLoading: false })
    }

    onCancel = () => {
        this.hideModal();
    }

    onFinish = async (values) => {
        let filters = {};
        if (values.service !== undefined) {
            filters['name_contains'] = values.service;
        }
        this.setState({
            filters,
            pagination: {
                current: 1,
                pageSize: 10,
            },
        })
        await this.getTotalCount();
        this.loadData();
    }

    handleChange = (pagination) => {
        this.loadData(pagination)
    }

    render() {
        const { visible, isEditing, confirmLoading, initialValues, loading, services, pagination, serviceGroups, serviceGroupsLoading } = this.state;
        const onCreate = isEditing ? this.handleUpdate : this.handleCreate;
        return (
            <>
                <Form layout="vertical" onFinish={this.onFinish}>
                    <Row style={{ marginBottom: '15px' }} gutter={[24, 24]}>
                        <Col md={6} sm={24} xs={24}>
                            <Form.Item label="Dịch vụ" name="service">
                                <Input name="service" style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col md={6} sm={24} xs={24}>
                            <Form.Item style={{ float: 'left', marginLeft: '5px', marginTop: '20px' }}>
                                <Button type="primary" htmlType="submit" style={{ float: 'right' }}>Lọc</Button>
                            </Form.Item>
                        </Col>
                        <Col md={6} sm={24} xs={24}>
                        </Col>
                    </Row>
                </Form>
                <ServicePopup
                    confirmLoading={confirmLoading}
                    serviceGroups={serviceGroups}
                    serviceGroupsLoading={serviceGroupsLoading}
                    services={services}
                    visible={visible}
                    isEditing={isEditing}
                    initialValues={initialValues}
                    onCancel={this.onCancel}
                    onCreate={onCreate} />
                <Row>
                    <Col xs={24}>
                        <Card title="Danh sách dịch vụ">
                            <Button onClick={() => this.handleAdd()} type="primary"
                                style={{ marginBottom: 16, borderRadius: '5px', float: 'right' }}
                                icon={<FileAddOutlined />}>
                                Thêm mới dịch vụ
                            </Button>
                            <Table
                                columns={this.columns}
                                dataSource={services}
                                onChange={this.handleChange}
                                pagination={pagination}
                                loading={loading}
                                rowKey={record => record.id}
                            />
                        </Card>
                    </Col>
                </Row>
            </>
        );
    }
}

export default Service;
