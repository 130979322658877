import fetch from 'interceptors/FetchInterceptor';

const REVENUE_URL_API = '/collections/reports';

class RevenueReportService {
    async getOverview(params) {
        const response = await fetch({
            url: REVENUE_URL_API + '/total-widget-revenue',
            method: 'get',
            params
        });
        return response;
    }

    async getRevenue(params) {
        const response = await fetch({
            url: REVENUE_URL_API + '/revenue',
            method: 'get',
            params
        });
        return response;
    }

    async export(params) {
        const response = await fetch({
            url: REVENUE_URL_API + '/export-revenue',
            method: 'get',
            params
        });
        return response;
    }
}

export default new RevenueReportService();