import React, { Component } from 'react';
import { Form, Avatar, Button, Input, DatePicker, Row, Col, message, Upload, Spin } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import Flex from 'components/shared-components/Flex'
import { connect } from "react-redux";
import { loadUserProfile, updateUserProfile } from 'redux/actions/User'
import moment from 'moment';

const UserAvatar = ({ avatarUrl, onRemoveAvater }) => {

  const avatarEndpoint = 'https://www.mocky.io/v2/5cc8019d300000980a055e76'

  // const getBase64 = (img, callback) => {
  //   const reader = new FileReader();
  //   reader.addEventListener('load', () => callback(reader.result));
  //   reader.readAsDataURL(img);
  // }

  const onUploadAavater = info => {
    const key = 'updatable';
    if (info.file.status === 'uploading') {
      message.loading({ content: 'Uploading...', key, duration: 1000 });
      return;
    }
    if (info.file.status === 'done') {
      // getBase64(info.file.originFileObj, imageUrl =>
      //   this.setState({
      //     avatarUrl: imageUrl,
      //   }),
      // );
      message.success({ content: 'Uploaded!', key, duration: 1.5 });
    }
  };


  return (
    <Flex alignItems="center" mobileFlex={false} className="text-center text-md-left">
      <Avatar size={90} src={avatarUrl} icon={<UserOutlined />} />
      <div className="ml-3 mt-md-0 mt-3">
        <Upload onChange={onUploadAavater} showUploadList={false} action={avatarEndpoint}>
          <Button type="primary">Đổi ảnh đại diện</Button>
        </Upload>
        <Button className="ml-2" onClick={onRemoveAvater}>Xóa</Button>
      </div>
    </Flex>
  );
}

export class EditProfile extends Component {

  componentDidMount() {
    const { loading, loadUserProfile } = this.props;
    if (!loading) loadUserProfile();
  }

  componentDidUpdate() {

  }

  onFinish = (values) => {
    const key = 'updatable';
    message.loading({ content: 'Updating...', key });
    let _newProfile = {
      "firstName": values.firstName,
      "lastName": values.lastName,
      "attributes": {}

    }
    if (values.phone !== undefined) _newProfile.attributes["phone"] = values.phone;
    if (values.dateOfBirth !== undefined) _newProfile.attributes["dateOfBirth"] = values.dateOfBirth._d.toLocaleDateString('en-GB');
    if (values.address !== undefined) _newProfile.attributes["address"] = values.address;
    this.props.updateUserProfile(_newProfile)
  }

  onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  }

  onRemoveAvater = () => {
    this.setState({
      avatarUrl: ''
    })
  }

  render() {
    const { currentUser } = this.props;
    if (!currentUser) {
      return <Spin />;
    }
    return (
      <>
        <UserAvatar
          avatarUrl={currentUser.avatarUrl}
          onRemoveAvater={this.onRemoveAvater}
        />
        <div className="mt-4">
          <Form
            name="basicInformation"
            layout="vertical"
            initialValues={currentUser}
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
          >
            <Row>
              <Col xs={24} sm={24} md={24} lg={16}>
                <Row gutter={ROW_GUTTER}>
                  <Col xs={24} sm={24} md={12}>
                    <Form.Item
                      label="Tên"
                      name="firstName"
                      rules={[
                        {
                          required: true,
                          message: 'Please input your name!',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12}>
                    <Form.Item
                      label="Họ"
                      name="lastName"
                      rules={[
                        {
                          required: true,
                          message: 'Please input your username!'
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12}>
                    <Form.Item
                      label="Email"
                      name="email"
                      rules={[{
                        required: true,
                        type: 'email',
                        message: 'Please enter a valid email!'
                      }]}
                    >
                      <Input disabled={true} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12}>
                    <Form.Item
                      label="Sinh nhật"
                      name="dateOfBirth"
                      initialValue={moment((currentUser.hasOwnProperty('attributes')) ? currentUser.attributes.dateOfBirth[0] : null, 'DD/MM/YYYY')}
                    >
                      <DatePicker format={'DD/MM/YYYY'} className="w-100" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12}>
                    <Form.Item
                      label="Số điện thoại"
                      name="phone"
                      initialValue={(currentUser.hasOwnProperty('attributes')) ? currentUser.attributes.phone[0] : null}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24}>
                    <Form.Item
                      label="Địa chỉ"
                      name="address"
                      initialValue={(currentUser.hasOwnProperty('attributes')) ? currentUser.attributes.address[0] : null}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Button type="primary" htmlType="submit">Cập nhật</Button>
              </Col>
            </Row>
          </Form>
        </div>
      </>
    )
  }
}

const mapStateToProps = ({ user }) => {
  const { currentUser, loading } = user;

  return { currentUser, loading }
};
const mapDispatchToProps = {
  loadUserProfile,
  updateUserProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile)
