import fetch from 'interceptors/FetchInterceptor';

const SUBSCRIPTION_DETAIL_URL_API = '/collections/customers/detail';
const COMMENT_URL_API = '/collections/notes';

class SubscriptionDetailService {
    async getOverview(params) {
        const response = await fetch({
            url: SUBSCRIPTION_DETAIL_URL_API + '/total-widget',
            method: 'get',
            params
        });
        return response;
    }

    async getTimeline(params) {
        const response = await fetch({
            url: SUBSCRIPTION_DETAIL_URL_API + '/action-logs-timeline',
            method: 'get',
            params
        });
        return response;
    }


    async getActiveLogs(params) {
        const response = await fetch({
            url: SUBSCRIPTION_DETAIL_URL_API + '/action-logs',
            method: 'get',
            params
        });
        return response;
    }

    async getActiveLogChart(params) {
        const response = await fetch({
            url: SUBSCRIPTION_DETAIL_URL_API + '/action-logs-chart',
            method: 'get',
            params
        });
        return response;
    }

    async postComment(data) {
        const response = await fetch({
            url: COMMENT_URL_API,
            method: 'post',
            data
        });
        return response;
    }
}

export default new SubscriptionDetailService();