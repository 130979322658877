import fetch from 'interceptors/FetchInterceptor';
const TELCO_API_ENDPOINT = '/collections/telcos';
class TelcoService {
    async create(telco){
        const response = await fetch({
            url: TELCO_API_ENDPOINT,
            method: 'POST',
            data: telco,
        })
        console.log(response);
        return response;
    }
    async read(params){
        const response = await fetch({
            url: `${TELCO_API_ENDPOINT}`,
            method: 'GET',
            params
        })
        return response;
    }
    async count(params){
        const response = await fetch({
            url: `${TELCO_API_ENDPOINT}/count`,
            method: 'GET',
            params

        })
        return response;
    }
    async update(telco){
        const response = await fetch({
            url: `${TELCO_API_ENDPOINT}/${telco.id}`,
            method: 'PUT',
            data: telco
        })
        return response;
    }
    async delete(id){
        const response = await fetch({
            url: `${TELCO_API_ENDPOINT}/${id}`,
            method: 'DELETE',
        })
        return response;
    }
}
export default new TelcoService();