import fetch from 'interceptors/FetchInterceptor';

const PACKAGE_GROUP_PREFIX_API_URL = '/collections/package-groups';

class PackageGroupService {
    static async read(params) {
        const response = await fetch({
            url: PACKAGE_GROUP_PREFIX_API_URL,
            method: 'get',
            params
        });
        return response;
    }

    static async create(item) {
        const response = await fetch({
            url: PACKAGE_GROUP_PREFIX_API_URL,
            method: 'post',
            data: item,
        });
        return response;
    }

    static async update(item) {
        const response = await fetch({
            url: PACKAGE_GROUP_PREFIX_API_URL + '/' + item.id,
            method: 'put',
            data: item,
        });
        return response;
    }

    static async delete(item) {
        const response = await fetch({
            url: PACKAGE_GROUP_PREFIX_API_URL + '/' + item.id,
            method: 'delete',
        });
        return response;
    }

    static async count(params){
        const response = await fetch({
            url: PACKAGE_GROUP_PREFIX_API_URL + '/count',
            method: 'GET',
            params
        })
        return response;
    }
}

export default PackageGroupService;
