import React, { Component } from 'react';
import { Card, Col, Row } from "antd";
import {
    MobileOutlined,
    CustomerServiceOutlined,
    PercentageOutlined,
    ShoppingCartOutlined,
} from "@ant-design/icons";
import NumberFormat from 'react-number-format';

class Statistic extends Component {
    render() {
        const statistic = this.props.statistic;
        return (
            <Row gutter={[24, 24]}>
                <Col md={6} sm={12} xs={24}>
                    <Card>
                        <div className="media ant-align-items-center">
                            <div className="ant-avatar ant-avatar-icon ant-avatar-lg ant-avatar-purple">
                                <ShoppingCartOutlined />
                            </div>
                            <div className="m-l-15">
                                <h2 className="m-b-0">
                                    <NumberFormat value={statistic.lifetime_value_total} displayType="text" thousandSeparator={true} />
                                </h2>
                                <p className="m-b-0 text-muted">Lifetime Value</p>
                            </div>
                        </div>
                    </Card>
                </Col>
                <Col md={6} sm={12} xs={24}>
                    <Card>
                        <div className="media ant-align-items-center">
                            <div className="ant-avatar ant-avatar-icon ant-avatar-lg ant-avatar-blue">
                                <MobileOutlined />
                            </div>
                            <div className="m-l-15">
                                <h2 className="m-b-0">
                                    <NumberFormat value={statistic.service_total} displayType="text" thousandSeparator={true} />
                                </h2>
                                <p className="m-b-0 text-muted">Dịch vụ</p>
                            </div>
                        </div>
                    </Card>
                </Col>
                <Col md={6} sm={12} xs={24}>
                    <Card>
                        <div className="media ant-align-items-center">
                            <div className="ant-avatar ant-avatar-icon ant-avatar-lg ant-avatar-cyan">
                                <PercentageOutlined />
                            </div>
                            <div className="m-l-15">
                                <h2 className="m-b-0">
                                    <NumberFormat value={statistic.charge_ratio} displayType="text" thousandSeparator={true} />
                                </h2>
                                <p className="m-b-0 text-muted">Charge Cước</p>
                            </div>
                        </div>
                    </Card>
                </Col>
                <Col md={6} sm={12} xs={24}>
                    <Card>
                        <div className="media ant-align-items-center">
                            <div className="ant-avatar ant-avatar-icon ant-avatar-lg ant-avatar-gold">
                                <CustomerServiceOutlined />
                            </div>
                            <div className="m-l-15">
                                <h2 className="m-b-0">
                                    <NumberFormat value={statistic.call_total} displayType="text" thousandSeparator={true} />
                                </h2>
                                <p className="m-b-0 text-muted">Số cuộc gọi</p>
                            </div>
                        </div>
                    </Card>
                </Col>
            </Row>
        )
    }
}

export default Statistic;