import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE, DIR_LTR } from 'constants/ThemeConstant';

export const APP_NAME = 'AMS - CMS';
export const API_BASE_URL = 'https://kong.dbiz.vn/ams' // 'https://kong.dbiz.vn';
export const APP_PREFIX_PATH = '/app';
export const AUTH_PREFIX_PATH = '/authentication';
export const PATTERN_PHONE = /(^(09|03|07|08|05)+([0-9]{8}$))|(^(84)+([0-9]{9}$))/;


export const ENTRY_ROUTE = '/authentication/login'
export const TOKEN_PAYLOAD_KEY = 'authorization';
export const PUBLIC_REQUEST_KEY = 'public-request';

export const ERROR_ROUTE_1 = '/authentication/error-1';
export const ERROR_ROUTE_2 = '/authentication/error-2';

export const THEME_CONFIG = {
	navCollapsed: false,
	sideNavTheme: SIDE_NAV_LIGHT,
	locale: 'vi',
	navType: NAV_TYPE_SIDE,
	topNavColor: '#3e82f7',
	headerNavColor: '',
	mobileNav: false,
	currentTheme: 'light',
	direction: DIR_LTR
};
