import fetch from 'interceptors/FetchInterceptor';

const SERVICE_GROUP_API_ENDPOINT = '/collections/service-groups';

class ServiceGroupService {

    static async create(data) {
        const response = await fetch({
            url: SERVICE_GROUP_API_ENDPOINT,
            method: 'POST',
            data
        })
        return response;
    }

    static async read(params) {
        const response = await fetch({
            url: `${SERVICE_GROUP_API_ENDPOINT}`,
            method: 'GET',
            params
        })
        return response;
    }

    static async count(params) {
        const response = await fetch({
            url: `${SERVICE_GROUP_API_ENDPOINT}/count`,
            method: 'GET',
            params
        })
        return response;
    }

    static async update(data) {
        const response = await fetch({
            url: `${SERVICE_GROUP_API_ENDPOINT}/${data.id}`,
            method: 'PUT',
            data
        })
        return response;
    }

    static async delete(id) {
        const response = await fetch({
            url: `${SERVICE_GROUP_API_ENDPOINT}/${id}`,
            method: 'DELETE',
        })
        return response;
    }
}
export default ServiceGroupService;