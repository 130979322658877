import {
    DashboardOutlined,
    BarChartOutlined,
    CustomerServiceOutlined,
    DollarOutlined,
    FileTextOutlined,
    OrderedListOutlined,
    SettingOutlined,
    LinkOutlined
} from '@ant-design/icons';
import {APP_PREFIX_PATH} from 'configs/AppConfig'

const dashBoardNavTree = [{
    key: 'Dashboard',
    path: `${APP_PREFIX_PATH}/dashboard`,
    title: 'dashboard',
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: []
}]

const revenueReportsNavTree = [{
    key: 'revenueReports',
    path: `${APP_PREFIX_PATH}/bao-cao-doanh-thu`,
    title: 'sidenav.revenueReports',
    icon: BarChartOutlined,
    breadcrumb: false,
    submenu: []
}]

const customerServiceNavTree = [{
    key: 'customerService',
    path: `${APP_PREFIX_PATH}/cham-soc-khach-hang`,
    title: 'sidenav.customerService',
    icon: CustomerServiceOutlined,
    breadcrumb: false,
    submenu: [
        // {
        //   key: 'customerService-incomingCall',
        //   path: `${APP_PREFIX_PATH}/cham-soc-khach-hang/cuoc-goi-den`,
        //   title: 'sidenav.customerService.incomingCall',
        //   icon: '',
        //   breadcrumb: false,
        //   submenu: []
        // },
        {
            key: 'customerService-subscriptionInformation',
            path: `${APP_PREFIX_PATH}/cham-soc-khach-hang/thong-tin-thue-bao`,
            title: 'sidenav.customerService.subscriptionInformation',
            icon: '',
            breadcrumb: false,
            submenu: []
        },
        {
            key: 'customerService-mt',
            path: `${APP_PREFIX_PATH}/cham-soc-khach-hang/lich-su-mt`,
            title: 'sidenav.customerService.mt',
            icon: '',
            breadcrumb: false,
            submenu: []
        },
        {
            key: 'customerService-mo',
            path: `${APP_PREFIX_PATH}/cham-soc-khach-hang/lich-su-mo`,
            title: 'sidenav.customerService.mo',
            icon: '',
            breadcrumb: false,
            submenu: []
        },
        {
            key: 'customerService-history',
            path: `${APP_PREFIX_PATH}/cham-soc-khach-hang/lich-su-dang-ky`,
            title: 'sidenav.customerService.history',
            icon: '',
            breadcrumb: false,
            submenu: []
        },
        {
            key: 'customerService-history-cskh',
            path: `${APP_PREFIX_PATH}/cham-soc-khach-hang/lich-su-cskh`,
            title: 'sidenav.customerService.history-cskh',
            icon: '',
            breadcrumb: false,
            submenu: []
        },
    ]
}]

const partnerNavTree = [{
    key: 'generalManagement',
    path: `${APP_PREFIX_PATH}/quan-ly-chung`,
    title: 'sidenav.generalManagement',
    icon: SettingOutlined,
    breadcrumb: false,
    submenu: [
        {
            key: 'general-management-partner',
            path: `${APP_PREFIX_PATH}/quan-ly-chung/doi-tac`,
            title: 'sidenav.generalManagement.partner',
            icon: '',
            breadcrumb: false,
            submenu: []
        },
        {
            key: 'general-management-homeNetwork',
            path: `${APP_PREFIX_PATH}/quan-ly-chung/nha-mang`,
            title: 'sidenav.generalManagement.homeNetwork',
            icon: '',
            breadcrumb: false,
            submenu: []
        },
    ]
}]

const revenueNavTree = [{
    key: 'revenueManagement',
    path: `${APP_PREFIX_PATH}/for-control`,
    title: 'sidenav.revenueManagement',
    icon: DollarOutlined,
    breadcrumb: false,
    submenu: [
        {
            key: 'revenue-management-partner',
            path: `${APP_PREFIX_PATH}/for-control/partner`,
            title: 'sidenav.revenueManagement.partner',
            icon: '',
            breadcrumb: false,
            submenu: []
        },
        {
            key: 'revenue-management-homeNetwork',
            path: `${APP_PREFIX_PATH}/for-control/home-network`,
            title: 'sidenav.revenueManagement.homeNetwork',
            icon: '',
            breadcrumb: false,
            submenu: []
        },
    ]
}]

const contentManagementNavTree = [{
    key: 'contentManagement',
    path: `${APP_PREFIX_PATH}/quan-ly-noi-dung`,
    title: 'sidenav.contentManagement',
    icon: FileTextOutlined,
    breadcrumb: false,
    submenu: [
        {
            key: 'contentManagement-list',
            path: `${APP_PREFIX_PATH}/quan-ly-noi-dung/danh-sach-noi-dung`,
            title: 'sidenav.contentManagement.list',
            icon: '',
            breadcrumb: false,
            submenu: []
        },
    ]
}]

const serviceManagementNavTree = [{
    key: 'serviceManagement',
    path: `${APP_PREFIX_PATH}/quan-ly-dich-vu`,
    title: 'sidenav.serviceManagement',
    icon: OrderedListOutlined,
    breadcrumb: false,
    submenu: [
        {
            key: 'serviceManagement-services',
            path: `${APP_PREFIX_PATH}/quan-ly-dich-vu/dich-vu`,
            title: 'sidenav.serviceManagement.services',
            icon: '',
            breadcrumb: false,
            submenu: []
        },
        {
            key: 'serviceManagement-service-group',
            path: `${APP_PREFIX_PATH}/quan-ly-dich-vu/nhom-dich-vu`,
            title: 'sidenav.serviceManagement.serviceGroup',
            icon: '',
            breadcrumb: false,
            submenu: []
        },
        {
            key: 'serviceManagement-package',
            path: `${APP_PREFIX_PATH}/quan-ly-dich-vu/goi-cuoc`,
            title: 'sidenav.serviceManagement.package',
            icon: '',
            breadcrumb: false,
            submenu: []
        },
        {
            key: 'serviceManagement-package-group',
            path: `${APP_PREFIX_PATH}/quan-ly-dich-vu/nhom-goi-cuoc`,
            title: 'sidenav.serviceManagement.packageGroup',
            icon: '',
            breadcrumb: false,
            submenu: []
        },
        {
            key: 'serviceManagement-syntax',
            path: `${APP_PREFIX_PATH}/quan-ly-dich-vu/cu-phap`,
            title: 'sidenav.serviceManagement.syntax',
            icon: '',
            breadcrumb: false,
            submenu: []
        },
    ]
}]

const settingsNavTree = [{
    key: 'settings',
    path: `${APP_PREFIX_PATH}/thiet-lap`,
    title: 'sidenav.settings',
    icon: SettingOutlined,
    breadcrumb: false,
    submenu: [
        {
            key: 'settings-user',
            path: `${APP_PREFIX_PATH}/thiet-lap/nguoi-dung`,
            title: 'sidenav.settings.user',
            icon: '',
            breadcrumb: false,
            submenu: []
        },
        {
            key: 'settings-benefit-rates-management',
            path: `${APP_PREFIX_PATH}/thiet-lap/quan-ly-ti-le-phan-chia`,
            title: 'sidenav.settings.benefitRatesManagement',
            icon: '',
            breadcrumb: false,
            submenu: []
        },
    ]
}]

const toolsNavTree = [{
    key: 'tools',
    path: `${APP_PREFIX_PATH}/cong-cu`,
    title: 'sidenav.tools',
    icon: LinkOutlined,
    breadcrumb: false,
    submenu: [
        /* {
            key: 'tools-smartReport',
            path: `${APP_PREFIX_PATH}/cong-cu/bao-cao-thong-minh`,
            title: 'sidenav.tools.smartReport',
            icon: '',
            breadcrumb: false,
            submenu: []
        },
        {
            key: 'tools-support',
            path: `${APP_PREFIX_PATH}/cong-cu/cham-soc-khach-hang`,
            title: 'sidenav.tools.support',
            icon: '',
            breadcrumb: false,
            submenu: []
        },
        {
            key: 'tools-mobifone',
            path: `${APP_PREFIX_PATH}/cong-cu/mobifone`,
            title: 'sidenav.tools.mobifone',
            icon: '',
            breadcrumb: false,
            submenu: [
                {
                    key: 'tools-mobifone-apiGateway',
                    path: `${APP_PREFIX_PATH}/cong-cu/mobifone/api-gateway-mobiedu`,
                    title: 'sidenav.tools.mobifone.apiGateway',
                    icon: '',
                    breadcrumb: false,
                    submenu: []
                },
                {
                    key: 'tools-mobifone-doiSoat',
                    path: `${APP_PREFIX_PATH}/cong-cu/mobifone/doi-soat`,
                    title: 'sidenav.tools.mobifone.reportRevenue',
                    icon: '',
                    breadcrumb: false,
                    submenu: []
                },
            ]
        },
        {
            key: 'tools-vinafone',
            path: `${APP_PREFIX_PATH}/cong-cu/vinafone`,
            title: 'sidenav.tools.vinafone',
            icon: '',
            breadcrumb: false,
            submenu: [
                {
                    key: 'tools-vinafone-supportXoso',
                    path: `${APP_PREFIX_PATH}/cong-cu/vinafone/cskh-xoso`,
                    title: 'sidenav.tools.vinafone.supportXoso',
                    icon: '',
                    breadcrumb: false,
                    submenu: []
                },
                {
                    key: 'tools-vinafone-support9029',
                    path: `${APP_PREFIX_PATH}/cong-cu/vinafone/cskh-9029`,
                    title: 'sidenav.tools.vinafone.support9029',
                    icon: '',
                    breadcrumb: false,
                    submenu: []
                },
                {
                    key: 'tools-vinafone-doiSoat',
                    path: `${APP_PREFIX_PATH}/cong-cu/vinafone/doi-soat`,
                    title: 'sidenav.tools.vinafone.reportRevenue',
                    icon: '',
                    breadcrumb: false,
                    submenu: []
                },
            ]
        } */
    ]
}]

export const toNavMenu = (menu) => {
    const parentMenu = {};
    
    for (let i = 0; i < menu.length; i++) {
        const item = menu[i];
        if (item.parent_id) {
            if (!parentMenu[item.parent_id.id]) {
                parentMenu[item.parent_id.id] = {
                    key: item.parent_id.id,
                    isExternalLink: true,
                    path: item.parent_id.url,
                    title: item.parent_id.name,
                    icon: '',
                    breadcrumb: false,
                    submenu: []
                };
            }
            parentMenu[item.parent_id.id].submenu.push({
                key: item.id,
                isExternalLink: true,
                path: item.url,
                title: item.name,
                icon: '',
                breadcrumb: false,
            });
        } else {
            if (!parentMenu[item.id]) {
                parentMenu[item.id] = {
                    key: item.id,
                    isExternalLink: true,
                    path: item.url,
                    title: item.name,
                    icon: '',
                    breadcrumb: false,
                    submenu: []
                };
            }
        }
        
    }
    const newMenu = [];

    for (const key in parentMenu) {
        if (Object.hasOwnProperty.call(parentMenu, key)) {
            newMenu.push(parentMenu[key]);
        }
    }



    return newMenu;
};

const navigationConfig = [
    ...dashBoardNavTree,
    ...revenueReportsNavTree,
    ...customerServiceNavTree,
    ...revenueNavTree,
    ...partnerNavTree,
    ...contentManagementNavTree,
    ...serviceManagementNavTree,
    ...settingsNavTree,
    ...toolsNavTree
]

export default navigationConfig;
