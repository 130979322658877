import { LOAD_USER_PROFILE, LOAD_USER_PROFILE_SUCCESS, LOAD_USER_PROFILE_ERROR, UPDATE_USER_PROFILE, CHANGE_PASSWORD } from "redux/actions/User";


const initState = {
  loading: false, // kiểm tra để hiển thị spin nếu cần
  currentUser: null // lưu thông tin profile của current user
}

const user = (state = initState, action) => {
  switch (action.type) {
    case LOAD_USER_PROFILE:
      return {
        ...state,
        currentUser: null,
        loading: true
      }
    
    case LOAD_USER_PROFILE_SUCCESS:
      return {
        ...state,
        currentUser: action.user,
        loading: false
      }

    case LOAD_USER_PROFILE_ERROR:
      return {
        ...state,
        currentUser: null,
        loading: false
      }

    case UPDATE_USER_PROFILE:
      return {
        ...state,
        currentUser: action.user,
        loading: true
      }
    case CHANGE_PASSWORD:
      return {
        ...state,
        loading: true
      }
    default:
      return state;
  }
}

export default user