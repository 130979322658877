import React, { useEffect } from 'react';

import {
  Modal,
  Form,
  Select,
} from 'antd';


const ModalForm = ({ visible, title = 'Thêm mới gói', onCreate, onCancel, initialValues = null, confirmLoading = false, isEditing = false }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
    } else {
      form.resetFields();
    }
  }, [form, initialValues]);

  const okText = isEditing ? 'Sửa' : 'Thêm';

  if (isEditing) {
    title = 'Chỉnh sửa khiếu nại';
  }

  return (
    <Modal
      forceRender
      confirmLoading={confirmLoading}
      visible={visible}
      title={title}
      okText={okText}
      cancelText="Hủy"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onCreate({
              ...initialValues,
              ...values
            });
          })
          .catch((info) => {
            console.log(info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="packageForm"
        initialValues={initialValues}
      >
        <Form.Item
          label="Vấn đề khiếu nại"
          name="complain_type"
        >
          <Select style={{ width: '100%' }}>
            <Select.Option key="cancel" value="cancel">Hủy dịch vụ</Select.Option>
            <Select.Option key="register" value="register">Đăng ký dịch vụ</Select.Option>
            <Select.Option key="check" value="check">Kiểm tra tình trạng thuê bao</Select.Option>
            <Select.Option key="refund" value="refund">Yêu cầu hoàn cước</Select.Option>
            <Select.Option key="mt_not_receive" value="mt_not_receive">Không nhận được MT dịch vụ</Select.Option>
            <Select.Option key="membership_code_invalid" value="membership_code_invalid">Nhập mã hội viên không hợp lệ</Select.Option>
            <Select.Option key="story_card_not_receive" value="story_card_not_receive">Không nhận được phiếu đọc truyện</Select.Option>
            <Select.Option key="other" value="other">Khác</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
            label="Nguyên nhân khiếu nại"
            name="complain_reason"
        >
          <Select style={{ width: '100%' }}>
            <Select.Option key="unregister-1" value="unregister">Không có nhu cầu sử dụng</Select.Option>
            <Select.Option key="registe-1" value="register">Có nhu cầu sử dụng</Select.Option>
            <Select.Option key="error-1" value="error">Lỗi dịch vụ</Select.Option>
            <Select.Option key="membership_code_invalid-1" value="membership_code_invalid">Nhập sai mã hội viên</Select.Option>
            <Select.Option key="check-1" value="check">Kiểm tra tình trạng thuê bao</Select.Option>
            <Select.Option key="other-1" value="other">Khác</Select.Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalForm;
