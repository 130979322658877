import React from 'react'
import { Button, Col, Row, Form, Select, DatePicker } from 'antd';
import IntlMessage from "components/util-components/IntlMessage";
import Utils from 'utils';
import moment from "moment";

const {RangePicker} = DatePicker;

function GlobalFilter({ onHandleFilter, servicesOptions, telcosOptions, partnersOptions }) {
  const onFinish = values => {
    if (Array.isArray(values.time) && values.time.length > 0) {
      const fromDate = moment(values.time[0].startOf('day').format('YYYY-MM-DD')).utc(true).toString();
      const toDate = moment(values.time[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')).utc(true).toString();
      values['submit_date_gte'] = fromDate;
      values['submit_date_lte'] = toDate;

      delete values.time;
    }
    
    typeof onHandleFilter === 'function' && onHandleFilter(values)
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Form layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={{
        time: Utils.getDefaultDateDashboard()
      }}
    >
      <Row gutter={[24, 24]} align="bottom">
        <Col xs={24} md={6}>
          <Form.Item name="time" label="Thời gian xem">
            <RangePicker style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col xs={24} md={5}>
          <Form.Item name="telco_id_in" label={<IntlMessage id="label.form.homeNetwork" />}>
            <Select
              showSearch
              mode="tags"
              placeholder="Chọn nhà mạng"
              filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {
                telcosOptions && telcosOptions.map((t, i) => <Select.Option key={t.id}
                  value={t.id}>{t.name}</Select.Option>)
              }
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={5}>
          <Form.Item name="service_id_in" label="Dịch vụ">
            <Select
                // onChange={onChangeService}
                mode="tags"
                showSearch
                placeholder="Chọn dịch vụ"
                filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {
                servicesOptions && servicesOptions.map((s, i) => <Select.Option key={s.id}
                                                                                value={s.id}>{s.name}</Select.Option>)
              }
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={5}>
          <Form.Item name="partner_id_in" label="Đối tác" style={{ width: '100%' }}>
            <Select 
              showSearch
              mode="tags"
              placeholder="Chọn đối tác"
              filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {
                partnersOptions && partnersOptions.map((p, i) => <Select.Option key={p.id}
                  value={p.id}>{p.name}</Select.Option>)
              }
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={3}>
          <Form.Item >
            <Button type="primary" htmlType="submit">
              <IntlMessage id={'button.submit'} />
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}


export default GlobalFilter;
