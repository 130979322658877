import React from 'react';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { Spin } from 'antd';
import { isEqual, sortBy } from 'lodash'
import { COLORS } from '../config';

am4core.useTheme(am4themes_animated);

class ChargeLineChart extends React.Component {

  constructor(props) {
    super(props);

    this.chartDivId = this.props.chartDivId || 'chargeLineChartDiv';
  }

  componentDidMount() {
    this.setup();
  }

  componentDidUpdate() {
    this.setup();
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return !isEqual(nextProps, this.props);
  }

  componentWillUnmount() {
    this.dispose();
  }

  setup() {
    const { loading, data } = this.props;
    if (!loading && data && data.length) {
      this.dispose()
      this.build(sortBy(data, (d) => new Date(d.date)));
    }
  }

  dispose() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  build(data) {
    let chart = am4core.create(this.chartDivId, am4charts.XYChart);

    chart.colors.step = 2;
    chart.maskBullets = false;

    // Add data
    chart.data = data;

    // Create axes
    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.renderer.minGridDistance = 50;
    dateAxis.renderer.grid.template.disabled = true;
    dateAxis.renderer.fullWidthTooltip = true;

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.max = 100;

    const transRatioSeries = chart.series.push(new am4charts.LineSeries());
    transRatioSeries.dataFields.valueY = "trans_ratio";
    transRatioSeries.dataFields.dateX = "date";
    transRatioSeries.name = "Giao dịch";
    transRatioSeries.strokeWidth = 2;
    transRatioSeries.propertyFields.strokeDasharray = "dashLength";
    transRatioSeries.tooltipText = "Giao dịch: {valueY} %";
    transRatioSeries.showOnInit = true;
    transRatioSeries.fill = am4core.color(COLORS[0]);
    transRatioSeries.stroke = am4core.color(COLORS[0]);


    const userRatioSeries = chart.series.push(new am4charts.LineSeries());
    userRatioSeries.dataFields.valueY = "user_ratio";
    userRatioSeries.dataFields.dateX = "date";
    userRatioSeries.name = "Người dùng";
    userRatioSeries.strokeWidth = 2;
    userRatioSeries.propertyFields.strokeDasharray = "dashLength";
    userRatioSeries.tooltipText = "Người dùng: {valueY} %";
    userRatioSeries.showOnInit = true;
    userRatioSeries.fill = am4core.color(COLORS[1]);
    userRatioSeries.stroke = am4core.color(COLORS[1]);

    const revenueRatioSeries = chart.series.push(new am4charts.LineSeries());
    revenueRatioSeries.dataFields.valueY = "revenue_ratio";
    revenueRatioSeries.dataFields.dateX = "date";
    revenueRatioSeries.name = "Doanh thu";
    revenueRatioSeries.strokeWidth = 2;
    revenueRatioSeries.propertyFields.strokeDasharray = "dashLength";
    revenueRatioSeries.tooltipText = "Doanh thu: {valueY} %";
    revenueRatioSeries.showOnInit = true;
    revenueRatioSeries.fill = am4core.color(COLORS[2]);
    revenueRatioSeries.stroke = am4core.color(COLORS[2]);


    // Add legend
    chart.legend = new am4charts.Legend();

    // Add cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.fullWidthLineX = true;
    chart.cursor.xAxis = dateAxis;
    chart.cursor.lineX.strokeOpacity = 0;
    chart.cursor.lineX.fill = am4core.color("#000");
    chart.cursor.lineX.fillOpacity = 0.1;


    chart.language.locale["_decimalSeparator"] = ",";
    chart.language.locale["_thousandSeparator"] = ".";

    this.chart = chart;
  }

  render() {
    const { data, loading } = this.props;

    return (
      <>
        { loading ? <Spin /> :
          (
            !data || !data.length ? (<div>No data found</div>) : (<div id={this.chartDivId} style={{ width: "100%", height: "500px" }}></div>)
          )
        }
      </>
    );
  }
}

export default ChargeLineChart;
