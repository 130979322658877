import React, { Component, useEffect } from 'react';
import {
    Row,
    Col,
    Card,
    Table,
    Button,
    Tooltip,
    Popconfirm,
    Form,
    Input,
    Modal,
    message
} from 'antd';
import {
    EditOutlined,
    FileAddOutlined,
    DeleteOutlined
} from '@ant-design/icons';
import moment from 'moment';
import TelcoService from "services/TelcoService";
import Utils from "utils";

const { TextArea } = Input;

const TelcoPopup = ({ visible, onCreate, onCancel, initialValues = null, confirmLoading = false, isEditing = false, telcos = [] }) => {
    const [form] = Form.useForm();
    useEffect(() => {
        if (initialValues) {
            form.setFieldsValue(initialValues);
        } else {
            form.resetFields();
        }
    }, [form, initialValues]);

    const okText = isEditing ? 'Sửa' : 'Thêm';
    const title = isEditing ? 'Sửa nhà mạng' : 'Thêm mới nhà mạng';
    return (
        <Modal confirmLoading={confirmLoading}
            visible={visible}
            title={title}
            okText={okText}
            cancelText="Hủy"
            onCancel={onCancel}
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                        onCreate({
                            ...initialValues,
                            ...values
                        });
                    })
                    .catch((info) => {
                        console.log(info);
                    });
            }}>
            <Form
                form={form}
                layout="vertical"
                name="serviceForm"
                initialValues={initialValues}
            >
                <Form.Item
                    label="Tên nhà mạng"
                    name="name"
                    rules={[{ required: true, message: 'Tên nhà mạng không được để trống' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Mô tả nhà mạng"
                    name="description"
                    rules={[{
                        required: true,
                        message: 'Mô tả nhà mạng không được để trống'
                    }]}
                >
                    <TextArea />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export class Telco extends Component {
    state = {
        telcos: [],
        loading: false,
        visible: false,
        confirmLoading: false,
        initialValues: {},
        isEditing: false,
        title: '',
        filters: {},
        pagination: {
            current: 1,
            pageSize: 10,
        },
    };
    columns = [
        {
            title: 'STT',
            dataIndex: 'id',
            key: 'id',
            render: (text, record, index) => {
                const { current, pageSize } = this.state.pagination;

                return ((current - 1) * pageSize) + index + 1
            },
        },
        {
            title: 'Tên nhà mạng',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Mô tả',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Ngày tạo',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (createdAt) => moment(createdAt).format("YYYY-MM-DD"),
            width: 200
        },
        {
            title: 'Cập nhật',
            dataIndex: 'update',
            key: 'update',
            render: (_, record) => (
                <>
                    <Tooltip title="Sửa">
                        <Button icon={<EditOutlined />} size="small" onClick={() => this.handleEdit(record)} />
                    </Tooltip>
                </>
            )
        },
        {
            title: 'Xóa',
            dataIndex: 'delete',
            key: 'delete',
            render: (_, record) => (
                <>
                    <Popconfirm onConfirm={() => this.handleDelete(record)} title="Bạn muốn xóa cú pháp này phải không?">
                        <Tooltip title="Xóa">
                            <Button danger icon={<DeleteOutlined />} size="small" />
                        </Tooltip>
                    </Popconfirm>
                </>
            )
        },
    ];

    constructor(props) {
        super(props);

        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        this.getTotalCount();
        this.loadData();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getTotalCount = async () => {
        const { filters } = this.state;
        const total = await TelcoService.count(filters);
        const { pagination } = this.state;
        this._isMounted && this.setState({
            pagination: {
                ...pagination,
                total
            }
        })
    }

    loadData = async (params) => {
        this.setState({
            loading: true
        })
        const { filters } = this.state;
        const __pagination = Utils.getPaginationParams(params);
        const __params = Object.assign(filters, __pagination);
        const telcos = await TelcoService.read(__params);
        const { pagination } = this.state;
        this._isMounted && this.setState({
            loading: false,
            telcos,
            pagination: {
                ...pagination,
                ...params
            }
        })
    }
    handleTableChange = (pagination) => {
        this.loadData(pagination);
    }

    handleAdd = () => {
        this.setState({
            initialValues: null,
            isEditing: false,
            visible: true,
            title: 'Thêm nhà mạng'
        })
    }
    handleCreate = async (telco) => {
        try {
            await TelcoService.create(telco);
            await this.loadData();
            this.hideModal();
            message.success({ content: 'Thêm mới thành công', duration: 2 });
        } catch (error) {
            this.hideModal();
            message.error({ content: 'Thêm mới không thành công', duration: 2 })
        }
    }
    handleEdit = (telco) => {
        this.setState({
            initialValues: telco,
            isEditing: true,
            visible: true,
            title: 'Sửa nhà mạng'
        })
    }
    handleUpdate = async (telco) => {
        try {
            await TelcoService.update(telco);
            await this.loadData();
            this.hideModal();
            message.success({ content: 'Sửa thành công', duration: 2 });
        } catch (error) {
            message.error({ content: 'Sửa không thành công', duration: 2 })
            this.hideModal();
        }
    }
    handleDelete = async (telco) => {
        try {
            await TelcoService.delete(telco.id);
            const { telcos } = this.state;
            this._isMounted && this.setState({
                telcos: telcos.filter(s => s.id !== telco.id)
            })
            message.success({ content: 'Xóa thành công', duration: 2 });
        } catch (error) {
            message.error({ content: 'Xóa không thành công', duration: 2 })
        }
    }
    hideModal = () => {
        this.setState({ visible: false, confirmLoading: false })
    }
    onCancel = () => {
        this.hideModal();
    }
    onFinish = async (values) => {
        let filters = {};
        if (values.telco !== undefined) {
            filters['name_contains'] = values.telco;
        }
        this.setState({
            filters,
            pagination: {
                current: 1,
                pageSize: 10,
            },
        })
        await this.getTotalCount();
        this.loadData();
    }
    render() {
        const { visible, isEditing, confirmLoading, initialValues, loading, telcos, pagination } = this.state;
        const onCreate = isEditing ? this.handleUpdate : this.handleCreate;
        return (
            <>
                <TelcoPopup
                    confirmLoading={confirmLoading}
                    visible={visible}
                    isEditing={isEditing}
                    initialValues={initialValues}
                    onCancel={this.onCancel}
                    onCreate={onCreate}
                    telcos={telcos} />
                <Card>
                    <Form layout="vertical" onFinish={this.onFinish}>
                        <Row style={{ marginBottom: '15px' }} gutter={[24, 24]}>
                            <Col md={6} sm={24} xs={24}>
                                <Form.Item label="Tên nhà mạng" name="telco">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col md={6} sm={24} xs={24}>
                                <Form.Item style={{ float: 'left', marginLeft: '5px', marginTop: '20px' }}>
                                    <Button type="primary" htmlType="submit" style={{ float: 'right' }}>Lọc</Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Card>
                <Row>
                    <Col xs={24}>
                        <Card title="Danh sách nhà mạng">
                            <Button onClick={() => this.handleAdd()} type="primary"
                                style={{ marginBottom: 16, borderRadius: '5px', float: 'right' }}
                                icon={<FileAddOutlined />}>
                                Thêm mới nhà mạng
                            </Button>
                            <Table
                                pagination={pagination}
                                onChange={this.handleTableChange}
                                columns={this.columns}
                                dataSource={telcos}
                                loading={loading}
                                rowKey={record => record.id}
                            />
                        </Card>
                    </Col>
                </Row>
            </>
        );
    }
}

export default Telco
