import fetch from 'interceptors/FetchInterceptor';

const PACKAGE_URL_API = '/collections/packages';

class PackageService {
    async read(params) {
        if(params){
            params['_sort'] = 'published_at:DESC';
        }
        const response = await fetch({
            url: PACKAGE_URL_API,
            method: 'get',
            params
        });
        return response;
    }

    async create(item) {
        const response = await fetch({
            url: PACKAGE_URL_API,
            method: 'post',
            data: item,
        });
        return response;
    }

    async update(item) {
        const response = await fetch({
            url: PACKAGE_URL_API + '/' + item.id,
            method: 'put',
            data: item,
        });
        return response;
    }

    async delete(item) {
        const response = await fetch({
            url: PACKAGE_URL_API + '/' + item.id,
            method: 'delete',
        });
        return response;
    }

    async count(params){
        const response = await fetch({
            url: PACKAGE_URL_API + '/count',
            method: 'GET',
            params
        })
        return response;
    }
}

export default new PackageService();
