import antdViVn from 'antd/es/locale/fr_FR';
import viMsg from "../locales/vi_VN";

const ViLang = {
  antd: antdViVn,
  locale: 'vi-VN',
  messages: {
    ...viMsg
  },
};
export default ViLang;
