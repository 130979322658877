import React, { Component } from 'react'
import { Button, Card, Col, DatePicker, Form, Input, Row, Select, Table } from "antd";
import { PhoneOutlined } from "@ant-design/icons";
import telcosService from "services/TelcoService";
import serviceService from "services/ServiceService";
import moment from "moment";
import mtService from "services/MtService";
import Utils from "utils";
import { PATTERN_PHONE } from "configs/AppConfig";
import {
    DownloadOutlined
} from '@ant-design/icons';
import MtService from 'services/MtService';

const { RangePicker } = DatePicker;

export class MtList extends Component {
    state = {
        mt: [],
        telcos: [],
        services: [],
        loading: false,
        pagination: {
            current: 1,
            pageSize: 10,
        },
        filters: {}
    };

    columns = [
        {
            title: 'STT',
            dataIndex: 'id',
            render: (text, record, index) => {
                const { current, pageSize } = this.state.pagination;

                return ((current - 1) * pageSize) + index + 1
            },
            width: 10
        },
        {
            title: 'Số điện thoại',
            dataIndex: 'msisdn',
            width: 100
        },
        {
            title: 'Dịch vụ',
            dataIndex: ['service_id', 'name'],
            width: 100
        },
        // {
        //     title: 'Gói dịch vụ',
        //     dataIndex: ['package_id', 'name'],
        //     width: 150
        // },
        {
            title: 'Nhà mạng',
            dataIndex: ['telco_id', 'name'],
            width: 100
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            render: (_, record) => {
                if (record.status === 1) {
                    return 'Thành công';
                } else if (record.status === 0) {
                    return 'Lỗi khi gửi';
                } else {
                    return 'Unknown';
                }
            },
            width: 100
        },
        {
            title: 'Nội dung MT',
            dataIndex: 'mt_content',
            width: 450
        },
        {
            title: 'Thời gian gửi',
            dataIndex: 'submit_time',
            width: 150,
            render: (_, record) => {
                const date = moment.utc(record.submit_time);
                return date.format('DD-MM-YYYY HH:mm:ss');
            }
        },
    ];

    constructor(props) {
        super(props);

        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        this.getDefaultDate();
        this.loadData();
        this.getTotalCount();
        this.getServices();
        this.getTelcos();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getTotalCount = async () => {
        const { filters } = this.state;

        const total = await mtService.count(filters);
        const { pagination } = this.state;
        this._isMounted && this.setState({
            pagination: {
                ...pagination,
                total
            }
        })
    }

    getDefaultDate = () => {
        const { filters } = this.state;
        let defaultDates = Utils.getDefaultDates();
        if (filters.submit_time_gte === undefined || filters.submit_time_lte === undefined) {
            filters['submit_time_gte'] = moment.utc(defaultDates[0]).format('YYYY-MM-DDT00:00:00.000Z');
            filters['submit_time_lte'] = moment.utc(defaultDates[1]).format('YYYY-MM-DDT23:59:59.000Z');
        }
        this.setState({
            filters
        });
    }

    loadData = async (params) => {
        this.setState({
            loading: true
        })
        const { filters } = this.state;
        const __pagination = Utils.getPaginationParams(params);
        const __params = Object.assign(filters, __pagination);
        const mt = await mtService.read(__params);
        const { pagination } = this.state;
        this._isMounted && this.setState({
            loading: false,
            mt,
            filters,
            pagination: {
                ...pagination,
                ...params
            }
        });
        await this.getTotalCount();
    }

    getTelcos = async () => {
        const telcos = await telcosService.read();
        this._isMounted && this.setState({
            telcos
        });
    }

    getServices = async () => {
        const services = await serviceService.read();
        this._isMounted && this.setState({
            services
        })
    }

    onFinish = async (values) => {
        this.setState({
            loading: true,
        });

        let filters = {};
        if (values.msisdn && values.msisdn !== '') {

            let _phone = values.msisdn;
            if ((/(^(09|03|07|08|05)+([0-9]{8}$))/).test(_phone)) {
                _phone = _phone.replace('0', '84')
            }
            filters['msisdn_eq'] = _phone;
        }
        if (values.status) {
            filters['status_eq'] = values.status;
        }

        if (values.submit_time && values.submit_time !== '') {
            let submitTimeFrom = new Date(values.submit_time[0]);
            let submitTimeTo = new Date(values.submit_time[1]);
            submitTimeFrom = moment.utc(submitTimeFrom).format('YYYY-MM-DDT00:00:00.000Z');
            submitTimeTo = moment.utc(submitTimeTo).format('YYYY-MM-DDT23:59:59.000Z');

            filters['submit_time_gte'] = submitTimeFrom;
            filters['submit_time_lte'] = submitTimeTo;
        }

        if (values.service_id) {
            filters['service_id_in'] = values.service_id;
        }

        if (values.telco_id) {
            filters['telco_id_in'] = values.telco_id;
        }
        this.setState({
            filters,
            pagination: {
                current: 1,
                pageSize: 10,
            },
        })
        this.loadData();
    }

    handleTableChange = (pagination) => {
        this.loadData(pagination);
    }

    handleDownload = async () => {
        this.state.filters._limit = -1
        const url = await MtService.export(this.state.filters)
        if (url) {
            Utils.downloadBySaveFile(url, url.replace(/^.*[\\/]/, ''))
        }
    }

    render() {
        const { mt, services, telcos, loading, pagination } = this.state;
        return (
            <>
                <Card>
                    <Form layout="vertical" onFinish={this.onFinish}>
                        <Row gutter={[24, 24]}>
                            <Col md={6} sm={24} xs={24}>
                                <Form.Item name="msisdn"
                                    label="Số điện thoại"

                                    rules={[
                                        {
                                            pattern: PATTERN_PHONE,
                                            message: 'Không đúng định dạng số điện thoại',
                                        },
                                    ]}
                                >
                                    <Input size="default" placeholder="Nhập số điện thoại cần tra cứu"
                                        prefix={<PhoneOutlined />} />
                                </Form.Item>
                            </Col>
                            <Col md={6} sm={24} xs={24}>
                                <Form.Item name="status" label="Trạng thái">
                                    <Select style={{ width: '100%' }}
                                        placeholder="Lựa chọn trạng thái"
                                    >
                                        <Select.Option>Tất cả</Select.Option>
                                        <Select.Option key="1" value="1">Thành công</Select.Option>
                                        <Select.Option key="0" value="0">Lỗi khi gửi</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col md={6} sm={24} xs={24} className="d-flex">
                                <Form.Item name="submit_time" label="Thời gian gửi" initialValue={Utils.getDefaultDates()}
                                    style={{ width: '100%', float: 'left', marginRight: '5px' }}>
                                    <RangePicker
                                        style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                            <Col md={6} sm={24} xs={24}>
                                <Form.Item name="telco_id" label="Nhà mạng">
                                    <Select style={{ width: '100%' }}
                                        placeholder="Lựa chọn nhà mạng"

                                        mode="tags"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {
                                            telcos.map((data) => <Select.Option key={data.id}
                                                value={data.id}>{data.name}</Select.Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '15px' }} gutter={[24, 24]}>
                            <Col md={6} sm={24} xs={24}>
                                <Form.Item name="service_id" label="Dịch vụ">
                                    <Select style={{ width: '100%' }}
                                        placeholder="Lựa chọn dịch vụ"

                                        mode="tags"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {
                                            services.map((data) => <Select.Option key={data.id}
                                                value={data.id}>{data.name}</Select.Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col md={6} sm={24} xs={24}>
                                <Form.Item style={{ width: '30%', float: 'left', marginLeft: '5px' }}>
                                    <Button className="float-right" type="primary" htmlType="submit"
                                        style={{ width: '100%', marginTop: '20px', padding: '0px' }}>Lọc</Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Card>
                <Row gutter={[24, 24]}>
                    <Col md={24} sm={24} xs={24}>
                        <Card title="Danh sách MT">
                            <Button type="default"
                                    style={{marginBottom: 16, borderRadius: '5px', float: 'right', marginLeft: '15px'}}
                                    icon={<DownloadOutlined/>}
                                    onClick={this.handleDownload}>
                                Tải về
                            </Button>
                            <Table
                                columns={this.columns}
                                dataSource={mt}
                                loading={loading}
                                rowKey={record => record.id}
                                pagination={pagination}
                                onChange={this.handleTableChange}
                            />
                        </Card>
                    </Col>
                </Row>
            </>
        );
    }
}

// adding the oidc user in the props
export default MtList
