import React, { Component } from 'react';
import moment from "moment";
import { Table } from 'antd';


class ServicesOfCustomer extends Component {
    state = {
        pagination: {
            current: 1,
            pageSize: 10,
        }
    };

    columns = [
        {
            title: 'STT',
            dataIndex: 'stt',
            key: 'stt',
            render: (text, record, index) => {
                const { current, pageSize } = this.state.pagination;

                return ((current - 1) * pageSize) + index + 1
            }
        },
        {
            title: 'Thuê bao',
            dataIndex: 'msisdn',
        },
        {
            title: 'Tên dịch vụ',
            dataIndex: ['service_id', 'name'],
            width: 150,
        },
        {
            title: 'Gói dịch vụ',
            dataIndex: ['package_id', 'name'],
            width: 150,
            render: (_, record) => {
                if (record?.package_id?.code) {
                    return record.package_id.code;
                } else if (record?.package_id?.name) {
                    return record.package_id.name;
                } else
                    return ''
            }
        },
        {
            title: 'Cú pháp dịch vụ',
            dataIndex: ['command_code_id', 'command_code'],
            width: 150,
        },
        {
            title: 'Đầu số dịch vụ',
            dataIndex: 'tel_server',
            width: 150,
        },
        {
            title: 'Nhà mạng',
            dataIndex: ['telco_id', 'name'],
            width: 150,
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            width: 150,
            render: (status) => {
                if (status === 1) {
                    return 'Đăng ký';
                } else if (status === 0) {
                    return 'Hủy';
                } else {
                    return 'Unknown';
                }
            }
        },
        {
            title: 'Ngày DK',
            dataIndex: 'registered_time',
            width: 150,
            render: (_, record) => {
                if (record.registered_time !== null) {
                    const date = moment(record.registered_time).utc(false);
                    return date.format('DD-MM-YYYY HH:mm:ss');
                } else {
                    return '';
                }
            }
        },
        {
            title: 'Ngày hủy',
            dataIndex: 'unregistered_time',
            width: 150,
            render: (_, record) => {
                if (record.unregistered_time !== null) {
                    const date = moment(record.unregistered_time).utc(false);
                    return date.format('DD-MM-YYYY HH:mm:ss');
                } else {
                    return '';
                }
            }
        },
        {
            title: 'Ngày hết hạn',
            dataIndex: 'expired_time',
            width: 150,
            render: (_, record) => {
                if (record.expired_time !== null) {
                    const date = moment(record.expired_time).utc(false);
                    return date.format('DD-MM-YYYY HH:mm:ss');
                } else {
                    return '';
                }
            }
        },
        {
            title: 'Ngày tạm dừng',
            dataIndex: 'paused_time',
            width: 150,
            render: (_, record) => {
                if (record.paused_time !== null) {
                    const date = moment(record.paused_time).utc(false);
                    return date.format('DD-MM-YYYY HH:mm:ss');
                } else {
                    return '';
                }
            }
        },
        {
            title: 'Ngày gia hạn tiếp theo',
            dataIndex: 'resumed_time',
            width: 200,
            render: (_, record) => {
                if (record.resumed_time !== null) {
                    const date = moment(record.resumed_time).utc(false);
                    return date.format('DD-MM-YYYY HH:mm:ss');
                } else {
                    return '';
                }
            }
        },
    ];

    render() {
        return (
            <Table
                columns={this.columns}
                dataSource={this.props.servicesOfCustomer}
                loading={this.props.loading}
                pagination={false}
                rowKey={record => record.id}
            />
        );
    }
}

export default ServicesOfCustomer;
