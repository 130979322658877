import React, { Component } from 'react'
import WaterfallChart from '../components/AmCharts/WaterfallChart';
import GlobalFilter from './GlobalFilter';
import StatisticDashboard from "./Components/statistic";
import TransactionTable from './transaction-table';
import { Row, Col, Card } from 'antd';
import dashboardService from "services/DashboardService";
import partnerService from "services/PartnerService";
import TelcoService from 'services/TelcoService';
import ServiceService from 'services/ServiceService';
import ReportService from 'services/ReportService';
import Utils from 'utils';
import LineChart from '../components/AmCharts/LineChart';
import ChargeLineChart from '../components/AmCharts/ChargeLineChart';
import StackColumnChart from "../components/AmCharts/StackColumnChart";

export class Dashboard extends Component {

  constructor(props) {
    super(props);

    this.state = {
      partners: [],
      partnersLoading: false,
      services: [],
      seriesServices: [],
      seriesServicesLoading: false,
      servicesLoading: false,
      telcos: [],
      telcosLoading: false,

      statistic: [],
      statisticLoading: false,
      usersAndRevenue: [],
      usersAndRevenueLoading: false,
      revenueBytelcos: [],
      revenueBytelcosLoading: false,
      servicesAndPackages: [],
      servicesAndPackagesLoading: false,
      callsByServices: [],
      callsByServicesLoading: false,
      callLogs: [],
      callLogsLoading: false,
      sharingRates: [],
      sharingRatesLoading: false,
      filters: {}
    };

    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;

    // load for filters
    this.loadServices();
    this.loadSeriesServices();
    this.loadTelcos();
    this.loadPartners();
    this.defaultDate();
    this.update();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  loadTelcos = async () => {
    this.setState({
      telcosLoading: true
    })

    const telcos = await TelcoService.read();
    this._isMounted && this.setState({
      telcos,
      telcosLoading: false
    })
  }

  loadServices = async () => {
    this.setState({
      servicesLoading: true
    })

    const services = await ServiceService.read();
    this._isMounted && this.setState({
      services,
      servicesLoading: false
    })
  }

  loadSeriesServices = async () => {
    this.setState({
      seriesServicesLoading: true
    })

    const seriesServices = await ServiceService.read({service_group_null: false});
    this._isMounted && this.setState({
      seriesServices,
      seriesServicesLoading: false
    })
  }

  loadPartners = async () => {
    this.setState({
      partnersLoading: true
    })

    const partners = await partnerService.read();
    this._isMounted && this.setState({
      partners,
      partnersLoading: false
    })
  }

  defaultDate = () => {
    const { filters } = this.state;

    const defautlDates = Utils.getDefaultDateDashboard();
    if (filters.submit_date_gte === undefined || filters.submit_date_lte === undefined) {
      filters['submit_date_gte'] = defautlDates[0].startOf('day').format('YYYY-MM-DD');
      filters['submit_date_lte'] = defautlDates[1].endOf('day').format('YYYY-MM-DD HH:mm:ss');
    }

    this.setState({
      filters
    });
  }

  // Get Statistic
  getStatistic = async (params) => {
    this.setState({
      statisticLoading: true
    });
    const { filters } = this.state;
    const __param = Object.assign(filters, params);
    const statistic = await dashboardService.getOverview(__param);

    this._isMounted && this.setState({
      statisticLoading: false,
      statistic
    });
  }

  getStatisticUser = async (params) => {
    this.setState({
      usersAndRevenueLoading: true
    });
    const { filters } = this.state;
    const __param = Object.assign(filters, params);
    const usersAndRevenue = await ReportService.getStatisticUser(__param);

    this._isMounted && this.setState({
      usersAndRevenueLoading: false,
      usersAndRevenue
    });
  }

  getRevenueByTelco = async (params) => {
    this.setState({
      revenueBytelcos: true
    });
    const { filters } = this.state;
    const __param = Object.assign(filters, params);
    const revenueBytelcos = await ReportService.getRevenueByTelco(__param);

    this._isMounted && this.setState({
      revenueBytelcosLoading: false,
      revenueBytelcos
    });
  }

  getRatioCharge = async (params) => {
    this.setState({
      sharingRatesLoading: true
    });
    const { filters } = this.state;
    const __param = Object.assign(filters, params);
    const sharingRates = await ReportService.getRatioCharge(__param);

    this._isMounted && this.setState({
      sharingRatesLoading: false,
      sharingRates
    });
  }

  getCallsByService = async (params) => {
    this.setState({
      callsByServicesLoading: true
    });
    const { filters } = this.state;
    const __param = Object.assign(filters, params);
    const callsByServices = await ReportService.getCallsByService(__param);

    this._isMounted && this.setState({
      callsByServicesLoading: false,
      callsByServices
    });
  }

  // Get Call Logs
  getCallLog = async (params) => {
    this.setState({
      callLogsLoading: true
    });
    const { filters } = this.state;
    const __param = Object.assign(filters, params);
    const callLogs = await dashboardService.getCallLog(__param);
    this._isMounted && this.setState({
      callLogsLoading: false,
      callLogs
    });
  }

  update = (params = {}) => {
    this.getStatistic(params)
    this.getStatisticUser(params)
    this.getRevenueByTelco(params)
    this.getCallsByService(params)
    this.getCallLog(params);
    this.getRatioCharge(params);
  }

  handleFilter = (filters) => {
    this.update(filters);
  }

  render() {
    const {
      statistic,
      callLogs,
      partners,
      services,
      telcos,
      statisticLoading,
      callLogsLoading,
      usersAndRevenue,
      usersAndRevenueLoading,
      revenueBytelcos,
      revenueBytelcosLoading,
      callsByServices,
      callsByServicesLoading,
      sharingRates,
      sharingRatesLoading,
      seriesServices
    } = this.state;
    return (
      <div className="site-card-wrapper">
        <GlobalFilter onHandleFilter={this.handleFilter} servicesOptions={services} telcosOptions={telcos} partnersOptions={partners} />
        <StatisticDashboard statistic={statistic} loading={statisticLoading} />
        <Row gutter={[24, 24]}>
          <Col xs={24}>
            <Card>
              <h5>Tỉ lệ charge cước</h5>
              <ChargeLineChart data={sharingRates} loading={sharingRatesLoading} />
            </Card>
          </Col>
          <Col xs={24}>
            <Card>
              <h5>Doanh thu Khách hàng</h5>
              <StackColumnChart data={revenueBytelcos} loading={revenueBytelcosLoading} seriesServices={seriesServices} />
            </Card>
          </Col>
          <Col xs={24}>
            <Card>
              <h5>Người dùng</h5>
              <LineChart data={usersAndRevenue} loading={usersAndRevenueLoading} />
            </Card>
          </Col>
        </Row>
        <Row gutter={[24, 24]}>
          <Col md={12} xs={24}>
            <Card>
              <h5>Thống kê cuộc gọi theo dịch vụ</h5>
              <WaterfallChart data={callsByServices} loading={callsByServicesLoading} />
            </Card>
          </Col>
          <Col md={12} xs={24}>
            <Card>
              <h5>Giao dịch gần đây</h5>
              <TransactionTable callLogs={callLogs} loading={callLogsLoading} />
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Dashboard;
