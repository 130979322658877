import fetch from 'interceptors/FetchInterceptor';
const ENDPOINT = '/collections/cross-checks';

class ForControlHomeNetworkService {
    async create(content){
        const response = await fetch({
            url: ENDPOINT,
            method: 'POST',
            data: content,
        })
        return response;
    }
    async read(params){
        const response = await fetch({
            url: `${ENDPOINT}`,
            method: 'GET',
            params
        })
        return response;
    }
    async count(params){
        const response = await fetch({
            url: `${ENDPOINT}/count`,
            method: 'GET',
            params
        })
        return response;
    }
    async update(content){

        const response = await fetch({
            url: `${ENDPOINT}/${content.id}`,
            method: 'PUT',
            data: content
        })

        return response;
    }
    async delete(id){
        const response = await fetch({
            url: `${ENDPOINT}/${id}`,
            method: 'DELETE',
        })
        return response;
    }

    async exportTelco(params){
        const response = await fetch({
            url: `${ENDPOINT}/export-telco`,
            method: 'GET',
            params
        })
        return response;
    }


    async exportPartner(params){
        const response = await fetch({
            url: `${ENDPOINT}/export-partner`,
            method: 'GET',
            params
        })
        return response;
    }

}
export default new ForControlHomeNetworkService();