import fetch from 'interceptors/FetchInterceptor';

const API_ENDPOINT = '/collections/benefit-rates';
class BenefitRatesService {
    
    static async create(service){
        const response = await fetch({
            url: API_ENDPOINT,
            method: 'POST',
            data: service,
        })
        return response;
    }
    
    static async read(params){
        console.log(params)

        const response = await fetch({
            url: `${API_ENDPOINT}`,
            method: 'GET',
            params
        })
        return response;
    }
    
    static async count(params){
        const response = await fetch({
            url: `${API_ENDPOINT}/count`,
            method: 'GET',
            params
        })
        return response;
    }
    
    static async update(service){
        const response = await fetch({
            url: `${API_ENDPOINT}/${service.id}`,
            method: 'PUT',
            data: service
        })
        return response;
    }
    
    static async delete(id){
        const response = await fetch({
            url: `${API_ENDPOINT}/${id}`,
            method: 'DELETE',
        })
        return response;
    }
}
export default BenefitRatesService;