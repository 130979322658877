import { AUTH_TOKEN, REFRESHED_TOKEN } from "redux/constants/Auth";
import jwtDecode from "jwt-decode";
import { API_BASE_URL, ENTRY_ROUTE } from "configs/AppConfig";
import axios from "axios";
import { notification } from "antd";
import history from "../history";
import User from "./User";

class Auth {
  refreshTimeoutId;

  constructor() {
    this.token = localStorage.getItem(AUTH_TOKEN);
    this.refreshedToken = localStorage.getItem(REFRESHED_TOKEN);
    this.isRefreshing = false;
    
    if (this.token && this.refreshedToken) {
      this.setup();
    } else {
      this.eraseToken();
    }
  }

  getToken = () => {
    return this.token;
  }

  setToken = (token) => {
    this.token = token;
    localStorage.setItem(AUTH_TOKEN, token);
  }

  eraseToken = () => {
    this.token = null;
    this.abortRefreshToken();
    localStorage.removeItem(AUTH_TOKEN);
    localStorage.removeItem(REFRESHED_TOKEN)
  }

  getRefreshedToken = () => {
    return this.refreshedToken;
  }

  setRefreshedToken = (refreshedToken) => {
    this.refreshedToken = refreshedToken;
    localStorage.setItem(REFRESHED_TOKEN, refreshedToken);
  }

  async setup() {
    try {
      const decoded = jwtDecode(this.token);
      this.user = new User(decoded);
      await this.getNewRefreshedToken();
    } catch (error) {
      this.backToLogin()
    }
    
  }

  setupToken = ({ access_token, refresh_token }) => {
    try {
      this.setToken(access_token)
      this.setRefreshedToken(refresh_token)

      const decoded = jwtDecode(access_token)
      const delay = decoded.exp - decoded.iat

      this.refreshToken(delay);
      this.user = new User(decoded);
    } catch (error) {
      this.backToLogin()
    }
  }

  refreshToken = (delay = 5) => {
    this.abortRefreshToken()
    this.refreshTimeoutId = setTimeout(this.getNewRefreshedToken, delay * 1000 - 5000)
  }

  getNewRefreshedToken = async () => {
    try {
      const refreshedToken = this.getRefreshedToken();
      this.isRefreshing = true;
      const response = await this.fetchRefreshToken(refreshedToken)
      if (response && response.data) {
        if (response.data.data) {
          this.setupToken(response.data.data)
        } else if (response.data.msg) {
          notification.error(response.data.msg.message);
          throw new Error(response.data.msg.message)
        }
      }
      this.isRefreshing = false;
      return true
    } catch (error) {
      this.backToLogin()
      this.isRefreshing = false;
      return false;
    }
  }

  abortRefreshToken = () => {
    clearTimeout(this.refreshTimeoutId)
  }

  isAuthenticate = () => {
    const token = this.getToken();

    if (!token) {
      return false
    } else {
      const decoded = jwtDecode(token)
      const now = (new Date().getTime()) / 1000;
      if (now - 5000 > decoded.exp) {
        return this.getNewRefreshedToken();
      }

      this.refreshToken(decoded.exp - now)
      return true
    }
  }

  fetchRefreshToken = (refreshToken) => {
    return axios({
      method: 'post',
      url: API_BASE_URL + '/auth/refreshToken',
      data: {
        refreshToken
      }
    });
  }

  getDecodedJWT(access_token) {
    return jwtDecode(access_token);
  }

  backToLogin() {
    this.eraseToken();
    history.push(ENTRY_ROUTE)
    window.location.reload();
  }
}

export default new Auth();
