import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
  SIGNIN,
  SIGNOUT
} from '../constants/Auth';
import {
  showAuthMessage,
  authenticated,
  signOutSuccess
} from "../actions/Auth";

import auth from 'auth';
import authService from 'services/AuthService';

export function* signIn() {
  yield takeEvery(SIGNIN, function* ({ payload }) {
    try {
      const user = yield call(authService.login, payload);
      if (user) {
        auth.setupToken(user);
        yield put(authenticated(user.access_token));
      } else {
        yield put(showAuthMessage('Tên đăng nhập và mật khẩu không đúng'));
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* signOut() {
  yield takeEvery(SIGNOUT, function* () {
    try {
      auth.eraseToken();
      yield put(signOutSuccess(null));
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(signIn),
    fork(signOut)
  ]);
}
