import fetch from 'interceptors/FetchInterceptor';
import {API_GATEWAY_URL} from "../redux/constants/User";

class UserService {

    async getUserProfile() {
        const response = await fetch({
            url: `${API_GATEWAY_URL}/ams/account/profile`,
            method: 'get'
        })
        // console.log(response.data);
        return response.data;
    }


    async updateUserProfile(profile) {
        const response = await fetch({
            url: `${API_GATEWAY_URL}/ams/account/updateProfile`,
            method: 'post',
            data: profile.profile
        })
        return response.data;
    }

    async updatePassword(data) {
        // password
        const response = await fetch({
            url: `${API_GATEWAY_URL}/ams/account/changePassword`,
            method: 'post',
            data: data.password
        })
        return response.data;
    }
}


export default new UserService();
