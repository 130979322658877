import React from 'react';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { Spin } from 'antd';

am4core.useTheme(am4themes_animated);

class WaterfallChart extends React.Component {
  constructor(props) {
    super(props);
    
    this.chartDivId = this.props.chartDivId || 'waterfallChartDiv';
  }

  componentDidMount() {
    this.setup();
  }

  componentDidUpdate() {
    this.setup();
  }

  componentWillUnmount() {
    this.dispose();
  }

  setup() {
    const { loading, data } = this.props;
    if (!loading && data && data.length) {
      this.dispose();
      this.build(data);
    }
  }

  dispose() {
    if (this.chart) {
      this.chart.dispose();
    }
  }


  build(data) {
    let chart = am4core.create(this.chartDivId, am4charts.XYChart);

    // Add data
    chart.data = data

  //   [{
  //     "id": "abc",
  //     "service": "Mangatoon",
  //     "total_auto_reply": 1223,
  //     "total_staff_call": 2334
  // }]


    // Create axes
    const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "name";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.inversed = true;
    categoryAxis.renderer.minGridDistance = 10;
    categoryAxis.renderer.axisFills.template.disabled = false;
    categoryAxis.renderer.axisFills.template.fillOpacity = 0.05;


    const valueAxis = chart.xAxes.push(new am4charts.ValueAxis());

    valueAxis.renderer.minGridDistance = 40;
    valueAxis.renderer.ticks.template.length = 5;
    valueAxis.renderer.ticks.template.disabled = false;
    valueAxis.renderer.ticks.template.strokeOpacity = 0.4;
    valueAxis.renderer.labels.template.adapter.add("text", function(text) {
        return text + "";
    })

    // Legend
    chart.legend = new am4charts.Legend();
    chart.legend.position = "bottom";

    // Use only absolute numbers
    chart.numberFormatter.numberFormat = "#.#s";

    // Create series
    function createSeries(field, name, color) {
        const series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueX = field;
        series.dataFields.categoryY = "name";
        series.stacked = true;
        series.name = name;
        series.stroke = color;
        series.fill = color;

        const label = series.bullets.push(new am4charts.LabelBullet());
        label.label.text = "{valueX}";
        label.label.fill = am4core.color("#fff");
        label.label.strokeWidth = 0;
        label.label.truncate = false;
        label.label.hideOversized = true;
        label.locationX = 0.5;
        return series;
    }

    const interfaceColors = new am4core.InterfaceColorSet();
    const positiveColor = interfaceColors.getFor("positive");

    createSeries("total_auto_reply", "Tự động", positiveColor.lighten(0.5));
    createSeries("total_staff_call", "Nhân viên", positiveColor);

    chart.legend.events.on("layoutvalidated", function(event){
        chart.legend.itemContainers.each((container)=>{
            if(container.dataItem.dataContext.name === "Never"){
                container.toBack();
            }
        })
    })

    this.chart = chart;
  }

  render() {
    const { data, loading } = this.props;
    return (
      <>
        { loading ? <Spin /> :
          (
            !data || !data.length ? (<div>No data found</div>) : (<div id={this.chartDivId} style={{ width: "100%", height: "500px" }}></div>)
          )
        }
      </>
    )
  }
}

export default WaterfallChart;
