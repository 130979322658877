import React from 'react';
import { Form, Button, Input, Row, Col, Select, message } from 'antd';
import CallLogService from "services/CallLogService";

const { TextArea } = Input;

const AddComment = ({ phone, packages, onAddComment }) => {

    // const [confirmloading, setConfirmloading] = useState(false)

    const [form] = Form.useForm();
    const onPostComment = async (data) => {
        data['msisdn'] = phone;

        // setConfirmloading(true)
        if (data['type_note'] !== 'call') {
            const comment = await CallLogService.create(data);
            typeof onAddComment === 'function' && onAddComment(comment)
        } else {
            await CallLogService.updateNote(data);
        }

        message.success({ content: 'Gửi thông tin thành công', duration: 3 });

        form.resetFields();

        // setConfirmloading(false)


    }

    return (
        <Form
            form={form}
            layout="vertical"
            // confirmLoading={confirmloading}
            onFinish={onPostComment}
        >
            <Row gutter={[24, 24]}>
                <Col md={8} sm={24} xs={24}>
                    <Form.Item name="complain_type" label="Vấn đề khiếu nại">
                        <Select style={{ width: '100%' }}>
                            <Select.Option key="cancel" value="cancel">Hủy dịch vụ</Select.Option>
                            <Select.Option key="register" value="register">Đăng ký dịch vụ</Select.Option>
                            <Select.Option key="check" value="check">Kiểm tra tình trạng thuê bao</Select.Option>
                            <Select.Option key="refund" value="refund">Yêu cầu hoàn cước</Select.Option>
                            <Select.Option key="mt_not_receive" value="mt_not_receive">Không nhận được MT dịch vụ</Select.Option>
                            <Select.Option key="membership_code_invalid" value="membership_code_invalid">Nhập mã hội viên không hợp lệ</Select.Option>
                            <Select.Option key="story_card_not_receive" value="story_card_not_receive">Không nhận được phiếu đọc truyện</Select.Option>
                            <Select.Option key="other" value="other">Khác</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col md={8} sm={24} xs={24}>
                    <Form.Item name="complain_reason" label="Nguyên nhân khiếu nại">
                        <Select style={{ width: '100%' }}>
                            <Select.Option key="unregister-1" value="unregister">Không có nhu cầu sử dụng</Select.Option>
                            <Select.Option key="registe-1" value="register">Có nhu cầu sử dụng</Select.Option>
                            <Select.Option key="error-1" value="error">Lỗi dịch vụ</Select.Option>
                            <Select.Option key="membership_code_invalid-1" value="membership_code_invalid">Nhập sai mã hội viên</Select.Option>
                            <Select.Option key="check-1" value="check">Kiểm tra tình trạng thuê bao</Select.Option>
                            <Select.Option key="other-1" value="other">Khác</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col md={8} sm={24} xs={24}>
                    <Form.Item name="type_note" label="Loại ghi chú">
                        <Select style={{ width: '100%' }} >
                            <Select.Option key="call-2" value="call">Cuộc gọi</Select.Option>
                            <Select.Option key="email-2" value="email">Email</Select.Option>
                            <Select.Option key="message-2" value="message">Gửi tin nhắn (SMS / Zalo / Facebook,..)</Select.Option>
                            <Select.Option key="other-2" value="other">Khác</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col md={24} sm={24} xs={24}>
                    <Form.Item name="package_ids" label="Gói dịch vụ">
                        <Select style={{ width: '100%' }}
                            mode="tags"
                        >
                            {
                                packages.map((data) => <Select.Option key={data.id}
                                    value={data.id}>{data.code || data.name}</Select.Option>)
                            }
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            {/*<AddComment/>*/}
            <Form.Item name="content" rules={[{ required: true, message: 'Vui lòng nhập thông tin' }]}>
                <TextArea rows={4} />
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit">
                    Gửi thông tin
                </Button>
            </Form.Item>
        </Form>
    );

}

export default AddComment;