import React, {Component} from 'react'
import {Button, Card, Col, DatePicker, Form, Input, Row, Select, Table} from "antd";
import {DownloadOutlined, PhoneOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";
import {APP_PREFIX_PATH, PATTERN_PHONE} from "configs/AppConfig";
import moment from "moment";
import subscriptionService from "services/SubscriptionServiceService";
import Utils from "utils";
import telcosService from "services/TelcoService";
import serviceService from "services/ServiceService";
import packageService from "services/PackageService";
import syntaxService from "services/SyntaxService";
import SubscriptionServiceService from "services/SubscriptionServiceService";

const {RangePicker} = DatePicker;

export class SubscriptionInformation extends Component {
    state = {
        telcos: [],
        services: [],
        syntax: [],
        packages: [],
        subscriptions: [],
        loading: false,
        pagination: {
            current: 1,
            pageSize: 10,
        },
        filters: {_sort: "registered_time:DESC"}
    };

    columns = [
        {
            title: 'STT',
            dataIndex: 'stt',
            key: 'stt',
            render: (_text, _record, index) => {
                const {current, pageSize} = this.state.pagination;

                return ((current - 1) * pageSize) + index + 1
            },
            width: 50
        },
        {
            title: 'Số điện thoại',
            dataIndex: 'msisdn',
            render: (msisdn, _record) => <Link to={`${APP_PREFIX_PATH}/subscription/phone/${msisdn}`}>{msisdn}</Link>
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            width: 150,
            render: (status) => {
                if (status === 1) {
                    return 'Đăng ký';
                } else if (status === 0) {
                    return 'Hủy';
                } else {
                    return 'Unknown';
                }
            }
        },
        {
            title: 'Dịch vụ',
            dataIndex: ['service_id', 'name'],
            width: 150
        },
        {
            title: 'Đầu số dịch vụ',
            dataIndex: ['service_id', 'name'],
            width: 150,
            render: (_, record) => {
                if (record.service_id.name === 'Mangatoon') {
                    return '9285';
                } else
                    return '9971'
            }
        },
        {
            title: 'Gói cước',
            dataIndex: ['package_id', 'name'],
            width: 150,
            render: (_, record) => {
                if (record?.package_id?.code) {
                    return record.package_id.code;
                } else if (record?.package_id?.name) {
                    return record.package_id.name;
                } else
                    return ''
            }
        },
        {
            title: 'Cú pháp dịch vụ',
            dataIndex: ['command_code_id', 'command_code'],
            width: 150,
        },
        {
            title: 'Nhà mạng',
            dataIndex: ['telco_id', 'name'],
            width: 150,
        },
        {
            title: 'Ngày DK',
            dataIndex: 'registered_time',
            width: 150,
            render: (_, record) => {
                if (record.registered_time !== null) {
                    const date = moment(record.registered_time).utc(false);
                    return date.format('DD-MM-YYYY HH:mm:ss');
                } else {
                    return '';
                }
            }
        },
        {
            title: 'Ngày hủy',
            dataIndex: 'unregistered_time',
            width: 150,
            render: (_, record) => {
                if (record.unregistered_time !== null) {
                    const date = moment(record.unregistered_time).utc(false);
                    return date.format('DD-MM-YYYY HH:mm:ss');
                } else {
                    return '';
                }
            }
        },
        {
            title: 'Ngày hết hạn',
            dataIndex: 'expired_time',
            width: 150,
            render: (_, record) => {
                if (record.expired_time !== null) {
                    const date = moment(record.expired_time).utc(false);
                    return date.format('DD-MM-YYYY HH:mm:ss');
                } else {
                    return '';
                }
            }
        },
        {
            title: 'Ngày tạm dừng',
            dataIndex: 'paused_time',
            width: 150,
            render: (_, record) => {
                if (record.paused_time !== null) {
                    const date = moment(record.paused_time).utc(false);
                    return date.format('DD-MM-YYYY HH:mm:ss');
                } else {
                    return '';
                }
            }
        },
        {
            title: 'Ngày gia hạn tiếp theo',
            dataIndex: 'next_renew',
            width: 200,
            render: (_, record) => {
                if (record.next_renew !== null) {
                    const date = moment(record.next_renew).utc(false);
                    return date.format('DD-MM-YYYY HH:mm:ss');
                } else {
                    return '';
                }
            }
        },
    ];

    constructor(props) {
        super(props);
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        this.getDefaultDate();
        this.loadData();
        this.getTotalCount();
        this.getServices();
        this.getTelcos();
        this.getPackages();
        this.getSyntax();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getTotalCount = async () => {
        const {filters} = this.state;

        const total = await subscriptionService.count(filters);
        const {pagination} = this.state;
        this._isMounted && this.setState({
            pagination: {
                ...pagination,
                total
            }
        })
    }

    getDefaultDate = () => {
        const {filters} = this.state;
        let defaultDates = Utils.getDefaultDates();

        if (filters.registered_time_gte === undefined || filters.registered_time_lte === undefined) {
            filters['registered_time_gte'] = defaultDates[0].startOf('day').format('YYYY-MM-DD');
            filters['registered_time_lte'] = defaultDates[1].endOf('day').format('YYYY-MM-DD 23:59:59');
        }

        this.setState({
            filters
        })
    }

    loadData = async (params) => {
        this.setState({
            loading: true
        })
        const {filters} = this.state;
        const __pagination = Utils.getPaginationParams(params);
        const __params = Object.assign(filters, __pagination);
        const subscriptions = await subscriptionService.read(__params);
        const {pagination} = this.state;
        this._isMounted && this.setState({
            loading: false,
            subscriptions,
            filters,
            pagination: {
                ...pagination,
                ...params
            }
        });
        await this.getTotalCount();
    }

    getTelcos = async () => {
        const telcos = await telcosService.read();
        this._isMounted && this.setState({
            telcos
        });
    }

    getServices = async () => {
        const services = await serviceService.read();
        this._isMounted && this.setState({
            services
        })
    }

    getPackages = async () => {
        const packages = await packageService.read();
        this._isMounted && this.setState({
            packages
        })
    }

    getSyntax = async () => {
        const syntax = await syntaxService.read();
        this._isMounted && this.setState({
            syntax
        })
    }

    handleTableChange = (pagination) => {
        this.loadData(pagination);
    }

    handleDownload = async () => {
        this.state.filters._limit = -1
        const url = await SubscriptionServiceService.export(this.state.filters)
        if (url) {
            Utils.downloadBySaveFile(url, url.replace(/^.*[\\/]/, ''))
        }
    }

    onFinish = async (values) => {
        this.setState({
            loading: true,
        });

        let filters = {};
        if (values.msisdn && values.msisdn !== '') {
            let _phone = values.msisdn;
            if ((/(^(09|03|07|08|05)+([0-9]{8}$))/).test(_phone)) {
                _phone = _phone.replace('0', '84')
            }
            filters['msisdn_eq'] = _phone;
        }

        if (values.status) {
            filters['status_eq'] = values.status;
        }

        if (values.expired_time !== undefined && values.expired_time !== '' && values.expired_time !== null) {
            let expireTimeFrom = new Date(values.expired_time[0]);
            let expireTimeTo = new Date(values.expired_time[1]);
            expireTimeFrom = moment.utc(expireTimeFrom).format('YYYY-MM-DD');
            expireTimeTo = moment.utc(expireTimeTo).format('YYYY-MM-DD 23:59:59');

            filters['expired_time_gte'] = expireTimeFrom;
            filters['expired_time_lte'] = expireTimeTo;
        }

        if (values.registered_time !== undefined && values.registered_time !== '' && values.registered_time !== null) {
            let registeredTimeFrom = new Date(values.registered_time[0]);
            let registeredTimeTo = new Date(values.registered_time[1]);
            registeredTimeFrom = moment.utc(registeredTimeFrom).format('YYYY-MM-DD');
            registeredTimeTo = moment.utc(registeredTimeTo).format('YYYY-MM-DD 23:59:59');

            filters['registered_time_gte'] = registeredTimeFrom;
            filters['registered_time_lte'] = registeredTimeTo;
        }

        if (values.unregistered_time !== undefined && values.unregistered_time !== '' && values.unregistered_time !== null) {
            let unregisteredTimeFrom = new Date(values.unregistered_time[0]);
            let unregisteredTimeTo = new Date(values.unregistered_time[1]);
            unregisteredTimeFrom = moment.utc(unregisteredTimeFrom).format('YYYY-MM-DD');
            unregisteredTimeTo = moment.utc(unregisteredTimeTo).format('YYYY-MM-DD 23:59:59');

            filters['unregistered_time_gte'] = unregisteredTimeFrom;
            filters['unregistered_time_lte'] = unregisteredTimeTo;
        }

        if (values.service_id) {
            filters['service_id_in'] = values.service_id;
        }

        if (values.telco_id) {
            filters['telco_id_in'] = values.telco_id;
        }

        if (values.package_id) {
            filters['package_id_in'] = values.package_id;
        }
        this.setState({
            filters,
            pagination: {
                current: 1,
                pageSize: 10,
            },
        })
        this.loadData();
    }

    render() {
        const {telcos, loading, packages, pagination, services, subscriptions} = this.state;
        return (
            <>
                <Card>
                    <Form layout="vertical" onFinish={this.onFinish}>
                        <Row gutter={[24, 24]}>
                            <Col md={6} sm={24} xs={24}>
                                <Form.Item name="msisdn"
                                           label="Số điện thoại"

                                           rules={[
                                               {
                                                   pattern: PATTERN_PHONE,
                                                   message: 'Không đúng định dạng số điện thoại',
                                               },
                                           ]}
                                >
                                    <Input size="default" placeholder="Nhập số điện thoại cần tra cứu"
                                           prefix={<PhoneOutlined/>}/>
                                </Form.Item>
                            </Col>
                            <Col md={6} sm={24} xs={24}>
                                <Form.Item name="status" label="Trạng thái">
                                    <Select style={{width: '100%'}}
                                            placeholder="Lựa chọn trạng thái"

                                    >
                                        <Select.Option>Tất cả</Select.Option>
                                        <Select.Option key="1" value="1">Đăng ký</Select.Option>
                                        <Select.Option key="0" value="0">Hủy</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col md={6} sm={24} xs={24} className="d-flex">
                                <Form.Item name="registered_time" label="Thời gian ĐK" initialValue={Utils.getDefaultDates()}
                                           style={{width: '100%', float: 'left'}}>
                                    <RangePicker 
                                                 style={{width: '100%'}}/>
                                </Form.Item>
                            </Col>
                            <Col md={6} sm={24} xs={24} className="d-flex">
                                <Form.Item name="unregistered_time" label="Thời gian hủy"
                                           style={{width: '100%', float: 'left'}}>
                                    <DatePicker.RangePicker format="DD/MM/YYYY" style={{width: '100%'}}/>
                                </Form.Item>
                            </Col>

                            <Col md={6} sm={24} xs={24}>
                                <Form.Item name="telco_id" label="Nhà mạng">
                                    <Select style={{width: '100%'}}
                                            placeholder="Lựa chọn nhà mạng"

                                            mode="tags"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                    >
                                        {
                                            telcos.map((data) => <Select.Option key={data.id}
                                                                                value={data.id}>{data.name}</Select.Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col md={6} sm={24} xs={24}>
                                <Form.Item name="service_id" label="Dịch vụ">
                                    <Select style={{width: '100%'}}
                                            placeholder="Lựa chọn dịch vụ"

                                            mode="tags"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                    >
                                        {
                                            services.map((data) => <Select.Option key={data.id}
                                                                                  value={data.id}>{data.name}</Select.Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col md={6} sm={24} xs={24} className="d-flex">
                                <Form.Item name="expired_time" label="Thời gian hết hạn"
                                           style={{width: '100%', float: 'left'}}>
                                    <DatePicker.RangePicker format="DD/MM/YYYY" style={{width: '100%'}}/>
                                </Form.Item>
                            </Col>
                            <Col md={6} sm={24} xs={24}>
                                <Form.Item name="package_id" label="Gói cước"
                                           style={{width: '65%', float: 'left', marginRight: '5px'}}>
                                    <Select style={{width: '100%'}}
                                            placeholder="Lựa chọn gói cước"

                                            mode="tags"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                    >
                                        {
                                            packages.map((data) => <Select.Option key={data.id}
                                                                                  value={data.id}>{data.code || data.name}</Select.Option>)
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item style={{width: '30%', float: 'left', marginLeft: '5px'}}>
                                    <Button className="float-right" type="primary" htmlType="submit"
                                            style={{width: '100%', marginTop: '20px', padding: '0px'}}>Lọc</Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Card>
                <Row gutter={[24, 24]}>
                    <Col md={24} sm={24} xs={24}>
                        <Card title="Danh sách thuê bao">
                            <Button type="primary"
                                    style={{marginBottom: 16, borderRadius: '5px', float: 'right', marginLeft: '15px'}}
                                    icon={<DownloadOutlined/>}
                                    onClick={this.handleDownload}>
                                Tải về
                            </Button>
                            <Table
                                columns={this.columns}
                                dataSource={subscriptions}
                                loading={loading}
                                rowKey={record => record.id}
                                pagination={pagination}
                                onChange={this.handleTableChange}
                            />
                        </Card>
                    </Col>
                </Row>
            </>
        );
    }
}

export default SubscriptionInformation;
